@media (min-width: 1200px) {
    .butnot-prime {
        width: 200px;
        height: 40px;
        filter:
            drop-shadow(
                0 1px 2px hsl(0deg 0% 30.7% / 0.37)
            )
            drop-shadow(
                0 6px 18px hsl(36deg 57% 65% / 0.37)
            );
        position: relative;
        color: rgb(244, 241, 224);
        background-color: rgba(255, 255, 255, 0.3);
        font-size: 11.4pt;
        border-radius: 50px;
        border: none;
        overflow: hidden;
        transition: 300ms;
        -webkit-transition: 300ms;
        -moz-transition: 300ms;
        -ms-transition: 300ms;
        -o-transition: 300ms;
        border-bottom: 1px solid rgba(255, 255, 255, 0.392);
        user-select: none;
        -webkit-filter: 
            drop-shadow(
                0 1px 2px hsl(0deg 0% 30.7% / 0.37)
            )
            drop-shadow(
                0 6px 18px hsl(36deg 57% 65% / 0.37)
            );
    }
    
    .butnot-prime:hover {
        background-color: hsl(36deg, 57%, 65%, 0.95);
        width: 200px;
        padding-right: 10px;
    }
    
    .butnot-prime .fa {
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        right: 3.3%;
        color: rgb(244, 241, 224);
        transform: translateX(-50%);
        transition: 200ms;
        -webkit-transition: 200ms;
        -moz-transition: 200ms;
        -ms-transition: 200ms;
        -o-transition: 200ms;
        opacity: 0;
    }
    
    .butnot-prime:hover .fa {
        opacity: 1;
        transform: translateX(0);
    }
    
    .butnot-prime span {
        color: white;
        font-family: "Overpass", sans-serif;
        font-weight: 400;
        font-size: 11.4pt;
        letter-spacing: 0.15pt;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 1px !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}
@media (max-width: 480px) {
    .butnot-prime {
        width: 200px;
        height: 40px;
        /* filter:
            drop-shadow(
                0 1px 2px hsl(0deg 0% 30.7% / 0.37)
            )
            drop-shadow(
                0 6px 18px hsl(36deg 57% 65% / 0.37)
            ); */
        position: relative;
        color: rgb(244, 241, 224);
        background-color: rgba(255, 255, 255, 0.3);
        font-size: 11.4pt;
        border-radius: 50px;
        border: none;
        overflow: hidden;
        transition: 300ms;
        -webkit-transition: 300ms;
        -moz-transition: 300ms;
        -ms-transition: 300ms;
        -o-transition: 300ms;
        border-bottom: 1px solid rgba(255, 255, 255, 0.392);
        user-select: none;
    }
    
    .butnot-prime:hover {
        background-color: hsl(36deg, 57%, 65%, 0.95);
        width: 200px;
        padding-left: 10px;
        /* on mobile padding left (reverse behavior)  */
    }
    
    .butnot-prime .fa {
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        right: 4px;
        color: rgb(244, 241, 224);
        transform: translateX(-50%);
        transition: 200ms;
        -webkit-transition: 200ms;
        -moz-transition: 200ms;
        -ms-transition: 200ms;
        -o-transition: 200ms;
        opacity: 0;
    }
    
    .butnot-prime:hover .fa {
        opacity: 1;
        transform: translateX(0);
    }
    
    .butnot-prime span {
        color: white;
        font-family: "Overpass", sans-serif;
        font-weight: 400;
        font-size: 11.4pt;
        letter-spacing: 0px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 0 !important;
    }

}
