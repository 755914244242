@media (min-width: 1200px) {
    .TwoRowsSupport {
        display: flex;
        justify-content: center;
        gap: 3.75rem;
        max-width: 1400px;
        min-width: 60%;
        margin: auto;
        padding-top: 2.5rem;
        padding-left: 7.5rem;
        padding-right: 7.5rem;
    }
    .SupportContainer {
        min-width: 530px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.8rem;
    }
    .SupportContainer p {
        margin-top:-0.75rem;
        align-self: start;
        color: #d9b173;
        font-size: xx-large;
        letter-spacing: 0.3px;
        font-weight: 500;
        max-width: 500px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .FrequentQuestions {
        font-weight: 400;
        width: 400px;
        min-height: 320px;
        height: fit-content;
        background-color: #fcfcfb;
        display: flex;
        border-radius: 5px;
    }
    .FrequentQuestions a {
        color: #0f281e !important;
        text-decoration: none !important;
        letter-spacing: 0.15px;
        font-size: small;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 92%;
    }
    .FrequentQuestions a:hover {
        color: #000 !important;
        text-decoration: none !important;
        letter-spacing: 0.15px;
        transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        -webkit-transition: all .2s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .2s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .2s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .2s cubic-bezier(.165, .84, .44, 1);
    }
    .FQAlign {
        width: 92%;
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }
    #FQrows {
        position: relative;
        display: flex !important;
        flex-flow: column wrap !important;
        flex-wrap: wrap;
        gap: 1rem;
        width: 100% !important;
    }
    div #FQAlignPolicy {
        position: relative !important;
        top:0 !important;
        padding: 0.5rem;
        min-width: 628px !important;
        padding-right: 0;
        height: 640px;
        /* margin-left: 35rem; */
    }
    div #FQAlignPolicy p {
        width: 97%;
        letter-spacing: 0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    div #FQAlignPolicy h3 {
        margin-bottom: 1.25rem;
        letter-spacing: 0.15pt;
    }
    div #FQAlignQuestions {
        position: sticky !important;
        top: 24px !important;
        height: fit-content;
        width: 400px;
    }
    #check-api-p1:hover {
        scale: 1.1;
    }
    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border-left-color: #09f;
        -webkit-animation: spin 1s ease infinite;
        animation: spin 1s ease infinite;
    }
    @-webkit-keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    .supportMessageS {
        max-width: 500px;
        margin-bottom: 10px;
        align-self: center !important;
        color: black !important;
        font-size: medium !important;
        font-weight: 400 !important;
        letter-spacing: 0.5px !important;
    }
    .supportMessageE {
        max-width: 500px;
        margin-bottom: 10px;
        align-self: center !important;
        color: #ffb74d !important;
        font-size: medium !important;
        font-weight: 400 !important;
    }
    .ModalButton{
        border: 2px solid black;
        border-radius: 7px;
        background-color:white;
    }
    .SupportContainer #question, #email1, #ip-support {
        width: 500px !important;
    }
    .SupportContainer #questiontype {
        width: 480px;
    }
    .SupportContainer #name-support, #surname-support, #patronymic-support {
        width: 141px;
    }
    .SupportContainer #CheckBoxS p {
        justify-items: top !important;
        font-weight: 400;
        font-size: medium !important;
        /* margin-top: -10px !important; */
        margin-bottom: 0 !important;
        padding: 0rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* margin-top: -5px !important; */
    }
    .SupportContainer #CheckBoxS {
        width: 100% !important;
    }
    .SupportContainer #CheckBoxS #check-api-p {
        /* width: 100% !important; */
        margin-right: 10px;
        margin-top: 0px !important;
        width: 32px;
        height: 32px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .SupportRowColumn {
        display:flex;
        flex-direction: row;
        gap: 12px;
    }
}

@media (max-width: 480px) {
    .TwoRowsSupport {
        display: flex;
        flex-direction: column;
        width: 100% !important;
        gap: 4rem;
        height: 100%;
    }
    #FQrows {
        position: relative;
        display: flex !important;
        flex-flow: column wrap !important;
        flex-wrap: wrap;
        gap: 1rem;
        width: 100% !important;
    }
    .FrequentQuestions a {
        color: #0f281e !important;
        text-decoration: none !important;
        letter-spacing: 0.15px;
        font-size: small;
        padding-top: 1rem;
        padding-bottom: 1rem;
        width: 92%;
    }
    .FrequentQuestions a:hover {
        color: #000 !important;
        text-decoration: none !important;
        letter-spacing: 0.15px;
        transition: all 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
        -webkit-transition: all .2s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .2s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .2s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .2s cubic-bezier(.165, .84, .44, 1);
    }
    .SupportContainer {
        position: relative;
        display: flex;
        width: 92%;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin: auto;
    }
    .SupportContainer p {
        /* margin-top:-0.75rem; */
        align-self: center;
        color: #d9b173;
        font-size: xx-large;
        letter-spacing: 0.3px;
        font-weight: 500;
        max-width: 500px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .SupportContainer hr {
        display: none !important;
    }
    .FrequentQuestions {
        font-weight: 400;
        width: 88%;

        height: fit-content;
        background-color: #fcfcfb;
        margin: auto;
        display: flex;
        border-radius: 5px;
        margin-left: -5rem;
    }
    .FQAlign {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem;
    }
    div #FQAlignPolicy {
        position: relative !important;
        top:0 !important;
        padding: 1rem;
        width: 88%;
        padding-right: 0;
        height: max-content !important;
        margin: auto;
    }
    div #FQAlignPolicy p {
        width: 95%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    div #FQAlignQuestions {
        position: relative !important;
        display: flex;
        margin: auto;
    }
    #check-api-p1:hover {
        scale: 1.1;
    }
    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border-left-color: #09f;
        -webkit-animation: spin 1s ease infinite;
        animation: spin 1s ease infinite;
    }
    @-webkit-keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .supportMessageS {
        max-width: 500px;
        margin-bottom: 0;
        align-self: center !important;
        color: rgb(244, 241, 224) !important;
        font-size: medium !important;
        font-weight: 400 !important;
        letter-spacing: 0.5px !important;
    }
    .supportMessageE {
        max-width: 500px;
        margin-bottom: 0;
        align-self: center !important;
        color: #ffb74d !important;
        font-size: medium !important;
        font-weight: 400 !important;
    }
    .SupportContainer #question, #email1, #ip-support {
        width: 326px !important;
    }
    .SupportContainer #questiontype {
        width: 306px !important;
    }
    .SupportContainer #name-support, #surname-support, #patronymic-support {
        width: 326px;
    }
    .SupportContainer #CheckBoxS p {
        justify-items: top !important;
        font-weight: 400;
        width: 100% !important;
        font-size: medium !important;
        margin-bottom: 0 !important;
        padding-left: 1rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: -25px !important;
    }
    .SupportContainer #CheckBoxS {
        padding-top: 12px;
        margin-bottom: 12px;
        width: 100% !important;
    }
    .SupportContainer #CheckBoxS #check-api-p {
        margin-right: 10px;
        margin-top: 0px !important;
        width: 32px;
        height: 32px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .SupportRowColumn {
        display:flex;
        flex-direction: column;
        gap: 12px;
    }
}