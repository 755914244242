@media (min-width: 1200px) {
    .row1{
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
    }
    .row1 child {
        width: 50%;
    }
    .row1 h1 {
     color: white;
     font-size: 32pt;
     font-weight: 800;
     letter-spacing: 0.3px;
     position: relative;
     display: flex;
     flex: 1;
     flex-direction: column;
     justify-content: space-evenly;
     align-items: left;
     max-width: 530px;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
    }
    .row1 img {
        scale: 100%;
    }
    
    .row2 {
        flex: 1;
        flex-direction: row;
        /* flex-wrap: wrap; */
        display: flex;
    }
    .row2 h3 {
        color: rgb(244, 241, 224);
        font-size: large;
        letter-spacing: 0.25px;
        margin-top: 2.75rem;
        justify-content: center;
        /* font-family: 'IBM plex sans', sans-serif; */
        font-weight: 300;
        width: 250px;
    }
    .column {
        flex: 1;
        flex-direction: column;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .icon {
        color: rgb(66, 165, 245);
        font-size: 50px;  
    }
    .arrow {
        display: table-cell;
        color: rgb(244, 241, 224);
        font-size: 30px;
        transform: translateY(200%);
    }
    .container1 {
        width: 120px;
        height: 120px;
        display: flex;
        place-content: center;
        overflow: hidden;
        border-radius: 15px;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        /* box-shadow: 0 6px 18px hsl(0deg 0% 0.7% / 0.37); */
        box-shadow: 
            0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 60px 100px rgba(0, 0, 0, 0.12)
        ;
        background: rgba(199, 199, 199, 0.21);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
        border-left:1px solid rgba(255, 255, 255, 0.18);
        border-right:1px solid rgba(255, 255, 255, 0.18);
    }
    .item1 {
        width: 48%;
        overflow: visible;
        stroke: rgba(76, 175, 80, 1);
        stroke-width: 2;
        stroke-linejoin: round;
        stroke-linecap: round;
    }
    .CenteredThings {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        width: 78%;
        padding-top: 4rem;
    }
    #CT2 {
        max-width: 74%;
        margin-bottom:-12rem;
    }
    .imageContainer {
        display: block;
        background: url('../images/forest.webp') no-repeat left bottom 65% ;
        background-size: 105% ;
        width: 74%;
        height: 300px;
        margin: auto;
        overflow: hidden;
        margin-top: 2rem;
        border-radius: 15px;
        object-fit: cover;
    }
    .imageContainer h2 {
        z-index: 1001;
        position: relative;
        margin: auto;
        padding: 4rem 14.5rem;
        color: #d9b173;
        font-weight: 600;
        margin-bottom: -3rem;
        filter: 
            drop-shadow(
                0px 0px 30px hsl(0deg 0% 0% / 0.99)
            )
            brightness(1.15);
    }
    .imageContainer p {
        z-index: 1001;
        color: #d9b173;
        position: relative;
        font-weight: 400;
        font-family: 'Overpass', sans-serif;
        padding: 0rem 14.5rem;
        font-size: larger;
        text-align: flex-start !important;
        filter: 
            drop-shadow(
                0px 0px 30px hsl(0deg 0% 0% / 1)
            )
            brightness(1.15);
        /* justify-content: flex-start !important; */
        /* max-width: 10% !important; */
    }
    .backToBack {
        background: black;
        opacity: 0.28;
        width: 100%;
        height: 110%;
        z-index: 999 !important;
        position: relative;
        margin-top: -15rem !important;
    }   
}

@media (max-width: 480px) {
    .row1{
        flex: 1;
        flex-direction: row;
        flex-wrap: wrap;
        display: flex;
        justify-content: center;
    }
    .row1 child {
        width: 50%;
    }
    .row1 h1 {
     color: white;
     font-size: 32pt;
     font-weight: 800;
     letter-spacing: 0.3px;
     position: relative;
     display: flex;
     flex: 1;
     flex-direction: column;
     justify-content: space-evenly;
     align-items: left;
     max-width: 530px;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
    }
    .row1 img {
        scale: 100%;
    }
    .row2 {
        flex: 1;
        flex-direction: column;
        display: flex;
        gap: 0rem;
    }
    .row2 h3 {
        color: rgb(244, 241, 224);
        font-size: 12.5pt;
        letter-spacing: 0.5px;
        margin-top: 3.2rem;
        justify-content: center;
        font-family: 'IBM plex sans', sans-serif;
        font-weight: 300;
        width: 225px;
    }
    .column {
        flex: 1;
        flex-direction: column;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .icon {
        color: rgb(66, 165, 245);
        font-size: 50px;  
    }
    .arrow {
        position: relative;
        margin: auto;
        margin-bottom: 3rem;
        display: contents !important;
        color: rgb(244, 241, 224);
        font-size: 30px;
        transform: translateY(50%) rotate(90deg);
    }
    .container1 {
        width: 120px;
        height: 120px;
        display: flex;
        place-content: center;
        overflow: hidden;
        border-radius: 30px;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        box-shadow: 0 6px 18px hsl(0deg 0% 15.7% / 0.37);
        background: rgba(199, 199, 199, 0.21);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
        border-left:1px solid rgba(255, 255, 255, 0.18);
        border-right:1px solid rgba(255, 255, 255, 0.18);
    }
    .item1 {
        width: 48%;
        overflow: visible;
        stroke: rgba(76, 175, 80, 1);
        stroke-width: 2;
        stroke-linejoin: round;
        stroke-linecap: round;
    }
    .CenteredThings {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        width: 90%;
        padding-top: 15rem;
    }
    #CT1 {
        margin-top: 25rem;
    }
    #CT2 {
        padding-top: 4rem !important;
        min-width: 100% !important;
        margin-bottom: -4rem !important;
    }
    #CT2 h1 {
        margin-left: 3% !important;
    }
    #CT2 hr {
        margin-top: 1rem !important;
        max-width: 93%;
        margin: auto;
    }
    .imageContainer {
        display: block;
        background: url('../images/forest.webp') no-repeat left bottom 5% ;
        background-size: 150%;
        width: 88% !important;
        height: 360px;
        margin: auto;
        overflow: hidden;
        border-radius: 15px;
        object-fit: cover;
    }
    .imageContainer h2 {
        z-index: 1001;
        position: relative;
        margin: auto;
        margin-top: 2.25rem;
        color: #d9b173;
        font-weight: 600;
        max-width: 250px;
        margin-bottom: 1rem;
        letter-spacing: 0.35pt;
        padding-left: 1rem;
        filter: 
            drop-shadow(
                0px 0px 30px hsl(0deg 0% 0% / 0.99)
            )
            brightness(1.15);
    }
    .imageContainer p {
        z-index: 1001;
        color: #d9b173;
        position: relative;
        font-weight: 400;
        font-family: 'Overpass', sans-serif;
        font-size: medium;
        margin: auto;
        text-align: flex-start !important;
        filter: 
            drop-shadow(
                0px 0px 30px hsl(0deg 0% 0% / 1)
            )
            brightness(1.15);
        max-width: 250px !important;
        letter-spacing: 0.35pt;
        padding-left: 1rem;
    }
    .backToBack {
        background: black;
        opacity: 0.28;
        width: 100%;
        height: 110%;
        z-index: 999 !important;
        position: relative;
        margin-top: -20rem !important;
    }
}
