@media (min-width: 1400px) {
    .InfoGrid {
        padding-top: 1rem;
        display: grid;
        position: relative;
        min-width: 74.5%;
        max-width: 1400px;
        grid-template-columns: 71% 27% !important;
        justify-content: space-between !important;
        justify-content: center;
        margin: auto;
    }
    .BlogFeed {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 75vh;
        max-height: 81vh;
        background:
            linear-gradient(to right, #d9b173 3px, transparent 3px) 0 0,
            linear-gradient(to right, #d9b173 3px, transparent 3px) 0 100%,
            linear-gradient(to left, #d9b173 3px, transparent 3px) 100% 0,
            linear-gradient(to left, #d9b173 3px, transparent 3px) 100% 100%,
            linear-gradient(to bottom, #d9b173 3px, transparent 3px) 0 0,
            linear-gradient(to bottom, #d9b173 3px, transparent 3px) 100% 0,
            linear-gradient(to top, #d9b173 3px, transparent 3px) 0 100%,
            linear-gradient(to top, #d9b173 3px, transparent 3px) 100% 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: 32px 32px;
        z-index: 100 !important;
        padding-bottom: 3px;
    }
    .BlogWrapper p {
        border-left: 3px solid #d9b173;
        z-index: 10;
        padding-left: 5px;
    }
    .BlogWrapper {
        width: 96.5%;
        height: 100% !important;
        margin: auto;
        display: block;
        z-index: 101 !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }
    .BlogWrapper::-webkit-scrollbar {
        width: 3px;
        overflow: hidden;
    }
    .BlogWrapper::-webkit-scrollbar-track {
        box-shadow: inset 1px 2px 4px hsla(0, 0%, 0%, 0.33);
        border-radius: 5px;
        margin-bottom: 72px;
    }
    .BlogWrapper::-webkit-scrollbar-thumb {
        background-color: hsla(153, 36%, 36%, 0.9);
        border-radius: 5px;
        max-height: 100px !important;
    }
    .FeedHeader {
        width: 100%;
        height: 88px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1.15rem;
        z-index: 1;
    }
    .VK {
        font-size: xx-large;
        color: #0077FF;
        z-index: 100;
        transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    }
    .VK:hover {
        filter: drop-shadow(0 0 10px hsla(212, 100%, 50%, 0.7));
    }  
    .FeedHeader h2 {
        font-size: large;
        color: #d9b173;
        font-weight: 400;
        letter-spacing: 0.35pt;
        user-select: none;
    }
    .TopicRow {
        font-family: 'Overpass', sans-serif;
        letter-spacing: 0.35pt;
        font-size: 10.5pt;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2rem;
        z-index: 10 !important;
    }
    .Topic {
        font-weight: 300;
        border-left: 3px solid #d9b173;
        color: #fff;
        padding-left: 1rem;
        height: fit-content;
        padding-top: 3px;
        letter-spacing: 0.5px;
    }
    .Date {
        padding-right: 1rem;
        font-weight: 300;
        color: #fff;
        border-left: 1px solid #d9b173;
        padding-left: 1rem;
        height: fit-content;
        padding-top: 3px;
        letter-spacing: 0.05pt;
    }
    .iTitle {
        color: rgb(232, 188, 123);
        font-size: xx-large;
        padding-left: 1rem;
        letter-spacing: 0.15pt;
        max-width: 94%;
    }
    .PostGrid {
        display: grid;
        position: relative !important;
        width: 100% !important;
        grid-template-columns: 45% 50% !important;
        grid-template-rows: 90% auto;
        justify-content: space-between !important;
        justify-content: center;
        padding-top: 2rem;
        margin-bottom: 11.75rem;
    }
    .PostGrid img {
        position: relative;
        /* grid-column: 2;
        grid-row: 1/3; */
        width: 100%;
        /* justify-self: right;
        margin-right: 1rem; */
        height: 100%;
        object-fit:cover;
        z-index: 1;
        border-radius: 14px;
        justify-content: center;
        align-content: center;
        overflow: hidden;
    }
    .PicContainer {
        grid-row: 1/3;
        grid-column: 2;
        justify-self: right;
        margin-right: 1rem;
        height: 300px;
        width: 88%;
        overflow: hidden;
        border-radius: 16px;
        box-shadow: 0 6px 12px -2px rgba(50,50,93,0.25),0 3px 7px -3px rgba(0,0,0,0.3);
        /* box-shadow: 0 13px 27px -5px rgba(21, 21, 51, 0.39),0 8px 16px -8px rgba(0,0,0,0.3); */
    }
    .shortDesc {
        font-family: "Overpass", sans-serif;
        padding-left: 1rem;
        color: #fff;
        letter-spacing: 0.25pt;
        font-weight: 300;
        font-size: medium;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .PostGridButton {
        position: relative;
        grid-column: 1;
        grid-row: 2;
        width: 180px;
        height: 40px;
        padding-top: 3px;
        margin-left: 1rem !important;
        font-family: 'Overpass', sans-serif;
        font-weight: 400 !important;
        color: rgb(232, 188, 123);
        /* box-shadow: 0 4px 6px hsl(0deg 0% 13% / 0.33); */
        background: transparent;
        border: 5px solid transparent !important;
        font-size: 11.2pt;
        border-radius: 5px;
        letter-spacing: 0.3pt;
        margin-top: -1rem !important;
    }
    .PostGridButton:hover {
        border: 5px solid transparent !important;
        letter-spacing: 0.3pt;
        font-size: 11.2pt;
        padding-top: 3px !important;
        border-bottom: none;
        box-shadow: 0 0px 0px 4px hsl(123, 43%, 39%);
        /* border: 3px solid rgba(199, 199, 199, 0.397) !important; */
        transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    }
    .PostGridIcon {
        transform: translateX(3px);
    }
    /* .Post { */
        /* height: 500px !important; */
        /* background: #0077FF !important;
        border: 1px solid #fff !important; */
        /* margin-bottom: 1px; */
    /* } */
    .QuestionFeed {
        width: 100%;
        min-height: 80%;
        max-height: 78vh;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) !important;
        border-radius: 15px !important;
        border:1px solid rgba(255, 255, 255, 0.18) !important;
        /* box-shadow: 0 23px 50px 0px hsl(0deg 0% 13% / 0.33) !important; */
        /* box-shadow: 0 30px 60px -12px rgba(42,42,73,0.23),0 18px 36px -18px rgba(0,0,0,0.3); */
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px -5px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px -10px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 50px 100px rgba(0, 0, 0, 0.12)
        ;
        border-top: 2px solid rgba(75, 75, 75, 0.2) !important;
        border-bottom: 2px solid rgba(236, 236, 236, 0.2) !important;
        background: rgba(199, 199, 199, 0.297) !important;
        justify-self: flex-end !important;
    }
    .QuestionFeedWrapper {
        border-radius: 10px;
        background: #fcfcfb;
        box-shadow: inset 0 1px 7px 0 hsla(0, 0%, 0%, 0.7);
        color: rgba(244, 241, 224, 0.096);
        position: relative;
        display: grid;
        grid-template-rows: 5% 78% 15%;
        max-width: 95.4%;
        height: 98%;
        padding: 1.25rem;
        gap: 18px;
        margin: auto;
        margin-top: 2%;
        overflow: hidden;
    }
    .QuestionFeedWrapper span{
        font-family: 'Inter', sans-serif;
        font-size: medium;
        letter-spacing: 0.3pt;
        max-width: 380px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;    
    }
    .QFthird {
        grid-row: 1;
        color: hsla(161, 56%, 10%, 0.8);
        display: flex;
        gap: 1rem;
        position: relative;
        max-height: 100%;
    }
    .QFthird p {
        font-family: 'Overpass', sans-serif;
        font-weight: 700;
        letter-spacing: 0.2px;
        position: relative;
        text-align: center;
        justify-content: center;
        height: 24px;
        padding: 0.5rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;  
    }
    .QFavatar {
        width: 40px;
        height: 40px;
        background: #0077FF;
        border-radius: 50px;
        overflow: hidden;
        outline: 1px solid #0b271e;
    }
    .QFavatar img {
        object-fit: cover;
        position: relative;
        width: 100%;
        scale: 1.25;
    }
    @keyframes blicky {
        0% {opacity: 0.9;}
        50% {opacity: 0;}
        100% {opacity: 0.9;}
    }
    .QFonline {
        position: relative;
        width: 9px;
        height: 9px;
        background: #81c784;
        border-radius: 50px;
        animation: blicky 1.5s ease-out infinite;
    }
    .QFup {
        height: 100%;
        grid-row: 3;
        margin-top:-5px;
        padding-bottom: 7px;
    }
    .QFup label {
        font-family: 'Overpass', sans-serif;
        color: hsla(161, 56%, 10%, 0.8);
        padding: 0px 1px;
        text-align: left;
        /* margin-left: -1px; */
        height: max-content;
        font-size: large;
        font-weight: 600;
        letter-spacing: 0.25px;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    #planeButton {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #d9b173;
        background: #fcfcfb;
        overflow: hidden;
        transition: .25s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: .25s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: .25s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: .25s cubic-bezier(.165, .84, .44, 1);
        -o-transition: .25s cubic-bezier(.165, .84, .44, 1);
    }
    #planeButton :nth-child(1) {
        animation: fly .5s cubic-bezier(.165, .84, .44, 1);
        color: hsla(161, 56%, 10%, 0.8);
    }
    #planeButton:hover {
        box-shadow: 0 0px 0px 5px hsl(123, 43%, 39%), inset 0 2px 6px 0 rgb(0 0 0 / 0.47);
        border: none;
    }
    #planeButton:hover :nth-child(1) {
        animation: antifly 0.35s cubic-bezier(0.4,0,0.2,1) forwards !important;
    }
    #clientQuestion {
        height: 30px !important;
        width: 240px;
        padding-bottom: 0px;
        padding-top: 0;
        margin-bottom: 0px;
    }
    .QFdown {
        margin-left: -1.15rem !important;
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
        grid-row: 2;
        overflow-x: hidden;
        width: 110%;
        position: relative;
        /* align-self: center; */
        margin: auto;
        height: 100%;
        cursor:ns-resize;
        padding: 1rem;
        overflow-y: scroll;
        display: flex;
        flex-direction:column;
        max-height: 644px;
        color: #0b271e;
        gap: 0.95rem;
        border-bottom: 1px solid #bdbdbd;
        
    }
    .QFdown::-webkit-scrollbar {
        display: none;
        width: 3px;
        overflow: hidden;
    }
    .QFdown::-webkit-scrollbar-track {
        display: none;
        border-radius: 0px;
        margin-top: 1rem !important;
        margin-bottom: 1rem;
    }
    .qMessage {
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: right;
        gap: -10px !important;
        color: hsla(161, 56%, 10%, 0.9);
    }
    .aMessage {
        margin-bottom: 1rem;
        margin-left: 0.25rem;
    }
    .qMessage p, .aMessage p {
        font-family: 'Overpass', sans-serif;
        font-weight: 500;
        letter-spacing: 0.1pt;
        font-size: medium;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .qMessage span, .aMessage span {
        font-size: small;
        padding-top: 1px;
        letter-spacing: 0.15pt;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .qBox {
        /* background: hsla(161, 56%, 10%, 0.72); */
        /* background: hsla(97, 85%, 28%, 0.56); */
        background: hsla(123, 38%, 57%, 1);
        margin-top: -1rem;
        padding: 0.75rem 0.9rem 0.9rem 0.75rem;
        max-width: 96% !important;
        min-width: 20%;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        position: relative !important;
        box-sizing: border-box;
        align-self: flex-end;
        text-align: flex-end !important;
        /* display: inline-block !important; */
    }
    .qBox span {
        color: #fff;
        position: relative;
        font-size: 11pt !important;
        letter-spacing: 0.5px;
        font-weight: 500;
        padding-left: 0.25rem;
        word-wrap: break-word !important;
        margin: 0;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .aBox {
        margin-bottom: 4px;
        border: 1px solid transparent;
        background: hsla(0, 0%, 13%, 0.78);
        /* background: hsla(36, 57%, 65%, 0.86); */
        padding: 0.75rem 1rem 0.9rem 0.5rem;
        max-width: 95% !important;
        min-width: 20%;
        box-sizing: border-box;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        position: relative;
        align-self: flex-start;
    }
    .aBox span {
        color: #fff;
        /* color: hsla(161, 56%, 10%, 0.825); */
        word-wrap: break-word !important;
        margin: 0;
        position: relative;
        font-size: 11pt !important;
        letter-spacing: 0.5px;
        left: 0.2rem;
        font-weight: 500;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
}

@media (min-width: 960px) and (max-width: 1399px) {
    .InfoGrid {
        padding-top: 1rem;
        display: grid;
        position: relative;
        min-width: 74.5%;
        max-width: 1200px;
        grid-template-columns: 71% 27% !important;
        justify-content: space-between !important;
        justify-content: center;
        margin: auto;
    }
    .BlogFeed {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 81vh;
        background:
            linear-gradient(to right, #d9b173 3px, transparent 3px) 0 0,
            linear-gradient(to right, #d9b173 3px, transparent 3px) 0 100%,
            linear-gradient(to left, #d9b173 3px, transparent 3px) 100% 0,
            linear-gradient(to left, #d9b173 3px, transparent 3px) 100% 100%,
            linear-gradient(to bottom, #d9b173 3px, transparent 3px) 0 0,
            linear-gradient(to bottom, #d9b173 3px, transparent 3px) 100% 0,
            linear-gradient(to top, #d9b173 3px, transparent 3px) 0 100%,
            linear-gradient(to top, #d9b173 3px, transparent 3px) 100% 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: 32px 32px;
        z-index: 100 !important;
        padding-bottom: 3px;
    }
    .BlogWrapper p {
        border-left: 3px solid #d9b173;
        z-index: 10;
        padding-left: 5px;
    }
    .BlogWrapper {
        width: 96.5%;
        height: 100% !important;
        margin: auto;
        display: block;
        z-index: 101 !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }
    .BlogWrapper::-webkit-scrollbar {
        width: 3px;
        overflow: hidden;
    }
    .BlogWrapper::-webkit-scrollbar-track {
        box-shadow: inset 1px 2px 4px hsla(0, 0%, 0%, 0.33);
        border-radius: 5px;
        margin-bottom: 72px;
    }
    .BlogWrapper::-webkit-scrollbar-thumb {
        background-color: hsla(153, 36%, 36%, 0.9);
        /* border: 1px solid hsla(36, 57%, 65%, 0.75); */
        /* border: 1px solid hsl(0 0% 100% / 0.75); */
        border-radius: 5px;
        max-height: 100px !important;
    }
    .FeedHeader {
        width: 100%;
        height: 88px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1.15rem;
        z-index: 1;
    }
    .VK {
        font-size: xx-large;
        color: #0077FF;
        z-index: 100;
        transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        /* filter: drop-shadow(0 0 1px hsl(0 0% 0% / 0.33)) !important; */
    }
    .VK:hover {
        filter: drop-shadow(0 0 10px hsla(212, 100%, 50%, 0.7));
    }  
    .FeedHeader h2 {
        font-size: large;
        color: #d9b173;
        font-weight: 400;
        letter-spacing: 0.35pt;
        user-select: none;
    }
    .TopicRow {
        font-family: 'Overpass', sans-serif;
        letter-spacing: 0.35pt;
        font-size: 10.5pt;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2rem;
        z-index: 10 !important;
    }
    .Topic {
        font-weight: 300;
        border-left: 3px solid #d9b173;
        color: #fff;
        padding-left: 1rem;
        height: fit-content;
        padding-top: 3px;
        letter-spacing: 0.5px;
    }
    .Date {
        padding-right: 1rem;
        font-weight: 300;
        /* font-size: small; */
        color: #fff;
        border-left: 1px solid #d9b173;
        padding-left: 1rem;
        height: fit-content;
        padding-top: 3px;
        letter-spacing: 0.05pt;
    }
    .iTitle {
        color: rgb(232, 188, 123);
        font-size: xx-large;
        padding-left: 1rem;
        letter-spacing: 0.15pt;
        max-width: 94%;
    }
    .PostGrid {
        display: grid;
        position: relative !important;
        width: 100% !important;
        grid-template-columns: 45% 50% !important;
        grid-template-rows: 90% auto;
        justify-content: space-between !important;
        justify-content: center;
        padding-top: 2rem;
        margin-bottom: 11.75rem;
        /* z-index: 1 !important; */
    }
    .PostGrid img {
        position: relative;
        /* grid-column: 2;
        grid-row: 1/3; */
        width: 100%;
        /* justify-self: right;
        margin-right: 1rem; */
        height: 100%;
        object-fit:cover;
        z-index: 1;
        border-radius: 14px;
        justify-content: center;
        align-content: center;
        overflow: hidden;
    }
    .PicContainer {
        grid-row: 1/3;
        grid-column: 2;
        justify-self: right;
        margin-right: 1rem;
        height: 300px;
        width: 88%;
        overflow: hidden;
        border-radius: 16px;
        box-shadow: 0 6px 12px -2px rgba(50,50,93,0.25),0 3px 7px -3px rgba(0,0,0,0.3);
        /* box-shadow: 0 13px 27px -5px rgba(21, 21, 51, 0.39),0 8px 16px -8px rgba(0,0,0,0.3); */
    }
    .shortDesc {
        font-family: "Overpass", sans-serif;
        padding-left: 1rem;
        color: #fff;
        letter-spacing: 0.25pt;
        font-weight: 300;
        font-size: medium;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .PostGridButton {
        position: relative;
        grid-column: 1;
        grid-row: 2;
        width: 180px;
        height: 40px;
        padding-top: 3px;
        margin-left: 1rem !important;
        font-family: 'Overpass', sans-serif;
        font-weight: 400 !important;
        color: rgb(232, 188, 123);
        /* box-shadow: 0 4px 6px hsl(0deg 0% 13% / 0.33); */
        background: transparent;
        border: 5px solid transparent !important;
        font-size: 11.2pt;
        border-radius: 5px;
        letter-spacing: 0.3pt;
        margin-top: -1rem !important;
    }
    .PostGridButton:hover {
        border: 5px solid transparent !important;
        letter-spacing: 0.3pt;
        font-size: 11.2pt;
        padding-top: 3px !important;
        border-bottom: none;
        box-shadow: 0 0px 0px 4px hsl(123, 43%, 39%);
        /* border: 3px solid rgba(199, 199, 199, 0.397) !important; */
        transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    }
    .PostGridIcon {
        transform: translateX(3px);
    }
    /* .Post { */
        /* height: 500px !important; */
        /* background: #0077FF !important;
        border: 1px solid #fff !important; */
        /* margin-bottom: 1px; */
    /* } */
    .QuestionFeed {
        width: 100%;
        height: 92.5%;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) !important;
        border-radius: 15px !important;
        border:1px solid rgba(255, 255, 255, 0.18) !important;
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px -5px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px -10px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 50px 100px rgba(0, 0, 0, 0.12)
        ;
        border-top: 2px solid rgba(75, 75, 75, 0.2) !important;
        border-bottom: 2px solid rgba(236, 236, 236, 0.2) !important;
        background: rgba(199, 199, 199, 0.297) !important;
        justify-self: flex-end !important;
    }
    .QuestionFeedWrapper {
        border-radius: 10px;
        background: #fcfcfb;
        box-shadow: inset 0 1px 7px 0 hsla(0, 0%, 0%, 0.7);
        color: rgba(244, 241, 224, 0.096);
        position: relative;
        display: grid;
        grid-template-rows: 5% 80% 5%;
        max-width: 95.4%;
        height: 98%;
        padding: 1rem;
        gap: 18px;
        margin: auto;
        margin-top: 2%;
        overflow-x: hidden;
    }
    .QuestionFeedWrapper span{
        font-family: 'Inter', sans-serif;
        font-size: medium;
        letter-spacing: 0.3pt;
        max-width: 380px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;    
    }
    .QFthird {
        grid-row: 1;
        color: hsla(161, 56%, 10%, 0.8);
        display: flex;
        gap: 1rem;
        position: relative;
        height: 100%;
    }
    .QFthird p {
        font-family: 'Overpass', sans-serif;
        font-weight: 700;
        letter-spacing: 0.2px;
        position: relative;
        text-align: center;
        justify-content: center;
        height: 24px;
        padding: 0.5rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;  
    }
    .QFavatar {
        width: 40px;
        height: 40px;
        background: #0077FF;
        border-radius: 50px;
    }
    @keyframes blicky {
        0% {opacity: 0.9;}
        50% {opacity: 0;}
        100% {opacity: 0.9;}
    }
    .QFonline {
        position: relative;
        width: 9px;
        height: 9px;
        background: #81c784;
        border-radius: 50px;
        animation: blicky 1.5s ease-out infinite;
    }
    .QFup {
        height: 100%;
        grid-row: 3;
        margin-top:-5px;
        padding-bottom: 7px;
    }
    .QFup label {
        font-family: 'Overpass', sans-serif;
        color: hsla(161, 56%, 10%, 0.8);
        padding: 0px 1px;
        text-align: left;
        height: max-content;
        font-size: large;
        font-weight: 600;
        letter-spacing: 0.25px;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    #planeButton {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #d9b173;
        background: #fcfcfb;
        overflow: hidden;
        transition: .25s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .25s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .25s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .25s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .25s cubic-bezier(.165, .84, .44, 1);
    }
    #planeButton :nth-child(1) {
        animation: fly .5s cubic-bezier(.165, .84, .44, 1);
        color: hsla(161, 56%, 10%, 0.8);
    }
    #planeButton:hover {
        box-shadow: 0 0px 0px 5px hsl(123, 43%, 39%), inset 0 2px 6px 0 rgb(0 0 0 / 0.47);
        border: none;
    }
    #planeButton:hover :nth-child(1) {
        animation: antifly 0.35s cubic-bezier(0.4,0,0.2,1) forwards !important;
    }
    #clientQuestion {
        height: 30px !important;
        width: 240px;
        padding-bottom: 0px;
        padding-top: 0;
        margin-bottom: 0px;
    }
    .QFdown {
        margin-left: -1.15rem !important;
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
        grid-row: 2;
        overflow-x: hidden;
        width: 110%;
        position: relative;
        /* align-self: center; */
        margin: auto;
        height: 100%;
        cursor:ns-resize;
        padding: 1rem;
        overflow-y: scroll;
        display: flex;
        flex-direction:column;
        max-height: 644px;
        color: #0b271e;
        gap: 0.95rem;
        border-bottom: 1px solid #bdbdbd;
    }
    .QFdown::-webkit-scrollbar {
        display: none;
        width: 3px;
        overflow: hidden;
    }
    .QFdown::-webkit-scrollbar-track {
        display: none;
        border-radius: 0px;
        margin-top: 1rem !important;
        margin-bottom: 1rem;
    }
    .qMessage {
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: right;
        gap: -10px !important;
        color: hsla(161, 56%, 10%, 0.9);
    }
    .aMessage {
        margin-bottom: 1rem;
        margin-left: 0.25rem;
    }
    .qMessage p, .aMessage p {
        font-family: 'Overpass', sans-serif;
        font-weight: 500;
        letter-spacing: 0.1pt;
        font-size: medium;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .qMessage span, .aMessage span { 
        font-size: small;
        padding-top: 1px;
        letter-spacing: 0.15pt;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .qBox {
        /* background: hsla(161, 56%, 10%, 0.72); */
        /* background: hsla(97, 85%, 28%, 0.56); */
        background: hsla(123, 38%, 57%, 1);
        margin-top: -1rem;
        padding: 0.75rem 0.9rem 0.9rem 0.75rem;
        max-width: 96% !important;
        min-width: 20%;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        position: relative !important;
        box-sizing: border-box;
        align-self: flex-end;
        text-align: flex-end !important;
        /* display: inline-block !important; */
    }
    .qBox span {
        color: #fff;
        position: relative;
        font-size: 11pt !important;
        letter-spacing: 0.5px;
        font-weight: 500;
        padding-left: 0.25rem;
        word-wrap: break-word !important;
        margin: 0;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .aBox {
        margin-bottom: 4px;
        border: 1px solid transparent;
        background: hsla(0, 0%, 13%, 0.78);
        padding: 0.75rem 1rem 0.9rem 0.5rem;
        max-width: 95% !important;
        min-width: 20%;
        box-sizing: border-box;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        position: relative;
        align-self: flex-start;
    }
    .aBox span {
        color: #fff;
        word-wrap: break-word !important;
        margin: 0;
        position: relative;
        font-size: 11pt !important;
        letter-spacing: 0.5px;
        left: 0.2rem;
        font-weight: 500;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
}

@keyframes fly {
    0% {
        transform: translateX(-50px);
    }
    100% {
        transform: translate(0px);
    }
}
@keyframes antifly {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(50px);
    }
}

@media (max-width: 480px) {
    .InfoGrid {
        padding-top: 1rem;
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: 74.5%;
        gap: 3.25rem;
        max-width: 96%;
        justify-content: space-between !important;
        justify-content: center;
        margin: auto;
    }
    .BlogFeed {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 81vh;
        background:
            linear-gradient(to right, #d9b173 3px, transparent 3px) 0 0,
            linear-gradient(to right, #d9b173 3px, transparent 3px) 0 100%,
            linear-gradient(to left, #d9b173 3px, transparent 3px) 100% 0,
            linear-gradient(to left, #d9b173 3px, transparent 3px) 100% 100%,
            linear-gradient(to bottom, #d9b173 3px, transparent 3px) 0 0,
            linear-gradient(to bottom, #d9b173 3px, transparent 3px) 100% 0,
            linear-gradient(to top, #d9b173 3px, transparent 3px) 0 100%,
            linear-gradient(to top, #d9b173 3px, transparent 3px) 100% 100%;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: 32px 32px;
        z-index: 100 !important;
        padding-bottom: 3px;
    }
    .BlogWrapper p {
        border-left: 3px solid #d9b173;
        z-index: 10;
        padding-left: 5px;
    }
    .BlogWrapper {
        width: 96.5%;
        height: 100% !important;
        margin: auto;
        display: block;
        z-index: 101 !important;
        overflow-y: scroll !important;
        overflow-x: hidden !important;
    }
    .BlogWrapper::-webkit-scrollbar {
        width: 3px;
        overflow: hidden;
    }
    .BlogWrapper::-webkit-scrollbar-track {
        box-shadow: inset 1px 2px 4px hsla(0, 0%, 0%, 0.33);
        border-radius: 5px;
        margin-bottom: 72px;
    }
    .BlogWrapper::-webkit-scrollbar-thumb {
        background-color: hsla(153, 36%, 36%, 0.9);
        border-radius: 5px;
        max-height: 100px !important;
    }
    .FeedHeader {
        width: 100%;
        height: 88px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 1.15rem;
        z-index: 1;
    }
    .VK {
        font-size: xx-large;
        color: #0077FF;
        z-index: 100;
        transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    }
    .VK:hover {
        filter: drop-shadow(0 0 10px hsla(212, 100%, 50%, 0.7));
    }  
    .FeedHeader h2 {
        font-size: large;
        color: #d9b173;
        font-weight: 400;
        letter-spacing: 0.35pt;
        user-select: none;
    }
    .TopicRow {
        font-family: 'Overpass', sans-serif;
        letter-spacing: 0.35pt;
        font-size: 10.5pt;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 2rem;
        z-index: 10 !important;
    }
    .Topic {
        font-weight: 300;
        border-left: 3px solid #d9b173;
        color: #fff;
        padding-left: 1rem;
        height: fit-content;
        padding-top: 3px;
        letter-spacing: 0.5px;
    }
    .Date {
        padding-right: 1rem;
        font-weight: 300;
        color: #fff;
        border-left: 1px solid #d9b173;
        padding-left: 1rem;
        height: fit-content;
        padding-top: 3px;
        letter-spacing: 0.05pt;
    }
    .iTitle {
        color: rgb(232, 188, 123);
        font-size: large;
        padding-left: 1rem;
        letter-spacing: 0.15pt;
        max-width: 94%;
    }
    .PostGrid {
        display: grid;
        position: relative !important;
        width: 100% !important;
        grid-template-columns: 45% 50% !important;
        grid-template-rows: 90% auto;
        justify-content: space-between !important;
        justify-content: center;
        padding-top: 2rem;
        margin-bottom: 11.75rem;
    }
    .PostGrid img {
        position: relative;
        /* grid-column: 2;
        grid-row: 1/3; */
        width: 100%;
        /* justify-self: right;
        margin-right: 1rem; */
        height: 100%;
        object-fit:cover;
        z-index: 1;
        border-radius: 14px;
        justify-content: center;
        align-content: center;
        overflow: hidden;
    }
    .PicContainer {
        grid-row: 1/3;
        grid-column: 2;
        justify-self: right;
        margin-right: 1rem;
        height: 240px;
        width: 88%;
        overflow: hidden;
        border-radius: 16px;
        box-shadow: 0 6px 12px -2px rgba(50,50,93,0.25),0 3px 7px -3px rgba(0,0,0,0.3);
        /* box-shadow: 0 13px 27px -5px rgba(21, 21, 51, 0.39),0 8px 16px -8px rgba(0,0,0,0.3); */
    }
    .shortDesc {
        font-family: "Overpass", sans-serif;
        padding-left: 1rem;
        color: #fff;
        letter-spacing: 0.25pt;
        font-weight: 300;
        font-size: medium;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .PostGridButton {
        position: relative;
        grid-column: 1;
        grid-row: 2;
        width: 180px;
        height: 40px;
        padding-top: 3px;
        margin-left: 1rem !important;
        font-family: 'Overpass', sans-serif;
        font-weight: 400 !important;
        color: rgb(232, 188, 123);
        /* box-shadow: 0 4px 6px hsl(0deg 0% 13% / 0.33); */
        background: transparent;
        border: 5px solid transparent !important;
        font-size: 11.2pt;
        border-radius: 5px;
        letter-spacing: 0.3pt;
        margin-top: 0.5rem !important;
    }
    .PostGridButton:hover {
        border: 5px solid transparent !important;
        letter-spacing: 0.3pt;
        font-size: 11.2pt;
        padding-top: 3px !important;
        border-bottom: none;
        box-shadow: 0 0px 0px 4px hsl(123, 43%, 39%);
        /* border: 3px solid rgba(199, 199, 199, 0.397) !important; */
        transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .35s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    }
    .PostGridIcon {
        transform: translateX(3px);
    }
    /* .Post { */
        /* height: 500px !important; */
        /* background: #0077FF !important;
        border: 1px solid #fff !important; */
        /* margin-bottom: 1px; */
    /* } */
    .QuestionFeed {
        width: 100%;
        height: 710px !important;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) !important;
        border-radius: 15px !important;
        border:1px solid rgba(255, 255, 255, 0.18) !important;
        /* box-shadow: 0 23px 50px 0px hsl(0deg 0% 13% / 0.33) !important; */
        /* box-shadow: 0 30px 60px -12px rgba(42,42,73,0.23),0 18px 36px -18px rgba(0,0,0,0.3); */
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px -5px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px -10px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 50px 100px rgba(0, 0, 0, 0.12)
        ;
        border-top: 2px solid rgba(75, 75, 75, 0.2) !important;
        border-bottom: 2px solid rgba(236, 236, 236, 0.2) !important;
        background: rgba(199, 199, 199, 0.297) !important;
        justify-self: flex-end !important;
    }
    .QuestionFeedWrapper {
        border-radius: 10px;
        background: #fcfcfb;
        box-shadow: inset 0 1px 7px 0 hsla(0, 0%, 0%, 0.7);
        color: rgba(244, 241, 224, 0.096);
        position: relative;
        display: grid;
        grid-template-rows: 5% 80% 5%;
        max-width: 95.4%;
        max-height: 97.8% !important;
        padding: 1rem;
        gap: 18px;
        margin: auto;
        margin-top: 2%;
        overflow-x: hidden;
    }
    .QuestionFeedWrapper span{
        font-family: 'Inter', sans-serif;
        font-size: medium;
        letter-spacing: 0.3pt;
        max-width: 380px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;    
    }
    .QFthird {
        grid-row: 1;
        color: hsla(161, 56%, 10%, 0.8);
        display: flex;
        gap: 1rem;
        position: relative;
        height: 100%;
    }
    .QFthird p {
        font-family: 'Overpass', sans-serif;
        font-weight: 700;
        letter-spacing: 0.2px;
        position: relative;
        text-align: center;
        justify-content: center;
        height: 24px;
        padding: 0.5rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;  
    }
    .QFavatar {
        width: 40px;
        height: 40px;
        background: #0077FF;
        border-radius: 50px;
        overflow: hidden;
    }
    .QFavatar img {
        object-fit: cover;
        position: relative;
        width: 100%;
        scale: 1.25;
    }
    @keyframes blicky {
        0% {opacity: 0.9;}
        50% {opacity: 0;}
        100% {opacity: 0.9;}
    }
    .QFonline {
        position: relative;
        width: 9px;
        height: 9px;
        background: #81c784;
        border-radius: 50px;
        animation: blicky 1.5s ease-out infinite;
    }
    .QFup {
        height: 100%;
        grid-row: 3;
        margin-top:-12px;
        padding-bottom: 7px;
        justify-self: center;
    }
    .QFup label {
        font-family: 'Overpass', sans-serif;
        color: hsla(161, 56%, 10%, 0.8);
        padding: 0px 1px;
        text-align: left;
        /* margin-left: -1px; */
        height: max-content;
        font-size: large;
        font-weight: 600;
        letter-spacing: 0.25px;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    #planeButton {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        border: 1px solid #d9b173;
        background: #fcfcfb;
        overflow: hidden;
        transition: .25s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .25s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .25s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .25s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .25s cubic-bezier(.165, .84, .44, 1);
    }
    #planeButton :nth-child(1) {
        animation: fly .5s cubic-bezier(.165, .84, .44, 1);
        color: hsla(161, 56%, 10%, 0.8);
    }
    #planeButton:hover {
        box-shadow: 0 0px 0px 5px hsl(123, 43%, 39%), inset 0 2px 6px 0 rgb(0 0 0 / 0.47);
        border: none;
    }
    #planeButton:hover :nth-child(1) {
        animation: antifly 0.35s cubic-bezier(0.4,0,0.2,1) forwards !important;
    }
    #clientQuestion {
        height: 30px !important;
        width: 240px;
        padding-bottom: 0px;
        padding-top: 0;
        margin-bottom: 0px;
    }
    .QFdown {
        margin-left: -1.15rem !important;
        padding-top: 1.75rem !important;
        padding-bottom: 1.75rem !important;
        grid-row: 2;
        overflow-x: hidden;
        width: 110%;
        position: relative;
        /* align-self: center; */
        margin: auto;
        height: 100%;
        cursor:ns-resize;
        padding: 1rem;
        overflow-y: scroll;
        display: flex;
        flex-direction:column;
        max-height: 644px;
        color: #0b271e;
        gap: 0.95rem;
        border-bottom: 1px solid #bdbdbd;
    }
    .QFdown::-webkit-scrollbar {
        display: none;
        width: 3px;
        overflow: hidden;
    }
    .QFdown::-webkit-scrollbar-track {
        display: none;
        border-radius: 0px;
        margin-top: 1rem !important;
        margin-bottom: 1rem;
    }
    .QFdown::-webkit-scrollbar-thumb {
        background-color: hsla(153, 36%, 36%, 0.9);
        border-radius: 0px;
    }
    .qMessage {
        display: flex;
        flex-direction: column;
        position: relative;
        text-align: right;
        gap: -10px !important;
        color: hsla(161, 56%, 10%, 0.9);
    }
    .aMessage {
        margin-bottom: 1rem;
        margin-left: 0.25rem;
    }
    .qMessage p, .aMessage p {
        font-family: 'Overpass', sans-serif;
        font-weight: 500;
        letter-spacing: 0.1pt;
        font-size: medium;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .qMessage span, .aMessage span {
        font-size: small;
        padding-top: 1px;
        letter-spacing: 0.15pt;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .qBox {
        background: hsla(123, 38%, 57%, 1);
        margin-top: -1rem;
        padding: 0.75rem 0.9rem 0.9rem 0.75rem;
        max-width: 96% !important;
        min-width: 20%;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        position: relative !important;
        box-sizing: border-box;
        align-self: flex-end;
        text-align: flex-end !important;
    }
    .qBox span {
        color: #fff;
        position: relative;
        font-size: 11pt !important;
        letter-spacing: 0.5px;
        font-weight: 500;
        padding-left: 0.25rem;
        word-wrap: break-word !important;
        margin: 0;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .aBox {
        margin-bottom: 4px;
        border: 1px solid transparent;
        background: hsla(0, 0%, 13%, 0.78);
        padding: 0.75rem 1rem 0.9rem 0.5rem;
        max-width: 95% !important;
        min-width: 20%;
        box-sizing: border-box;
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;
        border-bottom-right-radius: 15px;
        position: relative;
        align-self: flex-start;
    }
    .aBox span {
        color: #fff;
        word-wrap: break-word !important;
        margin: 0;
        position: relative;
        font-size: 11pt !important;
        letter-spacing: 0.5px;
        left: 0.2rem;
        font-weight: 500;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
}