@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&family=Inter:wght@100;200;300;400;500;600;700;800&family=Overpass:wght@200;300;400;500;600;700;800&display=swap');
#app { height: 100% }
html,
body {
  display: flexbox;
  min-height: 100%;
  position:relative;
}
body {
  margin: 0;
  padding: 0;
  background: linear-gradient(0deg,  #3b7d5f, #0f281e);
  /* linear-gradient(30deg,  #3b7d5f, #0f281e); */
  /* background: linear-gradient(-25deg,  hsla(143, 98%, 33%, 0.99), hsla(153, 36%, 36%, 0.95), hsla(156, 45%, 11%, 0.95) ); */
  font-family: 'IBM Plex Sans', sans-serif !important;
  overflow-x: hidden;
  overflow-y: scroll;
}
.SourcePhoto {
  position: relative;
  display: block;
  width: 256px !important;
  justify-content: center !important;
  margin-top: 15rem !important;
  margin-right: auto !important;
  margin-left: auto !important;
  left: 0 !important;
  right: 0 !important;
}

@media (max-width: 480px) {
 #app, body, html {
    /* height: 100%; */
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
    color: #f5deb3;
  }
}

@media (max-width: 800px) and (orientation: landscape) {
  #app, body, html {
    display: none;
  }
}

/* 225d2f */
/* 0bab64 */
/* d9b173 */
/* 181b23 */
/* b3bbc2 */
/* 3a7c5e */

