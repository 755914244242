@media (min-width: 1200px) {
    .EPContainer {
        display: flex;
        flex-direction: row;
        flex: 2;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 10rem;
        margin-top: -4rem;
        user-select: none;
    }
    .EPContainer child {
        width: 50%;
    }
    .EPCard {
        display: flex;
        flex-direction: column;
        /*justify-items: space-around;*/
        align-items: center;
        text-align: center;
        font-family: inherit;
        border: 1px solid transparent;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        width: 820px;
        height: 520px;
        border-radius: 15px;
        border:1px solid hsl(156 45% 11% / 0.2);
        border-bottom: 2px solid hsl(156 45% 11% / 0.2);
        border-top: 2px solid hsl(156 45% 11% / 0.2);
        box-shadow: inset 0 1px 7px 0px hsl(0deg 0% 23% / 0.3);
        transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        -webkit-transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        -moz-transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        -ms-transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        -o-transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .EPCard h3 {
        color: hsl(156 45% 11% / 0.7);
        position: static;
        scale: 100%;
        transition: 0.25s linear;
        -webkit-transition: 0.25s linear;
        -moz-transition: 0.25s linear;
        -ms-transition: 0.25s linear;
        -o-transition: 0.25s linear;
        font-size: 22pt;
        font-weight: 700;
        margin-bottom: 0.3em !important;
        letter-spacing: 0.15px !important;
    }
    .EPCard span {
        color: white;
        font-size: 13.5pt;
        font-weight: 300;
        margin-bottom: 4rem;
        scale: 100%;
        transition: 0.25s linear;
        -webkit-transition: 0.25s linear;
        -moz-transition: 0.25s linear;
        -ms-transition: 0.25s linear;
        -o-transition: 0.25s linear;
        width: 210px;
        letter-spacing: 0.5px;
    }
    .EPButton {
        background-color: hsl(0 100% 50% / 0.7);
        width: 190px;
        height: 40px;
        border: none;
        border-radius: 5px;
        color: white;
        font-family: "Overpass", sans-serif;
        font-weight: 400;
        font-size: 11pt;
        letter-spacing: 0.35pt !important;
        padding-top: 3px;
        transform: translateY(0);
        transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        -webkit-transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        -moz-transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        -ms-transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        -o-transition: all 250ms cubic-bezier(0.4,0,0.2,1);
        border: 1px solid rgba(255, 255, 255, 0.5);
    }
    
    .EPCard:hover {
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        /* box-shadow: 0 35px 45px 0px hsl(0deg 0% 23% / 0.3); */
        box-shadow: 
        0 2.8px 2.2px -5px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px -5px rgba(0, 0, 0, 0.048),
        0 12.5px 10px -5px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px -5px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px -10px rgba(0, 0, 0, 0.086),
        0 50px 100px rgba(0, 0, 0, 0.12)
        ;
        background: rgba(199, 199, 199, 0.1);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
        width: 820px;
        height: 520px;
    }
    .EPCard:hover span {
        filter:
            drop-shadow(
                0 1px 10px hsl(0deg 0% 26.7% / 0.3)
            ); 
    }
    .EPCard:hover .EPButton {
        padding-top: 3px;
        transform: translateY(2px);
        box-shadow: inset 0 1px 7px hsl(0 0% 0% / 0.23);
    }
    .EPCard img {
        transform: translateY(0);
        transition: all 0.2s ease-out;
        -webkit-transition: all 0.2s ease-out;
        -moz-transition: all 0.2s ease-out;
        -ms-transition: all 0.2s ease-out;
        -o-transition: all 0.2s ease-out;
        margin-bottom: -5px;
        width: 240px;
        margin: 2rem 0 3rem 0rem;
        height: 120px;
        object-fit: cover;
    } 
    .EPCard:hover img {
        transform: translateY(-5px);
    }
}

@media (max-width: 480px) {
    .EPContainer {
        display: flex;
        flex-direction: row;
        flex: 2;
        flex-wrap: wrap;
        justify-content: space-evenly;
        width: 100%;
        margin-bottom: 6rem !important;
        margin-top: -6rem;
        gap: 1.5rem !important;
        user-select: none;
   }
   .EPContainer child {
       width: 50%;
   }
   .EPCard {
       display: flex;
       flex-direction: column;
       justify-items: space-around;
       align-items: center;
       font-family: inherit;
       border: 1px solid transparent;
       border-top: 1px solid transparent;
       border-bottom: 1px solid transparent;
       min-width: 320px !important;
       height: 280px;
       border-radius: 15px;
       border:1px solid hsl(156 45% 11% / 0.2);
       border-bottom: 1px solid hsl(156 45% 11% / 0.2);
       border-top: 1px solid hsl(156 45% 11% / 0.2);
       box-shadow: inset 0 1px 7px 0px hsl(0deg 0% 23% / 0.3);
       transition: all 0.2s cubic-bezier(0.4,0,0.2,1);
       -webkit-transition: all 0.2s cubic-bezier(0.4,0,0.2,1);
       -moz-transition: all 0.2s cubic-bezier(0.4,0,0.2,1);
       -ms-transition: all 0.2s cubic-bezier(0.4,0,0.2,1);
       -o-transition: all 0.2s cubic-bezier(0.4,0,0.2,1);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
       /* transition: .2s cubic-bezier(.165, .84, .44, 1); */
       text-align: center;
       justify-content: space-evenly;
       overflow: hidden !important;
   }
   .EPCard:hover {
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        /* box-shadow: 0 35px 45px 0px hsl(0deg 0% 23% / 0.3); */
        box-shadow: 
        0 2.8px 2.2px -5px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px -5px rgba(0, 0, 0, 0.048),
        0 12.5px 10px -5px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px -5px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px -10px rgba(0, 0, 0, 0.086),
        0 50px 100px rgba(0, 0, 0, 0.12)
        ;
        background: rgba(199, 199, 199, 0.1);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
        min-width: 320px !important;
        height: 280px;
    }
    .EPCard h3 {
        color: hsl(156 45% 11% / 0.7);
        position: static !important;
        margin-bottom: 0rem;
        transition: none;
        font-size: x-large;
        font-weight: 700;
        height: 30px;
        padding: auto;
    }
    .EPCard span {
        color: white;
        font-size: medium;
        font-weight: 400;
        margin-bottom: 1rem;
        transition: none !important;
        width: 250px;
        height: 50px;
        padding: auto;
    }

    .EPCard img {
        transform: translateY(0);
        transition: all 0.25s linear;
        -webkit-transition: all 0.25s linear;
        -moz-transition: all 0.25s linear;
        -ms-transition: all 0.25s linear;
        -o-transition: all 0.25s linear;
        width: 128px !important;
        height: 56px;
        object-fit: cover;
        transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
        -webkit-transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
        -moz-transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
        -ms-transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
        -o-transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
    } 
    .EPCard:hover span {
        scale: 100%;
    }
    .EPCard:hover .EPButton {
        box-shadow: inset 0 1px 7px hsl(0 0% 0% / 0.23);
        transform: translateY(1px);
        padding-top: 3px;
    }
    .EPButton {
        /* background-color:hsl(36, 74%, 70%); */
        background-color: hsl(0 100% 50% / 0.7);
        width: 190px;
        height: 40px;
        border: none;
        border-radius: 5px;
        color: white;
        font-family: "Overpass", sans-serif;
        font-weight: 400;
        font-size: 11pt;
        letter-spacing: 0.35pt !important;
        padding-top: 3px;
        transform: translateY(0);
        transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
        -webkit-transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
        -moz-transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
        -ms-transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
        -o-transition: all 0.15s cubic-bezier(0.4,0,0.2,1);
        border: 1px solid rgba(255, 255, 255, 0.5);
    }
    .EPCard:hover img {
        transform: translateY(-1px);
    }
}