@media (min-width:1200px) {
  .containerRow {
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between !important;
    margin-bottom: 5rem;
    width: 100%;
    user-select: none;
  }
  .containerRow child {
    width: 50%;
  }
  .js-tilt-glare{
    border-radius: 15px;
  }
  .containerRow .card {
    height: 250px;
    width: 400px;
    transform-style: preserve-3d;
    transform: translateY(1000px);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    border-radius: 15px;
    border:1px solid rgba(255, 255, 255, 0.18);
    /* box-shadow: 0 6px 18px 0px hsl(0deg 0% 5.7% / 0.33); */
    box-shadow: 0 13px 27px -5px rgba(50,50,73,0.53),0 8px 16px -8px rgba(0,0,0,0.3);
    background: rgba(199, 199, 199, 0.295);
    border-top: 2px solid rgba(75, 75, 75, 0.2);
    border-bottom: 2px solid rgba(236, 236, 236, 0.2);
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .containerRow .card .par {
    margin: 6rem 2.5rem ;
    color: rgb(255, 255, 255);
    filter:
      drop-shadow(
        0 2px 3px hsl(0deg 0% 86.7% / 0.37)
      );
    width: 56px;
    height: 56px;
    transform: translateZ(45px);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .containerRow .card p {
    color: rgb(244, 241, 224);
    position: relative;
    align-self: center;
    width: 50%;
    margin-left: -0.5rem;
    font-size: 13.2pt;
    font-weight: 300;
    font-family: "Inter", sans-serif;
    cursor: default;
    letter-spacing: 0.25pt;
  }

  .containerRow .card:hover p {
    color: wheat;
  }

  .containerRow .card h2 {
    color: white;
    font-weight: 500;
  }

  .containerRow1 {
    flex: 2;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    align-items: top;
    margin-bottom: 4rem;
    width: 100%;
    user-select: none;
  }
  .containerRow1 child {
      width: 70%;
  }

  .containerRow .card1 {
    height: 400px;
    width: 300px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    border-radius: 15px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 23px 50px 0px hsl(0deg 0% 13% / 0.33);
    /* box-shadow: 
      0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 50px 80px rgba(0, 0, 0, 0.12)
    ; */
    /* box-shadow: 0 6px 18px 0px hsl(0deg 0% 5.7% / 0.5); */
    border-top: 2px solid rgba(186, 186, 186, 0.2);
    border-bottom: 1px solid rgba(236, 236, 236, 0.2);
    margin-bottom: 3.5rem;
    display: flex;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .containerRow1 .card1:hover {
    box-shadow: 0 6px 30px 0px hsl(0deg 0% 0% / 0.43) !important;
  }
  .containerRow .card1 .par {  
    color: rgb(255, 255, 255);
    object-fit:scale-down;
    width: 100%;
    height: auto;
    scale: 1.05;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .card1:hover ~ p {
    color: #fcfcfb;
    transition: 300ms ease-out;
    -webkit-transition: 300ms ease-out;
    -moz-transition: 300ms ease-out;
    -ms-transition: 300ms ease-out;
    -o-transition: 300ms ease-out;
  }
}

@media (max-width: 480px) {
  .containerRow {
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
    width: calc(100%+10px) !important;
    user-select: none;
  }
  .containerRow child {
    width: 50%;
  }
  .js-tilt-glare{
      border-radius: 15px !important;
  } 
  .containerRow .card {
    height: 160px;
    width: 100% !important;
    transform-style: preserve-3d;
    transform: translateY(1000px);
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    border-radius: 15px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 6px 18px 0px hsl(0deg 0% 5.7% / 0.33);
    background: rgba(199, 199, 199, 0.295);
    border-top: 1px solid rgba(75, 75, 75, 0.2);
    border-bottom: 1px solid rgba(236, 236, 236, 0.2);
    margin-bottom: 1.25rem;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }
  .containerRow .card:last-child {
    margin-bottom: -4rem !important;
  }
  .containerRow .card .par {
    margin: 3rem 2rem !important;
    color: rgb(255, 255, 255);
    filter:
        drop-shadow(
            0 2px 3px hsl(0deg 0% 86.7% / 0.37)
        );
    width: 50px;
    height: 50px;
    transform: translateZ(45px);
  }
  .containerRow .card p {
    color: white;
    position: relative;
    align-self: center;
    width: 50% !important;
    margin-left: 0.5rem;
    font-size: medium;
    font-weight: 500;
    letter-spacing: 0.25pt;
    font-family: "Inter", sans-serif;
  }
  .containerRow .card h2 {
    color: white;
    font-weight: 400;
  }
  .containerRow1 {
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    /* justify-content: space-between; */
    /* align-items: top; */
    margin-bottom: 4rem;
    width: 100%;
    user-select: none;
  }
  .containerRow1 child {
      width: 100% !important;
  }
  .containerRow .card1 {
    height: 320px;
    width: 240px;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    border-radius: 15px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 23px 50px 0px hsl(0deg 0% 13% / 0.33);
    border-top: 1px solid rgba(186, 186, 186, 0.2);
    border-bottom: 1px solid rgba(236, 236, 236, 0.2);
    margin-bottom: 2.5rem;
    display: flex;
    overflow: hidden;
    align-self: center;
  }
  .containerRow1 .card1:hover {
    box-shadow: 0 6px 30px 0px hsl(0deg 0% 0% / 0.43) !important;
  }
  .containerRow .card1 .par {  
    color: rgb(255, 255, 255);
    object-fit:scale-down;
    width: 100%;
    height: auto;
    scale: 1.05;
  }
}

@media (max-width: 375px) {
  .containerRow .card p {
    color: white;
    position: relative;
    align-self: center;
    width: 52% !important;
    margin-left: 0.5rem;
    font-size: 11pt;
    font-weight: 500;
    letter-spacing: 0.25pt;
    font-family: "Inter", sans-serif;
  }
}