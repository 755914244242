@media (min-width: 1200px) {
    .butnot-second {
        width: 190px;
        height: 40px;
        position: relative;
        color: white;
        background-color: hsl(36deg, 57%, 65%, 0.95);
        border-bottom: 1px solid rgba(255, 255, 255, 0.392) !important;
        font-size: 11pt;
        border-radius: 45px;
        border: none;
        overflow: hidden;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        padding-top: 3px;
        user-select: none;
    }
    .butnot-second:hover {
        background-color: rgba(255, 0, 0, 0.69);
        width: 190px;
        padding-right: 10px;
        /* on mobile padding left (reverse behavior)  */
        filter:
            drop-shadow(
                0 6px 18px hsl(36deg, 57%, 65%, 0.25)
            );
        -webkit-filter: 
            drop-shadow(
                0 6px 18px hsl(36deg, 57%, 65%, 0.25)
            );
    }
    .butnot-second .fa {
        position: absolute;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        right: 3.3%;
        color: white;
        transform: translateX(-50%);
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        opacity: 0;
    }
    .butnot-second:hover .fa {
        opacity: 1;
        transform: translateX(0);
    }
    .butnot-second span {
        color: white;
        font-family: "Overpass", sans-serif;
        font-weight: 400;
        font-size: 11pt;
        letter-spacing: 0.25px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

@media (max-width: 480px) {
    .butnot-second {
        width: 190px;
        height: 40px;
        position: relative;
        color: white;
        background-color: hsl(36deg, 57%, 65%, 0.95);
        border-bottom: 1px solid rgba(255, 255, 255, 0.392) !important;
        /* font-family: inherit; */
        font-size: 11pt;
        border-radius: 45px;
        border: none;
        overflow: hidden;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        user-select: none;
        padding-top: 3px;
    }
    .butnot-second:hover {
        background-color: rgba(255, 0, 0, 0.69);
        width: 190px;
        padding-left: 10px;
        /* filter:
            drop-shadow(
                0 6px 18px hsl(36deg, 57%, 65%, 0.25)
            ); */
    }
    .butnot-second .fa {
        position: absolute;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        right: 4px;
        color: white;
        transform: translateX(-30%);
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        opacity: 0;
    }
    .butnot-second:hover .fa {
        opacity: 1;
        transform: translateX(0);
    }
    .butnot-second span {
        color: white;
        font-family: "Overpass", sans-serif;
        font-weight: 400;
        font-size: 11pt;
        letter-spacing: 0px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        text-align: center;
        margin-bottom: auto;
    }
}