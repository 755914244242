@media (min-width: 1200px) {
    .BlockAndRoll {
        display: block;
        background: url('../images/ocean3.webp') no-repeat center top 65% ;
        background-size: 105% ;
        width: 90% !important;
        height: 750px !important;
        margin: auto;
        overflow: visible;
        margin-top: 2rem;
        border-radius: 15px;
        object-fit: cover;
        box-shadow: inset -1px 1px 33px hsl(0 0% 0%/ 1);
        margin-bottom: 5rem;
    }
    .PaymentContainer {
        width: 83%;
        height: 90% !important;
        /* z-index: 1001; */
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 5% !important;
        bottom: 0 !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        align-items: center;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(42px);
        -webkit-backdrop-filter: blur(42px);
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0 6px 33px hsl(0deg 0% 15.7% / 0.75);
        background: rgba(199, 199, 199, 0.2);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
        transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        will-change: transform;
    }
    .RequisitesTable {
        border-radius: 15px;
        display: grid !important;
      /*  grid-template-columns: 240px 240px 120px 220px auto;*/
        /* gap: 10px; */
      /*  grid-template-rows: 85px 85px 85px 55px;*/
        border: 1px solid black;
        top: -10rem;
        margin: 5rem 1rem 2rem 1rem;
      /*  margin-bottom: 2rem;*/
        font-weight: 600;
        position: relative;
        letter-spacing: 0.35px;
        /* margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0; */
        width: fit-content;
        justify-self: center !important;
        justify-self: center;
    }
    .RequisitesTable p {
        letter-spacing: 0.35px;
      /*  height: 85px !important;*/
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #onetwo-one {
        border-top-left-radius: 14px;
        padding: 0.5rem;
        grid-column: 1 / 3;
        grid-row: 1;
        border: 1px solid black;
    }
    #three-one {
        padding: 0.5rem;
        grid-column: 3;
        grid-row: 1;
        border: 1px solid black;
        margin-bottom: 0;
    }
    #four-one {
        padding: 0.5rem;
        grid-column: 4;
        grid-row: 1;
        border: 1px solid black;
    }
    #one-two {
        padding: 0.5rem;
        grid-column: 1;
        grid-row: 2;
        border: 1px solid black;
    }
    #two-two {
        padding: 0.5rem;
        grid-column: 2;
        grid-row: 2;
        border: 1px solid black;
    }
    #three-two {
        padding: 0.5rem;
        grid-column: 3;
        grid-row: 2;
        border: 1px solid black;
        margin-bottom: 0;
    }
    #four-two {
        padding: 0.5rem;
        grid-column: 4;
        grid-row: 2;
        border: 1px solid black;
    }
    #onetwo-three {
        padding: 0.5rem;
        grid-column: 1 / 3;
        grid-row: 3;
        border: 1px solid black;
    }
    #three-three {
        padding: 0.5rem;
        grid-column: 3;
        grid-row: 3;
        border: 1px solid black;
        margin-bottom: 0;
    }
    #four-three {
        padding: 0.5rem;
        grid-column: 4;
        grid-row: 3;
        border: 1px solid black;
    }
    #onefour-four {
        padding: 0.5rem;
        grid-column: 1 / 5;
        grid-row: 4;
        border: 1px solid black;
        border-bottom-left-radius: 14px;
    }
    #five {
        grid-column: 5;
        grid-row: 1/5;
        border: 1px solid black;
        padding: 1.5rem 1.5rem;
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
    }
    .flex-buttons {
        display: flex;
        flex-direction: row;
        gap: 3rem;
    }
    #ButtonPayBack, #ButtonDownload {
        top:-10rem;
        padding-bottom: 30px;
        margin-top: 1rem;
        width: 260px;
        height: 40px;
        position: relative;
        color: white;
        background-color: hsl(36deg, 57%, 65%, 0.95);
        border-bottom: 1px solid rgba(255, 255, 255, 0.392) !important;
        font-family: "Overpass", sans-serif;
        font-weight: 400;
        font-size: 11pt;
        padding-top: 5px !important;
        letter-spacing: 0.35px;
        border-radius: 45px;
        border: none;
        overflow: hidden;
        transition: 0.15s;
        -webkit-transition: 0.15s;
        -moz-transition: 0.15s;
        -ms-transition: 0.15s;
        -o-transition: 0.15s;
        padding-top: 10px !important;
    }
    #ButtonPayBack {
        padding-right: 12px;
    }
    #ButtonPayBack:hover, #ButtonDownload:hover {
        background-color: rgba(255, 0, 0, 0.69);
        width: 260px;
        filter:
            drop-shadow(
                0 6px 18px hsl(36deg, 57%, 65%, 0.25)
            );
    }
    .TerminalRow {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        margin-top: -8rem;
        max-width: 90%;
    }
    .TerminalRow:nth-child(1) {
        width: 100% !important;
    }
    .TerminalRow:nth-child(2) {
        width: 100% !important;
        object-fit: cover;
    }
    .TerminalRow img {
        width: 320px;
        height: 320px;
        filter: drop-shadow(0 1px 9px hsl(0 0% 0% / 0.33));
        margin-left: -3rem;
        margin-right: 0rem;
        object-fit: contain;
        padding-left: 50px;
        padding-right: 50px;
    }
    .TerminalRow span {
        font-family: 'Overpass', sans-serif;
        min-width: 500px;
        max-width: 825px;
        font-weight: 600;
        letter-spacing: 0.13px;
        font-size: 20pt;
        color: rgb(244, 241, 224);
        padding-left: 3rem;
        padding-right: 3rem;
        margin-top: -4rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        filter: drop-shadow(
                0 1px 30px hsl(0 0% 0% / 1)
            );
    }
    *, ::before, ::after {
        box-sizing: border-box;
    }
    .tabsList2 {
        width: 100% !important;
        display: flex;
        flex: 1;
        text-align: center;
        z-index: 5000;
    }
    .tabHead2 {
        padding: 1.2rem;
        justify-content: center;
        cursor: pointer;
        border-bottom: none;
        box-sizing: content-box;
        position: relative;
        width: 100%;
        height: 72px;
    }
    .tabHead2:hover:not(.active3){
        border-bottom: 2px solid #ffa94d;
    }
    .tabHead2:hover:not(.active3) .BiTime {
        transform: translateY(4px);
        z-index: 1;
    }
    .tabContent2 {
        position: relative;
        flex-grow: 1 !important;
        max-width: 100%;
        height: 100%;
        align-items: center !important;
    }
    .tabContainer2 {
        width: 100%;
        padding: auto !important;
        /* width: 1420px; */
        height: 550px !important;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        z-index: 5;
        justify-content: center;
        padding-top: 9rem !important;
        align-content: center !important;
        align-self: center !important;
        align-items: center !important;
    }
    .tabs2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        position: relative;
        align-content: center !important;
    }
    .active3 {
        box-shadow: inset 0 0 7px hsl(0deg 0% 10.7% / 0.3);
        background: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1));
        border-left:0px solid rgba(255, 255, 255, 0.18);
        border-right:0px solid rgba(255, 255, 255, 0.18);
        background: rgba(199, 199, 199, 0.247);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
    }
    .active3:first-child {
        border-top-left-radius: 15px;
    }
    .active3:last-child {
        border-top-right-radius: 15px;
    }
    .tabHead2 .BiTime {
        color: rgba(255, 255, 255, 0.767);
        font-size: 22pt;
        transition: 200ms;
        -webkit-transition: 200ms;
        -moz-transition: 200ms;
        -ms-transition: 200ms;
        -o-transition: 200ms;
        margin: 5px;
    }
    .tabHead2 h3 {
        color: rgb(244, 241, 224);
        margin: 15px;
        font-size: 11pt;
        font-weight: 300;
        font-family: 'Overpass', sans-serif;
        letter-spacing: 0.4px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    } 
}


@media (max-width: 480px) {
    .BlockAndRoll {
        display: block;
        background: url('../images/ocean3.webp') no-repeat center top 65% ;
        background-size: 302% ;
        width: 100% !important;
        height: 750px !important;
        margin: auto;
        overflow: visible;
        margin-top: 2rem;
        border-radius: 15px;
        object-fit: cover;
        box-shadow: inset -1px 1px 33px hsl(0 0% 0%/ 1);
        margin-bottom: 5rem;
    }
    .PaymentContainer {
        width: 88%;
        height: 90% !important;
        /* z-index: 1001; */
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: 5% !important;
        bottom: 0 !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        align-items: center;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(42px);
        -webkit-backdrop-filter: blur(42px);
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0 6px 33px hsl(0deg 0% 15.7% / 0.75);
        background: rgba(199, 199, 199, 0.2);
        border-top: 1px solid rgba(75, 75, 75, 0.2);
        border-bottom: 1px solid rgba(236, 236, 236, 0.2);
        transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        will-change: transform;
        overflow: hidden;
    }
    .RequisitesTable {
        border-radius: 15px;
        display: grid !important;
        grid-template-columns: 160px 160px;
        grid-template-rows: auto 85px 85px 85px 85px 85px auto auto;
        top: -7.5rem;
        margin-bottom: 2rem;
        font-weight: 600;
        position: relative;
        margin-right: 3.75rem;
        padding-right: 1px;
        max-height: 400px;
        overflow-y: auto !important;
        overflow-x: hidden !important;
        letter-spacing: 0.35px;
    }
    .RequisitesTable p {
        letter-spacing: 0.35px;
        height: 85px !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #onetwo-one {
        padding: 0.5rem;
        grid-column: 1/3;
        grid-row: 2;
        border: 1px solid black;
    }
    #three-one {
        padding: 0.5rem;
        grid-column: 1;
        grid-row: 3;
        border: 1px solid black;
    }
    #four-one {
        padding: 0.5rem;
        grid-column: 2;
        grid-row: 3;
        border: 1px solid black;
    }
    #one-two {
        padding: 0.5rem;
        grid-column: 1;
        grid-row: 4;
        border: 1px solid black;
    }
    #two-two {
        padding: 0.5rem;
        grid-column: 2;
        grid-row: 4;
        border: 1px solid black;
    }
    #three-two {
        padding: 0.5rem;
        grid-column: 1;
        grid-row: 5;
        border: 1px solid black;
    }
    #four-two {
        word-wrap: break-word;
        padding: 0.5rem;
        grid-column: 2;
        grid-row: 5;
        border: 1px solid black;
    }
    #onetwo-three {
        padding: 0.5rem;
        grid-column: 1 / 3;
        grid-row: 7;
        border: 1px solid black;
    }
    #three-three {
        padding: 0.5rem;
        grid-column: 1;
        grid-row: 6;
        border: 1px solid black;
    }
    #four-three {
        word-wrap: break-word;
        padding: 0.5rem;
        grid-column: 2;
        grid-row: 6;
        border: 1px solid black;
    }
    #onefour-four {
        padding: 0.5rem;
        grid-column: 1 / 3;
        grid-row: 8;
        border: 1px solid black;
        border-bottom: 2px solid black;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
    }
    #five {
        grid-column: 1/3;
        grid-row: 1;
        border: 1px solid black;
        padding: 1.5rem 1.5rem;
        border-top-right-radius: 14px;
        border-top-left-radius: 14px;
    }
    .flex-buttons {
        display: flex;
        flex-direction: column;
    }
    #ButtonPayBack, #ButtonDownload {
        top:-9.5rem;
        padding-bottom: 28px;
        margin-top: 0.5rem;
        width: 260px;
        height: 32px !important;
        position: relative;
        margin-right: 3.75rem;
        color: white;
        background-color: hsl(36deg, 57%, 65%, 0.95);
        border-bottom: 1px solid rgba(255, 255, 255, 0.392) !important;
        font-family: "Overpass", sans-serif;
        font-weight: 400;
        font-size: 11pt;
        letter-spacing: 0.3px;
        border-radius: 45px;
        border: none;
        overflow: hidden;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        padding-top: 10px !important;
    }
    #ButtonPayBack {
        padding-right: 12px;
    }
    #ButtonPayBack:hover, #ButtonDownload:hover {
        background-color: rgba(255, 0, 0, 0.69);
        width: 260px;
        filter:
            drop-shadow(
                0 6px 18px hsl(36deg, 57%, 65%, 0.25)
            );
    }
    .TerminalRow {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
        margin-right: 3.75rem;
        margin-top: 0rem;
        max-width: 100%;
    }
    .TerminalRow:nth-child(1) {
        width: 100% !important;
    }
    .TerminalRow:nth-child(2) {
        width: 100% !important;
    }
    .TerminalRow img {
        position: relative !important;
        display: flex !important;
        width: 60%;
        filter: drop-shadow(0 1px 9px hsl(0 0% 0% / 0.33));
        padding-top: 3rem;
    }
    .TerminalRow span {
        font-family: 'Overpass', sans-serif;
        width: 320px;
        font-weight: 500;
        letter-spacing: 0.15pt;
        font-size: medium;
        margin: auto;
        color: rgb(244, 241, 224);
        margin-top: -5rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        filter: drop-shadow(
                0 1px 30px hsl(0 0% 0% / 1)
            );
    }
    *, ::before, ::after {
        box-sizing: border-box;
    }
    .tabsList2 {
        display: flex;
        flex: 1;
        text-align: center;
        z-index: 5000;
    }
    .tabHead2 {
        padding: 1.2rem;
        justify-content: center;
        cursor: pointer;
        border-bottom: 2px solid transparent;
        box-sizing: content-box;
        position: relative;
        width: 100%;
        height: 100px;
        user-select: none;
    }
    .tabHead2:hover:not(.active3){
        border-bottom: 2px solid #ffa94d;
    }
    .tabHead2:hover:not(.active3) .BiTime {
        transform: translateY(4px);
        z-index: 1;
        padding: 1px;
    }
    .tabContent2 {
        position: relative;
        flex-grow: 1 !important;
        max-width: 100% !important;
        height: 100%;
        align-items: center !important;
    }
    .tabContainer2 {
        width: 420px !important;
        height: 100% !important;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        position: relative;
        z-index: 5;
        justify-content: center !important;
        text-align: center !important;
        padding-top: 9rem !important;
        align-items: center !important;
    }
    .tabs2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        height: 100%;
        position: relative;
        align-content: center !important;
    }
    .active3 {
        box-shadow: inset 0 0 7px hsl(0deg 0% 10.7% / 0.3);
        background: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1));
        border-left:0px solid rgba(255, 255, 255, 0.18);
        border-right:0px solid rgba(255, 255, 255, 0.18);
        background: rgba(199, 199, 199, 0.247);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
    }
    .active3:first-child {
        border-top-left-radius: 15px;
    }
    .active3:last-child {
        border-top-right-radius: 15px;
    }
    .tabHead2 .BiTime {
        color: rgba(255, 255, 255, 0.767);
        font-size: 22pt;
        transition: 200ms;
        -webkit-transition: 200ms;
        -moz-transition: 200ms;
        -ms-transition: 200ms;
        -o-transition: 200ms;
        margin: 5px 0 5px 0;
    }
    .tabHead2 h3 {
        color: rgb(244, 241, 224);
        margin-top: 15px;
        margin-bottom: 15px;
        font-size: small;
        font-weight: 400;
        font-family: 'Overpass', sans-serif;
        letter-spacing: 0.4px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }    
}

@media print {
    @media (orientation: portrait) {
        .RequisitesTable {
            visibility:visible !important;
            scale: 0.6;
            align-self: center;
            opacity: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
         #one-two , #four-one , #two-two , #one-two , #four-two , #onetwo-three , #four-three , #onefour-four {
            margin-bottom: 10px;
            padding-top: 0;
        }
        #onetwo-one {
            margin-bottom: 10px !important;
            padding: 0;
        }
        #three-one, #three-two, #three-three {
            margin-bottom: 0;
        }
        #five {
            margin-top: 2.25rem;
            align-self: center;
        }
    }
    @media (orientation: landscape) {
        .RequisitesTable {
            visibility:visible !important;
            scale: 0.5;
            align-self: center !important;
            justify-self: center;
            margin: auto;
            position: relative;
            z-index: 1000;
            height: 100%;
            width: 100%;
            margin-top: -40rem;
        }
        
    }
    body {
        margin-top: -40rem;
        max-width: 100%;
        height: 100%;
        justify-items: center !important;
    }
    .BlockAndRoll {
        visibility: hidden;
    }
    .PaymentContainer {
        visibility: hidden;
    }
    #FooterP {
        display: none !important;
    }
}