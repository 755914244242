

@media (min-width: 1200px) {
    #app {
        position: relative;
        height: 100%;
        width: 1420px;
        overflow: hidden;
    }
    .mySwiperM {
        margin-top: 0.5rem;
        padding-left: 0 !important;
        padding-right: 0 !important;
        height: 450px !important;
        position: relative;
        width: 1420px;
        border-radius: 15px;
        overflow: hidden;
        transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        will-change: contents;
    }
    .mySwiperSlideM {
        overflow: hidden;
        height: 450px;
        width: 1420px;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        box-shadow: none;
        border: none !important;
        border-radius: 15px !important;
    }
    .mySwiperSlideM img {
        /* width: 1420px; */
        filter: brightness(0.82);
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 450px;
        object-fit:cover;
    }
    .mySwiperSlideM h2 {
        z-index: 1000;
        position: relative;
        font-size: 29pt;
        font-weight: 700;
        margin-bottom: 1.25rem;
        letter-spacing: 0.3pt;
        max-width: 72%;
        line-height: 1.1em;
    }
    .mySwiperSlideM span {
        z-index: 1000;
        position: relative;
        font-family: "Overpass", sans-serif;
        font-size: x-large;
        font-weight: 300;
        letter-spacing: 0.25px;
        margin-bottom: 2.25rem;
        color: #fff;
        line-height: 1.8rem;
        max-width: 86%;
    }
    .mySwiperSlideM button {
        z-index: 1000;
        position: relative;
        min-width: 300px !important;
        padding-left: 5px;
        padding-right: 5px;
        height: 48px;
        font-family: 'Overpass', sans-serif;
        font-weight: 400 !important;
        color: #fff;
        box-shadow: 0 4px 6px hsl(0deg 0% 13% / 0.33);
        background-color: hsl(36deg, 57%, 65%, 0.95);
        border: 5px solid transparent !important;
        font-size: 11.2pt;
        border-radius: 5px;
        letter-spacing: 0.3pt;
        padding-top: 3px !important;
        transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: .3s cubic-bezier(.165, .84, .44, 1);
        user-select: none;
    }
    .mySwiperSlideM button:hover {
        border: 5px solid transparent !important;
        /* background-color: rgba(255, 0, 0, 0.69); */
        letter-spacing: 0.3pt;
        font-size: 11.2pt;
        padding-top: 3px !important;
        border-bottom: none;
        box-shadow: 0 0px 0px 5px hsl(123, 43%, 39%);
        border: 3px solid rgba(199, 199, 199, 0.397) !important;
    }
    .mySwiperSlideM a {
        max-width: 320px;
    }
    .sensesTooLit {
        display: flex;
        flex-direction: column;
        position: relative;
        /* gap: 1rem; */
        justify-content: center !important;
        margin: auto;
        align-self: center;
        max-width: 53%;
        height: 100%;
        margin-top: -10rem;
        color: hsl(39, 77%, 83%);
        text-shadow: 0 0 3px #0009;
        user-select: none;
    }
}

@media (max-width: 480px) {
    #app {
        position: relative;
        height: 100% !important;
    }
    .mySwiperM {
        user-select: none;
        padding-bottom: 20rem !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        height: 100% !important;
        position: relative;
        flex-direction: row;
        width: 88% !important;
        overflow: hidden;
    }
    .mySwiperSlideM {
        user-select: none;
        overflow: hidden;
        height: 100% !important;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 18px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        /* box-shadow: 0 6px 18px hsl(0deg 0% 5.7% / 0.43); */
        box-shadow: none !important;
        border: none !important;
    }
    .mySwiperSlideM img {
        pointer-events: none;
        user-select: none;
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 420px;
        object-fit:cover;
    }
    /* .mySwiperSlideM img {
        filter: brightness(0.82);
        position: absolute;
        top: 0;
        display: block;
        width: 100%;
        height: 450px;
        object-fit:cover;
    } */
    .mySwiperSlideM h2 {
        /* z-index: 1000; */
        position: relative;
        font-size: large;
        font-weight: 700;
        margin-bottom: 1.25rem;
        letter-spacing: 0.3pt;
        max-width: 78%;
    }
    .mySwiperSlideM span {
        /* z-index: 1000; */
        position: relative;
        font-family: "Overpass", sans-serif;
        font-size: large;
        font-weight: 400;
        letter-spacing: 0.25px;
        margin-bottom: 1.25rem;
        color: #fff;
        max-width: 92%;
        line-height: 1.35rem;
    }
    .mySwiperSlideM button {
        /* z-index: 1000; */
        position: relative;
        min-width: 300px !important;
        padding-left: 5px;
        padding-right: 5px;
        height: 48px;
        font-family: 'Overpass', sans-serif;
        font-weight: 400 !important;
        color: #fff;
        box-shadow: 0 4px 6px hsl(0deg 0% 13% / 0.33);
        background-color: hsl(36deg, 57%, 65%, 0.95);
        border: 5px solid transparent !important;
        font-size: 11.2pt;
        border-radius: 5px;
        letter-spacing: 0.3pt;
        padding-top: 3px !important;
        transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: .3s cubic-bezier(.165, .84, .44, 1);
        user-select: none;
    }
    .mySwiperSlideM button:hover {
        border: 5px solid transparent !important;
        /* background-color: rgba(255, 0, 0, 0.69); */
        letter-spacing: 0.3pt;
        font-size: 11.2pt;
        padding-top: 3px !important;
        border-bottom: none;
        box-shadow: 0 0px 0px 5px hsl(123, 43%, 39%);
        border: 3px solid rgba(199, 199, 199, 0.397) !important;
    }
    .sensesTooLit {
        display: flex;
        flex-direction: column;
        position: absolute;
        justify-content: center;
        margin: auto;
        align-self: center;
        width: 100%;
        max-width: 320px;
        height: 100%;
        /* margin-top: -10rem; */
        top: 0;
        color: hsl(39, 77%, 83%);
        text-shadow: 0 0 3px #0009;
    }
}
@media (max-width: 375px) {
    .mySwiperSlideM h2 {
        /* z-index: 1000; */
        position: relative;
        font-size: large;
        font-weight: 700;
        margin-bottom: 1.25rem;
        letter-spacing: 0.3pt;
        max-width: 86%;
    }
    .mySwiperSlideM span {
        /* z-index: 1000; */
        position: relative;
        font-family: "Overpass", sans-serif;
        font-size: large;
        font-weight: 400;
        letter-spacing: 0.25px;
        margin-bottom: 1.25rem;
        color: #fff;
        max-width: 100%;
    }
    .mySwiperSlideM button {
        /* z-index: 1000; */
        position: relative;
        min-width: 300px !important;
        padding-left: 5px;
        padding-right: 5px;
        height: 48px;
        font-family: 'Overpass', sans-serif;
        font-weight: 400 !important;
        color: #fff;
        box-shadow: 0 4px 6px hsl(0deg 0% 13% / 0.33);
        background-color: hsl(36deg, 57%, 65%, 0.95);
        border: 5px solid transparent !important;
        font-size: 11.2pt;
        border-radius: 5px;
        letter-spacing: 0.3pt;
        padding-top: 3px !important;
        transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: .3s cubic-bezier(.165, .84, .44, 1);
        user-select: none;
    }
    .mySwiperSlideM button:hover {
        border: 5px solid transparent !important;
        /* background-color: rgba(255, 0, 0, 0.69); */
        letter-spacing: 0.3pt;
        font-size: 11.2pt;
        padding-top: 3px !important;
        border-bottom: none;
        box-shadow: 0 0px 0px 5px hsl(123, 43%, 39%);
        border: 3px solid rgba(199, 199, 199, 0.397) !important;
    }
    .sensesTooLit {
        display: flex;
        flex-direction: column;
        position: absolute;
        justify-content: center;
        margin: auto;
        align-self: center;
        width: 100%;
        max-width: 300px;
        height: 100%;
        /* margin-top: -10rem; */
        top: 0;
        color: hsl(39, 77%, 83%);
        text-shadow: 0 0 3px #0009;
    }
}