@media (min-width: 1200px) {
    .ECformR {
        color: white;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: 0.5rem 0;
        width: 450px;
        gap: 15px;
    }
    .ECformR label {
        font-family: inherit;
        font-size: 12pt;
        font-weight: 400;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 0.15pt;
    }
    .ECformR input {
        width: 420px;
        height: 28px;
        border-radius: 5px;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .FormGroupR a {
        text-decoration: none;
        color: #81d4fa;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all .3s cubic-bezier(.165, .84, .44, 1) !important;
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    }
    .FormGroupR a:hover {
        color: black;
    }
    .FormGroupR {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 60%;
        height: 826px;
        margin-bottom: 2rem;
        margin-top: -7rem;
        padding-left: 50px;
        padding-right: 50px;
        align-items: center;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        background: rgba(199, 199, 199, 0.2);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 50px 100px rgba(0, 0, 0, 0.12)
        ;
    }
    .FormGroupR h1 {
        color: white;
        font-family: inherit;
        font-size: 23pt;
        font-weight: 500;
        padding: 2.5rem 3.3rem;
        cursor: default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .FormGroupR p {
        color: white;
        font-family: inherit;
        font-weight: 400;
        margin-bottom: 2.5rem;
        margin-left: 10px;
        cursor: default;
        letter-spacing: 0.3px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all .3s cubic-bezier(.165, .84, .44, 1) !important;
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    }
    .imgLogo {
        width: 320px !important;
        margin-left: 2rem;
        position: relative;
        margin-bottom: -10rem;
    }
    .InputRow {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 10px;
    }
    .phoneInput {
        width: 225px;
        color: gray;
    }
    .ECformR #username, #patronymic, #phone, #email  {
        width: 192px !important;
    }
    .ECformR #password {
        margin-bottom: 0;
        width: 372px !important;
    }
    #ButnotP1 {
        margin-top: 2rem;
        width: 300px;
        height: 45px;
        font-size: 13pt;
    }
    #errorM {
        margin-top: -2rem !important;
        margin-bottom: 6rem;
        text-align: center !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        user-select: none;
    }
    #successM {
        margin-top: -2rem !important;
        margin-bottom: 6rem;
        text-align: center !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        user-select: none;
    }
    #NoAccountR {
        position: absolute;
        bottom: -10px;
    }
    #CheckBoxR {
        max-width:450px !important;
        padding-top: 16px;
    }
}

@media (max-width: 480px) {
    .ECformR {
        color: white;
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: 0;
        width: 450px;
        gap: 1rem;
    }
    .ECformR label {
        font-family: inherit;
        font-size: 12pt;
        font-weight: 400;
        align-items: flex-start;
        margin-bottom: 0.5rem;
    }
    .ECformR input {
        width: 320px !important;
        height: 28px;
        border-radius: 5px;
        align-items: center;
    }
    .FormGroupR a {
        text-decoration: none;
        color: #81d4fa;
        /* color: rgba(0, 191, 155, 1); */
    }
    .FormGroupR a:hover {
        color: black;
    }
    .FormGroupR {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 95%;
        height: 1000px;
        margin-top: 0rem;
        margin-bottom: 10rem;
        padding-left: 50px;
        padding-right: 50px;
        align-items: center;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        background: rgba(199, 199, 199, 0.2);
        border-top: 1px solid rgba(75, 75, 75, 0.2);
        border-bottom: 1px solid rgba(236, 236, 236, 0.2);
        box-shadow: 0 6px 18px 0px hsl(0deg 0% 0.7% / 0.33);
    }
    .FormGroupR h1 {
        color: white;
        font-family: inherit;
        font-size: x-large;
        font-weight: 500;
        padding: 2.5rem 3.3rem;
        cursor: default;
    }
    .FormGroupR p {
        color: white;
        font-family: inherit;
        font-weight: 400;
        margin-bottom: 2.5rem;
        margin-left: 7px !important;
        cursor: default;
        letter-spacing: 0.3px;
        padding-bottom: 0px;
    }
    .imgLogo {
        width: 280px;
        padding: 2rem 2rem;
        margin-left: 2rem;
        position: relative;
    }
    .InputRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
    .phoneInput {
        width: 225px;
        color: gray;
    }
    .ECformR #username, #patronymic, #phone, #email {
        width: 320px !important;
    }
    .ECformR #password {
        margin-bottom: 0rem;
        width: 272px !important;
        height: 28px !important;
    }
    #ButnotP1 {
        margin-top: 1rem;
        width: 300px;
        height: 45px;
        font-size: 13pt;
    }
    #errorM {
        padding-top: 0rem !important;
        margin-bottom: 5.5rem;
        max-width: 339px !important;
        margin-left: 0rem !important;
        margin-top: -1rem;
        user-select: none;
    }
    #successM {
        padding-top: 0rem !important;
        margin-bottom: 5.5rem;
        max-width: 339px !important;
        margin-left: 0rem !important;
        margin-top: -1rem;
        user-select: none;
    }
    #CheckBoxR {
        max-width: 340px !important;
        bottom: 5px;
    }
    #NoAccountR {
        position: absolute;
        bottom: 4rem;
    }
}