*, ::before, ::after {
    box-sizing: border-box;
}
.tabs1 {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    margin-bottom: 10rem;
    max-width: 100%;
    /* margin: auto; */
    position: relative;
    user-select: none;
}
.tabsList1{
    display: flex; 
    flex: 1;
    text-align: center;
}
.tabHead1 {
    padding: 1.2rem;
    justify-content: center;
    cursor: pointer;
    border-bottom: none;
    box-sizing: content-box;
    position: relative;
    width: 100%;
    height: 72px;
}
.tabHead1:hover:not(.active2){
    border-bottom: 2px solid #ffa94d;
}
.tabHead1:hover:not(.active2) .BiTime {
    transform: translateY(4px);
    z-index: 1;
}
.tabContent1 {
/*    display:flex;
    flex-direction: column;*/
    display: table;
    padding: 2rem;
    position: relative;
    height: auto;
/*    max-height: 800px !important;*/
    flex-grow: 1;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    box-shadow: 0 6px 18px hsl(0deg 0% 5.7% / 0.33);
    background: rgba(199, 199, 199, 0.21);
    border-bottom: 1px solid rgba(236, 236, 236, 0.2);
    border-left:1px solid rgba(255, 255, 255, 0.18);
    border-right:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 30px 60px -12px rgba(50,50,73,0.15),0 18px 36px -18px rgba(0,0,0,0.3);
}
.active2 {
    filter: 
        drop-shadow(
            0 2px 8px hsl(0deg 0% 10.7% / 0.3)
        )
        drop-shadow(
            0 4px 16px hsl(0deg 0% 86.7% / 0.3) 
        );
    background: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1));
    border-left:0px solid rgba(255, 255, 255, 0.18);
    border-right:0px solid rgba(255, 255, 255, 0.18);
    background: rgba(199, 199, 199, 0.247);
    border-bottom: 2px solid rgba(236, 236, 236, 0.2);
}
.active2::before {
    content: "";
    display:inline-block;
    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);
    width: 99.5%;
    height: 5px;
    background-color: #ffa94d;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.tabHead1 .BiTime {
    color: rgba(255, 255, 255, 0.767);
    font-size: 22pt;
    transition: 200ms;
    -webkit-transition: 200ms;
    -moz-transition: 200ms;
    -ms-transition: 200ms;
    -o-transition: 200ms;
    margin: 5px;
}
.active1 h2::before {
    transform: translateX(-50%);
}
.tabHead1 h3 {
    color: rgb(244, 241, 224);
    margin: 0px;
    padding-top: 12px;
    font-size: 11pt;
    font-weight: 300;
    font-family: 'Overpass', sans-serif;
    letter-spacing: 0.35pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 480px) {
    .tabs1 {
        display: flex;
        flex-direction: column;
        justify-content: center; 
        margin-bottom: 5rem !important;
        max-width: 100% !important;
        position: relative;
    }
    .tabsList1{
        display: flex; 
        text-align: center;
        overflow-x: scroll;
    }
    .tabHead1 {
        padding: 1.2rem;
        justify-content: center;
        cursor: pointer;
        border-bottom: none;
        box-sizing: content-box;
        position: relative;
        height: 75px !important;
        min-width: 120px !important;
        border: none !important;
    }
    .tabHead1 h3 {
        color: rgb(244, 241, 224);
        margin: 0px !important;
        padding-top: 10px !important;
        font-size: 10pt;
        font-weight: 300;
        font-family: 'Overpass', sans-serif;
        letter-spacing: 0.4px;
    }
    .tabsList1::-webkit-scrollbar {
        display: none;
        color: transparent !important;
    }
    .tabHead1:hover:not(.active2){
        border-bottom: none !important;
        border: none !important;
    }
    .active2::before {
        content: "";
        display:inline-block;
        position: absolute;
        top: -3px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 100% !important;
        height: 5px;
        background-color: #ffa94d;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .tabHead1:hover:not(.active2) .BiTime {
        transform: translateY(0px) !important;
        z-index: 1;
        position: static !important;
        margin-top: 5px !important;
        margin: 5px !important;
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
        margin-bottom: 5px !important;
    }
    .active2 {
        filter: 
            drop-shadow(
                0 2px 8px hsl(0deg 0% 10.7% / 0.3)
            )
            drop-shadow(
                0 4px 16px hsl(0deg 0% 86.7% / 0.3) 
            );
        background: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1));
        border-left:none !important;
        border-right:none !important;
        background: rgba(199, 199, 199, 0.247);
        border-bottom: none !important;
        min-width: 120px !important;
    }
    .tabHead1 .BiTime {
        position: static;
        color: rgba(255, 255, 255, 0.767);
        font-size: 22pt;
        transition: 200ms;
        -webkit-transition: 200ms;
        -moz-transition: 200ms;
        -ms-transition: 200ms;
        -o-transition: 200ms;
        margin-top: 5px !important;
        margin-bottom: 10px !important;
        margin: 5px !important;
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
    }
    .tabContent1 {
        padding: 0 !important;
/*        height: 650px !important;*/
        /* max-height: 500px; */
        /* overflow-y: visible; */
    }
}

@media (max-width: 375px) {
    .tabs1 {
        display: flex;
        flex-direction: column;
        justify-content: center; 
        margin-bottom: 5rem !important;
        max-width: 100% !important;
        position: relative;
    }
    .tabsList1{
        display: flex; 
        text-align: center;
        overflow-x: scroll;
    }
    .tabHead1 {
        padding: 1.2rem;
        justify-content: center;
        cursor: pointer;
        border-bottom: none;
        box-sizing: content-box;
        position: relative;
        height: 75px !important;
        min-width: 120px !important;
        border: none !important;
    }
    .tabHead1 h3 {
        color: rgb(244, 241, 224);
        margin: 0px !important;
        padding-top: 10px !important;
        font-size: 10pt;
        font-weight: 300;
        font-family: 'Overpass', sans-serif;
        letter-spacing: 0.4px;
    }
    .tabsList1::-webkit-scrollbar {
        display: none;
        color: transparent !important;
    }
    .tabHead1:hover:not(.active2){
        border-bottom: none !important;
        border: none !important;
    }
    .active2::before {
        content: "";
        display:inline-block;
        position: absolute;
        top: -3px;
        left: 50%;
        transform: translateX(-50%);
        max-width: 100% !important;
        height: 5px;
        background-color: #ffa94d;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
    }
    .tabHead1:hover:not(.active2) .BiTime {
        transform: translateY(0px) !important;
        z-index: 1;
        position: static !important;
        margin-top: 5px !important;
        margin: 5px !important;
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
        margin-bottom: 5px !important;
    }
    .active2 {
        filter: 
            drop-shadow(
                0 2px 8px hsl(0deg 0% 10.7% / 0.3)
            )
            drop-shadow(
                0 4px 16px hsl(0deg 0% 86.7% / 0.3) 
            );
        background: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.1));
        border-left:none !important;
        border-right:none !important;
        background: rgba(199, 199, 199, 0.247);
        border-bottom: none !important;
        min-width: 120px !important;
    }
    .tabHead1 .BiTime {
        position: static;
        color: rgba(255, 255, 255, 0.767);
        font-size: 22pt;
        transition: 200ms;
        -webkit-transition: 200ms;
        -moz-transition: 200ms;
        -ms-transition: 200ms;
        -o-transition: 200ms;
        margin-top: 5px !important;
        margin-bottom: 10px !important;
        margin: 5px !important;
        margin-left: auto;
        margin-right: auto;
        left: 0 !important;
        right: 0 !important;
    }
    .tabContent1 {
        padding: 0 !important;
/*        height: 680px !important;*/
        /* max-height: 500px; */
        /* overflow-y: visible; */
    }
}