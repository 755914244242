.Container {
    position: relative;
    padding: 6rem 0rem;
    bottom: 0;
    justify-content: center;
    height: 620px;
    width: 74%;
    max-width: 75%;
    margin: auto;
}
.Wrapper {
    display: flex;
    flex-direction: column;
    justify-content: top;
    user-select: none;
    margin: 2rem auto;
}
.Column {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 25px;
    margin-right: 5px;
}
.Row {
    display: grid;
    grid-template-columns: repeat(4, minmax(230px, 1fr));
    grid-gap: 25px;
    justify-content: space-between;
    color: rgb();
}
.Link {
    color: rgb(244, 241, 224);
    font-family: inherit;
    margin-bottom: 10px;
    font-size: small;
    text-decoration: none;
    font-weight: 300;
    letter-spacing: 0.0px;
    opacity: 0.9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.Link a {
    color: rgb(244, 241, 224);
    opacity: 0.9;
    margin-bottom: 5rem;
    font-size: medium;
    text-decoration: none;
    font-weight: 300;
    letter-spacing: 0.3px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.Link a:hover {
    color: #ffffff;
    transition: 200ms ease;
    -webkit-transition: 200ms ease;
    -moz-transition: 200ms ease;
    -ms-transition: 200ms ease;
    -o-transition: 200ms ease;
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: .2s cubic-bezier(.165, .84, .44, 1);
    -webkit-transition: .2s cubic-bezier(.165, .84, .44, 1);
    -moz-transition: .2s cubic-bezier(.165, .84, .44, 1);
    -ms-transition: .2s cubic-bezier(.165, .84, .44, 1);
    -o-transition: .2s cubic-bezier(.165, .84, .44, 1);
}
.PrivacyPolicy:hover {
    color: #ffffff;
    transition: 200ms ease;
    -webkit-transition: .2s ease;
    -moz-transition: .2s ease;
    -ms-transition: .2s ease;
    -o-transition: .2s ease;
    opacity: 1;
    text-decoration: underline;
    text-underline-offset: 4px;
    transition: .2s cubic-bezier(.165, .84, .44, 1);
    -webkit-transition: .2s cubic-bezier(.165, .84, .44, 1);
    -moz-transition: .2s cubic-bezier(.165, .84, .44, 1);
    -ms-transition: .2s cubic-bezier(.165, .84, .44, 1);
    -o-transition: .2s cubic-bezier(.165, .84, .44, 1);
}
.Title {
    color: rgb(244, 241, 224);
    font-size: medium !important;
    margin-bottom: 21px;
    font-weight: 600;
    font-family: 'Overpass', sans-serif;
    letter-spacing: 0.1px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fa {
    color: rgb(244, 241, 224);
    font-size: 11pt;
    margin-right: 7px;
    margin-left: 2rem;
    margin-bottom: 2px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.Copyright {
    color: rgb(244, 241, 224);
    margin-left: 3.9rem;
    margin-top: 4.75rem;
    margin-bottom: -5rem;
    font-family: inherit;
    font-size: small;
    opacity: 0.9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.Column img {
    width: 320px; 
    margin-top: -5.75rem;
    margin-left: -1rem;
    margin-bottom: -5.75rem;
}
.PrivacyPolicy {
    border: none;
    text-align: start;
    background: transparent;
    font-size: medium;
    padding-left: 0;
    letter-spacing: 0.3px;
    color: rgb(244,241,224);
    font-weight:300;
    opacity: 0.9;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#FAdress {
    font-size: medium;
    opacity: 0.9;
    letter-spacing: 0.3px 
}

@media (max-width: 480px) {
    .Container {
        position: relative;
        padding: 4rem 0rem;
        bottom: 0;
        justify-content: center;
        height: 100% !important;
        width: 90% !important;
        margin: auto;
        margin-bottom: 1rem;
    }
    
    .Wrapper {
        display: flex;
        flex-direction: column;
        justify-content: top;
        flex-wrap: wrap;
        margin: 2rem 0;
    }
    .Column {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 25px;
    }
    .Row {
        display: flex;
        grid-template-columns: repeat(4, minmax(230px, 1fr));
        gap: 1px;
        justify-content: space-between;
        color: rgb();
        flex-wrap: wrap;
    }
    .Link {
        color: rgb(244, 241, 224);
        font-family: inherit;
        margin-bottom: 10px;
        font-size: small;
        text-decoration: none;
        font-weight: 300;
        letter-spacing: 0.0px;
    }
    .Link a {
        color: rgb(244, 241, 224);
        margin-bottom: 0 !important;
        font-size: small;
        text-decoration: none;
        font-weight: 300;
        letter-spacing: 0.3px;
        opacity: 0.9;
    }
    .link svg {
        margin-left: 0;
        padding: 0 !important;
    }
    #FAdress {
        font-size: small;
        opacity: 0.9;
        letter-spacing: 0.3px 
    }
    .Link a:hover {
        color: #ffffff;
        transition: 200ms ease;
        -webkit-transition: .2s ease;
        -moz-transition: .2s ease;
        -ms-transition: .2s ease;
        -o-transition: .2s ease;
    }
    .PrivacyPolicy:hover {
        color: #ffffff;
        transition: 200ms ease;
        -webkit-transition: .2s ease;
        -moz-transition: .2s ease;
        -ms-transition: .2s ease;
        -o-transition: .2s ease;
        opacity: 1;
        text-decoration: underline;
        text-underline-offset: 4px;
    }
    .PrivacyPolicy {
        border: none;
        text-align: start;
        background: transparent;
        font-size: small;
        padding-left: 0;
        letter-spacing: 0.3px;
        color: rgb(244,241,224);
        font-weight:300;
        opacity: 0.9;
    }
    .Title {
        color: rgb(244, 241, 224);
        font-size: medium;
        margin-bottom: 1rem;
        font-weight: 600;
        font-family: 'Overpass', sans-serif;
        letter-spacing: 0.1px;
    }
    #EClogoF {
        height: 120px;
        margin-left: -22px;
        margin-top: -1rem !important;
    }
    .Title img {
        width: 256px;
    }
    .fa {
        color: rgb(244, 241, 224);
        font-size: small;
        margin-right: 10px;
        margin-bottom: 2px;
        margin-left: 0;
    }
    .Copyright {
        color: rgb(244, 241, 224);
        margin-left: 1.75rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-family: inherit;
        font-size: small;
        opacity: 0.9;
    }
    .Column img {
        width:156px; 
        margin-top: 0;
        margin-left: 0rem;
    }
}