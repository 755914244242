.ACcol0 {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
    font-family: inherit;
    gap: 3rem;
    margin-bottom: -8rem;
    height: 1000px;
}
.ACcol1 {
    display: flex;
    flex-direction: column;
    width: 50%;
    flex: 1; 
}
.ACcol1 hr {
    color: wheat;
    margin-bottom: 3rem;
    width: 410px;
}
.ACcol2 {
    display: flex;
    flex-direction: column;
    width: 50%;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    user-select: none;
}
.ACcol2 img {
    width: 320px;
    margin-left: 1.5rem;
    margin-top: 1rem;
    /* box-shadow:0 10px 20px -3px rgba(0,0,0,.45); */
    box-shadow: 
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 20px -10px rgba(0,0,0,.45),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086)
    ;
    border-radius: 52px;
}
.AC-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-between !important;
    align-items: center;
    user-select: none;
}
.AC-row h2 {
    color: #d9b173;
    padding: 2rem 0;
    margin-left: -0.8rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
#ANCphone, #ANCmail {
    color: #d9b173;
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
}
#ANCphone:hover, #ANCmail:hover {
    color: #0f281e !important;
    transition: all 0.4s cubic-bezier(.165, .84, .44, 1);
    -webkit-transition: all 0.4s cubic-bezier(.165, .84, .44, 1);
    -moz-transition: all 0.4s cubic-bezier(.165, .84, .44, 1);
    -ms-transition: all 0.4s cubic-bezier(.165, .84, .44, 1);
    -o-transition: all 0.4s cubic-bezier(.165, .84, .44, 1);
}
#ANCphone:after, #ANCmail:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    background-color: #d9b173;
    bottom: 0;
    left: 0;
    transform-origin: bottom right;
    transition: transform 0.4s cubic-bezier(.165, .84, .44, 1);
    -webkit-transition: transform 0.4s cubic-bezier(.165, .84, .44, 1);
    -moz-transition: transform 0.4s cubic-bezier(.165, .84, .44, 1);
    -ms-transition: transform 0.4s cubic-bezier(.165, .84, .44, 1);
    -o-transition: transform 0.4s cubic-bezier(.165, .84, .44, 1);
}
#ANCphone:hover:after, #ANCmail:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
.AC-row p {
    color: rgb(244, 241, 224);
    font-weight: 300;
    font-size: 14pt;
    padding: 1rem 0;
    letter-spacing: 0.15pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.AC-row img {
    width: 35px;
    filter:
        drop-shadow(
            0 2px 3px hsl(0deg 0% 10% / 0.23)
        );
}
.AC-row img:hover {
    animation: bounce 1s;
}
#ANVK {
    color: #0077FF;
    width: 35px;
    font-size: 35px;
    margin: 0;
    filter:
        drop-shadow(
            0 3px 3px hsl(0deg 0% 10% / 0.15)
        );
}
#ANVK:hover {
    animation: bounce 1s;
}
#AnCallB {
    width: 320px;
    margin-left: -1rem;
    font-size: small;
    margin-bottom: 0rem;
    letter-spacing: 0.35pt !important;
}
@keyframes bounce {
    from, 20%, 53%, 80%, to {
        transform: translate3d(0,0,0);
    }
    40%, 43% {
        transform: translate3d(0, -15px, 0);
    }
    70% {
        transform: translate3d(0, -10px, 0);
    }
    90% {
        transform: translate3d(0,-4px,0);
    }
}
.AC-h1 {
    color: #d9b173;
    font-weight: 700;
    font-size: 32pt;
    letter-spacing: 0.3px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.AC-p {
    color: rgb(244, 241, 224);
    font-size: medium;
    font-weight: 300;
    font-family: "Inter", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.35pt;
}
@media (max-width: 480px) {
    .ACcol0 {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        font-family: inherit;
        gap: 3rem;
        margin-bottom: 11rem;
        flex-wrap: wrap-reverse;
        padding-top: 1rem;
        width: 100%;
        min-height: fit-content;
        max-height: 1000px;
    }
    .ACcol1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1; 
    }
    .ACcol1 hr {
        color: wheat;
        margin-top: 0.25rem;
        width: 410px;
    }
    .ACcol2 {
        display: flex;
        flex-direction: column;
        width: 50%;
        flex-wrap: wrap;
        flex: 1;
        align-items: center;  
    }
    .ACcol2 img {
        position: absolute;
        width: 240px;
        margin-left: 0rem;
        margin-top: 1rem;
        /* margin-bottom: 5rem !important; */
        box-shadow:0 10px 20px rgba(0,0,0,.45);
        border-radius: 37.5px;
    }
    .AC-row {
        display: flex;
        flex-direction: row;
        flex: 5;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 1.25rem;
    }
    .AC-row h2 {
        color: #d9b173;
        padding: 0.05rem 0;
        margin-left: 0.8rem;
        margin-bottom: 0rem !important;
    }
    .AC-row h2:first-child {
        margin-right: 3.25rem !important;
    }
    .AC-row p {
        color: rgb(244, 241, 224);
        font-weight: 400;
        font-size: medium;
        padding: 1rem 0;
    }
    .AC-row img {
        width: 36px !important;
        margin-bottom: 0rem;
        filter: none !important;
        margin-inline: 2.25rem;
        right:-0.25rem;
    }
    .AC-row img:hover {
        animation: bounce 1s;
    }
    .AC-h1 {
        color: #d9b173;
        font-weight: 700;
        font-size: x-large;
        letter-spacing: 0.3px;
    }
    .AC-p {
        color: rgb(244, 241, 224);
        font-size: medium;
        width: 94% !important;
        font-weight: 300;
        font-family: "Inter", sans-serif;
        padding: 0 1.5rem;
    }
    .ACcol1 hr {
        color: wheat;
        margin-bottom: 3rem;
        width: 100% !important;
    }
    #AnCallB {
        width: 310px !important;
        margin-left: 0.85rem !important;
        font-size: small;
        margin-right: auto;
        margin-left: auto;
        left: 0;
        right: 0;
        margin-bottom: 3rem;
    }
    #ANVK {
        color: #0077FF;
        width: 36px !important;
        font-size: 36px !important;
        /* margin: auto !important; */
        margin-inline: 2.25rem;
        right:-0.25rem;
        filter:
            drop-shadow(
                0 2px 3px hsl(0deg 0% 10% / 0.23)
            );
    }
    #ANVK:hover {
        animation: bounce 1s;
    
    }
}

@media (max-width: 375px) {
    .ACcol0 {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        font-family: inherit;
        gap: 3rem;
        margin-bottom: 13rem;
        flex-wrap: wrap-reverse;
        padding-top: 1rem;
        width: 100%;
        min-height: fit-content;
        max-height: 1000px;
    }
    .ACcol1 {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1; 
    }
    .ACcol1 hr {
        color: wheat;
        margin-top: 0.25rem;
        width: 410px;
    }
    .ACcol2 {
        display: flex;
        flex-direction: column;
        width: 50%;
        flex-wrap: wrap;
        flex: 1;
        align-items: center;  
    }
    .ACcol2 img {
        position: absolute;
        width: 240px;
        margin-left: 0rem;
        margin-top: 1rem;
        /* margin-bottom: 5rem !important; */
        box-shadow:0 10px 20px rgba(0,0,0,.45);
        border-radius: 37.5px;
    }
    .AC-row {
        display: flex;
        flex-direction: row;
        flex: 3;
        justify-content: space-around;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 1.25rem;
        max-width: 100%;
    }
    .AC-row h2 {
        color: #d9b173;
        padding: 0.05rem 0;
        margin-left: 0.8rem;
        margin-bottom: 0rem !important;
    }
    .AC-row h2:first-child {
        margin-right: 3.25rem !important;
    }
    .AC-row p {
        color: rgb(244, 241, 224);
        font-weight: 400;
        font-size: medium;
        padding: 1rem 0;
    }
    .AC-row img {
        width: 36px !important;
        margin-bottom: 0rem;
        filter: none !important;
        margin-inline: 0.25rem;
        right:-0.25rem;
    }
    .AC-row img:hover {
        animation: bounce 1s;
    }
    .AC-h1 {
        color: #d9b173;
        font-weight: 700;
        font-size: x-large;
        letter-spacing: 0.3px;
    }
    .AC-p {
        color: rgb(244, 241, 224);
        font-size: medium;
        width: 94% !important;
        font-weight: 300;
        font-family: "Inter", sans-serif;
        padding: 0 1.5rem;
    }
    .ACcol1 hr {
        color: wheat;
        margin-bottom: 3rem;
        width: 100% !important;
    }
    #AnCallB {
        width: 310px !important;
        margin-left: 0rem !important;
        font-size: small;
        margin-right: auto;
        margin-left: auto;
        left: 0;
        right: 0;
        margin-bottom: 3rem;
    }
    #ANVK {
        color: #0077FF;
        width: 36px !important;
        font-size: 36px !important;
        /* margin: auto !important; */
        margin-inline: 0.25rem;
        right:-0.25rem;
        filter:
            drop-shadow(
                0 2px 3px hsl(0deg 0% 10% / 0.23)
            );
    }
    #ANVK:hover {
        animation: bounce 1s;
    
    }
}