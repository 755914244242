.txxt {
    color: #d9b173;
    /* color: #bc9b6a; ebbd7a*/
    font-family: "IBM Plex Sans", sans-serif;
    align-items:center;
    left: 0;
    right: 0;
    margin-right: auto;
    margin-left: 4rem;
    /* gap:10px; */
    justify-content: space-around;
    letter-spacing: 0,35pt !important;
    margin-bottom: 5rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.txxt boldx {
    text-decoration: none;
}
.txxt-a {
    /* color: #e0bb83; */
    -webkit-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1) left !important;
    -moz-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1) left !important;
    -ms-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1) left !important;
    -o-transition: 0.5s cubic-bezier(0.4, 0, 0.2, 1) left !important;
    transition: 0.5s opacity, height cubic-bezier(0.4, 0, 0.2, 1) left !important;
    color: rgb(244, 241, 224);
    font-weight: 300;
    /* letter-spacing: 0.5px; */
    font-size: 12pt;
    text-decoration: none;
    margin-top: 0.2rem;
    width: max-content;
    font-family: 'Overpass', sans-serif;
    left:0;
    margin-left:auto;
    right:0;
    margin-right:auto;
    margin-inline: 1rem;
    letter-spacing: 0.3pt;
    padding-left: 5px !important;
    padding-right: 5px !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.txxt-a:hover {
    color: white;
    transition-duration: 200ms;
    -webkit-transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -ms-transition-duration: 200ms;
    -o-transition-duration: 200ms;
}
.txxt-a::before {
    color: gray;
    background-color: transparent;
    pointer-events:none;
}
.txxt li {
    font-size: 32pt;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5rem 2rem;
    width: 300px;
    max-width: 300px;
    margin-top: 1.5rem;
    margin-left: -2rem;
    letter-spacing: 0.25px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.txxt p {
    font-size: 14pt;
    font-weight: 300;
    margin: -0.2rem 0.3rem;
    margin-top: 4px;
    font-smooth: auto 2.5em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.tnt {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: top;
    justify-content: space-between;
    position: relative;
    margin-top: 0.5rem;
    min-width: 105%;
}
.txxt-h1 {
    color: #d9b173;
    font-family: inherit;
    font-weight: 700;
    font-size: 32pt;
    display: flex;
    float: left;
    margin-top: 4rem;
    max-width: 650px;
    letter-spacing: 0.15pt !important;
}
.active {
    color: rgba(255,255,255,.7);
    transition: 0 ease;
    -webkit-transition: 0 ease;
    -moz-transition: 0 ease;
    -ms-transition: 0 ease;
    -o-transition: 0 ease;
}
.begeContainer {
  background: hsl(36 57% 65% / 1);
  height:1000px;
  margin-bottom:'6rem';
}
.begeContainer1 {
  background: hsl(36, 57%, 65%);
  height: 800px;
  margin-bottom: 0rem;
}
.begeContainer2 {
  background: hsl(36 57% 65% / 1);
  margin-bottom:'6rem';
  z-index: 1;
}
.container-column {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 3.5rem;
  }
  .container-column h2 {
    color: #d9b173;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 22.5pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .container-column p {
    color: wheat;
    width: 300px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12.3pt;
    padding-top: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
.container-column1 {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: 4rem;
    max-width: 400px !important;
  }
  .container-column1:last-child {
    margin-left: 8rem;
  }
  .container-column1:first-child {
    margin-right: 8rem;
  }
  
  .container-column1 #KAY1 img {
    object-fit: scale-down !important;
    scale: 1.25 !important;
  }
  
  .container-column1 #AAA1 img {
    object-fit: scale-down !important;
    padding-top: 0.5rem!important;
    scale: 1.15 !important;
  }
  .container-column1 h2 {
    color: hsl(156 45% 11% / 0.75);
    /* color: #3a7c5e; */
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 22.5pt;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .container-column1 p {
    color: hsl(156 45% 11% / 0.75);
    width: 300px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12.3pt;
    padding-top: 15px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .navBarEC {
    height: 160px;
    z-index:10000 !important;
  }
  .navBarEClogo {
    margin-right:-10px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 275px;
    height: 160px;
    margin-left: -6rem;
    margin-right: 4.75rem !important;
    display: flex;
  }
  .navBarEClogo img {
    margin-top: 1rem;
    position: relative;
    object-fit: cover !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 275px;
  }
  #nav-dropdown {
    color: rgb(244, 241, 224);
    font-weight: 300;
    letter-spacing: 0.3pt;
    padding-left: 12px;
    padding-right: 12px;
  }
  #nav-dropdown:hover {
    color: white;
  }
  .dropdown-menu.show.dropdown-menu-dark {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    background: rgba(199, 199, 199, 0.297);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    min-width: 320px;
    scale: 1 !important;
    visibility: visible;
    transition-delay: 100ms !important;
    -webkit-transition-delay: 100ms !important;
    -ms-transition-delay: 100ms !important;
    -moz-transition-delay: 100ms !important;
    -o-transition-delay: 100ms !important;
    -webkit-transition: 0.5s ease left, width, height, visibility  !important;
    -moz-transition: 0.5s ease left, width, height, visibility  !important;
    -ms-transition: 0.5s ease left, width, height, visibility  !important;
    -o-transition: 0.5s ease left, width, height, visibility  !important;
    transition: 0.5s ease left, width, height, visibility !important;
    margin-top: -6px;
  }
  a.dropdown-item {
    color: #ffffff;
    font-weight: 400;
    letter-spacing: 0.3pt;
  }
  a.dropdown-item:hover {
    color: #0f281e;
  }
  .DropdownRow {
    font-family: 'Overpass', sans-serif;
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-bottom: 0.5rem;
    padding: 1rem 2rem;
    font-weight: 400;
    letter-spacing: 0.3px;
    text-shadow: 0 3px 30px #000;
    filter: brightness(1.25);
    color: #d9b173;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .DropdownRow #Ur {
    font-weight: 700;
    font-family: 'Overpass', sans-serif;
  }
  .DropdownLink {
    font-weight: 700;
    color: #d9b173;
    padding: 1rem 2rem;
    filter: brightness(1.25);
    font-size: medium;
  }
  .DropdownLink:hover {
    color: #0f281e;
    transition: 100ms linear;
    -webkit-transition: 100ms linear;
    -moz-transition: 100ms linear;
    -ms-transition: 100ms linear;
    -o-transition: 100ms linear;
  }
  .NavContacts {
    display: flex;
    flex-direction: column;
    color: #d9b173;
    text-align: right;
    padding-top: 10px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #NavPhone {
    text-decoration: none;
    font-size: larger;
    color: #d9b173;
    margin-right: -3.75rem;
    font-weight: 500;
  }
  #NavWorkingHours {
    font-size: small;
    margin-right: -3.5rem;
  }
  .dropdown-menu.dropdown-menu-dark {
    -webkit-transition: visibility, width, height 0.5s ease right left !important;
    -moz-transition: visibility, width, height 0.5s ease left !important;
    -ms-transition: visibility, width, height 0.5s ease left !important;
    -o-transition: visibility, width, height 0.5s ease left !important;
    transition: 0.5s ease left, visibility, width, height !important;
    visibility: hidden;
  }

  @media (max-width: 480px) {
    .navbar {
        user-select: none;
        max-height: 110px !important;
        width: 94% !important;
        z-index:1030 !important;
        margin: auto !important;
    }
    .collapse.show {
        visibility: visible;
        overflow: hidden !important;
    }
    .navbar-collapse.collapse {
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
        height: 885px !important;
        position: relative !important;
        top: -6rem !important;
        padding-top: 7rem;
        padding-bottom: 5rem;
        border-radius: 15px !important;
        border-bottom-left-radius: 15px !important;
        border-bottom-right-radius: 15px !important;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        border:1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0 6px 18px 0px hsl(0deg 0% 5.7% / 0.33);
        background: rgba(72,91,83,255);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
        transition-property: height, opacity, padding-bottom, padding-top, background;
        transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1) !important;
        -webkit-transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
        z-index:999 !important;
    }
    .navbar-collapse.collapse.in { 
        display: block!important;
        z-index:999 !important;
        border:1px solid rgba(255, 255, 255, 0.18);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
    }  
    .collapsing {
      overflow-y: hidden!important;
      max-height: 100% !important;
      position: relative !important;
      top: 0 !important;
      margin-top: -6rem !important;
      padding-top: 6rem;
      padding-bottom: 5rem;
      transition-property: height, opacity, padding-bottom, padding-top, background;
      transition: all 0.45s cubic-bezier(0.4, 0, 0.2, 1) !important;
      -webkit-transition: all .45s cubic-bezier(0.4, 0, 0.2, 1);
      -moz-transition: all .45s cubic-bezier(0.4, 0, 0.2, 1);
      -ms-transition: all .45s cubic-bezier(0.4, 0, 0.2, 1);
      -o-transition: all .45s cubic-bezier(0.4, 0, 0.2, 1);
      z-index:999 !important;
      border:1px solid rgba(255, 255, 255, 0.18);
      background: rgba(72,91,83,0.5);
      border-top: 2px solid rgba(75, 75, 75, 0.2);
      border-bottom: 2px solid rgba(236, 236, 236, 0.2);
    }
    /* .collapsing {
        border-radius: 15px !important;
        overflow: hidden!important;
        background-color: wheat;
        max-height: 100%!important;
        margin-top: -6rem;
        padding-top: 7rem;
        animation: fadeIn 0.5s ease;
        -webkit-transition-property: height;
        transition-property: height;
        -webkit-transition-duration: 0.35s ;
        transition-duration: 0.35s;
        -webkit-transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1); 
    } */
    .navBarEClogo.navbar-brand {
      justify-content: center;
      min-width: 100% !important;
      max-width: 420px;
      height: 120px;
      margin: auto;
      margin-left: 0 !important;
      margin-bottom: -3.55rem;
    }
    .navBarEClogo {
        display: flex;
        position: relative;
        justify-self: center !important;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        top:0;
        left: 0;
        right: 0;
        z-index: 2000 !important;
    }
    .navBarEClogo img {
        display: flex;
        justify-items: center !important;
        height:64px;
        width: 128px;
    }
    .navbar-toggler {
        position:relative !important;
        display: block !important;
        margin-left: 0rem !important;
        margin-top: -1rem;
        top: -2rem;
        border: 2px solid rgba(236, 236, 236, 0.2);
        border-radius: 15px !important;
        z-index: 5000 !important;
        padding: 1rem !important;
    }
    .txxt-a {
      position: relative;
      left: -2rem;
      align-self: center;
      margin-bottom: 6px !important;
      -webkit-font-smoothing: subpixel-antialiased;
      margin-top: .45rem !important;
    }

    #navbarAdaptive {
      position: absolute !important;
      margin-left: auto !important;
      margin-right: auto !important;
      left: 0;
      right: 0;
      margin-top: 2rem !important;
    }
    
    .tnt {
      font-size: small;
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: top;
      justify-content: space-evenly;
      position: relative;
      margin-top: 15rem;
      user-select: none;
    }
    .txxt p {
      font-size: medium;
      font-weight: 300;
      margin: -0.2rem 0.3rem;
      margin-top: 4px;
      /* font-smooth: auto 2.5em; */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .txxt li {
      font-size: xx-large;
      font-weight: 700;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem 0rem;
      width: 100%;
      max-width: 230px;
      margin-top: 0.5rem;
      margin-left: 0rem !important;
    }
    .txxt-h1 {
      color: #d9b173;
      font-family: inherit;
      font-weight: 700;
      font-size: x-large;
      margin-top: 2rem !important;
      max-width: 92% !important;
      margin-bottom: -0.25rem;
    }
    .container-column {
      margin-bottom: 2.25rem;
    }
    .container-column h1 {
      margin-top: 1rem !important;
    }
    .container-column hr {
      max-width: 98% !important;
    }
    .begeContainer {
      background: hsl(36 57% 65% / 1);
      height:500px !important;
      margin-bottom: 4rem !important;
    }
    .begeContainer1 {
      background: hsl(36, 57%, 65%);
      max-height: 620px !important;
      margin-bottom: -1px !important;
    }
    .begeContainer2 {
      background: hsl(36 57% 65% / 1);
      margin-bottom: 1rem !important;
      z-index: 1;
    }
    .container-column1:last-child {
      margin-left: 0rem !important;
    }
    .container-column1:first-child {
      margin-right: 0rem !important;
    }
    .container-column1 h2 {
      color: hsl(156 45% 11% / 0.75);
      /* color: #3a7c5e; */
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: x-large;
      padding: 0 4rem;
    }
    .container-column1 p {
      color: hsl(156 45% 11% / 0.75);
      width: 368px !important;
      font-family: 'Inter', sans-serif;
      font-weight: 400;
      font-size: medium;
      padding: 1rem 4rem;
      margin-bottom: 0;
    }
    .NavContacts {
      display: flex;
      flex-direction: column;
      color: #d9b173;
      text-align: center;
      padding-top: 10px;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    #NavPhone {
      text-decoration: none;
      font-size: larger;
      color: #d9b173;
      margin-right: 0rem;
      font-weight: 500;
    }
    #NavWorkingHours {
      position: relative;
      font-size: small;
      margin-right: 0rem !important;
    }
    #MPabout {
      margin-top: -4rem;
    }
    #MPabout hr {
      width: 98% !important;
    }
    .DropdownRow {
      font-family: 'Overpass', sans-serif;
      display: flex;
      flex-direction: column;
      gap: 0;
      margin-top: 6.5rem;
      padding: 1rem 2rem !important;
      font-weight: 400;
      letter-spacing: 0.3px;
      text-shadow: 0 3px 30px #000;
      filter: brightness(1.25);
      color: #d9b173;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    .DropdownRow span:not(#Ur) {
      user-select: all;
    }
    .DropdownRow #Ur {
      font-weight: 700;
      font-family: 'Overpass', sans-serif;
    }
    .DropdownLink {
      font-weight: 700;
      color: #d9b173;
      padding: 1rem 2rem;
      filter: brightness(1.25);
      font-size: medium;
    }
    .DropdownLink:hover {
      color: #0f281e;
      transition: 100ms linear;
      -webkit-transition: 100ms linear;
      -moz-transition: 100ms linear;
      -ms-transition: 100ms linear;
      -o-transition: 100ms linear;
    }
    .active {
      color: #d9b173;
      pointer-events: none;
    }
    .txxt-a:hover {
      color: #d9b173;
      transition: all 0.2s cubic-bezier(.165, .84, .44, 1);
      -webkit-transition: all .2s cubic-bezier(.165, .84, .44, 1);
      -moz-transition: all .2s cubic-bezier(.165, .84, .44, 1);
      -ms-transition: all .2s cubic-bezier(.165, .84, .44, 1);
      -o-transition: all .2s cubic-bezier(.165, .84, .44, 1);
    }
  }
  
  @media print {
    .navBarEC {
      display: none !important;
    }
  }