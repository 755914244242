@media (min-width:1200px) {
    .calcWrapper {
        display:flex;
        gap: 10px;
        align-items: flex-end;
        margin-top: 0.75rem;
        width: 1000px;
    }
    .calcBottomWrapperO {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        align-items: flex-start;
        max-height: 100%;
        width: 86% !important;
        /* height: 42px; */
    }
    .calcBottomWrapperO a {
        text-decoration: none;
        color: #81d4fa;
        transition: .2s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: transform .2s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: transform .2s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: transform .2s cubic-bezier(.165, .84, .44, 1);
        -o-transition: transform .2s cubic-bezier(.165, .84, .44, 1);
    }
    .calcBottomWrapperO a:hover {
        color: #000;
    }
    #c1, #c2 {
        width: 320px !important;
        margin-bottom: 0;
    }
    #formGroupOlabel {
        margin-top:1.12rem;
        margin-bottom: 0.15rem;
    }
    .checkOps {
        gap: 2rem;
        margin-bottom: 1.5rem;
        margin-top: 0.5rem;
        font-weight: 200 !important;
        letter-spacing: 0.5px 
    }
    .table_for_arrears{
        width: 100vh;
        border-collapse: collapse;
        margin-top: 10px;
    }
    .table_for_arrears thead{
        color: black;
        background-color: #f0f0f0;
    }
    .table_for_arrears thead th{
        padding: 5px; /* Внутренний отступ ячейки */
        text-align: center;
        border: 1px solid #ddd;
    }
    .table_for_arrears tbody tr{
        border-bottom: 1px solid #ddd;
    }
    .table_for_arrears tbody td{
        padding: 2px; /* Внутренний отступ ячейки */
        text-align: center;
        border: 1px solid #ddd;
    }
    .white-border-input {
        border: 2px solid white !important;
        color: white !important;
    }
}
@media (max-width:480px) {
    .checkOps {
        gap: 2px;
        margin-bottom: 1rem;
        margin-top: 0.5rem;
        font-weight: 200 !important;
        letter-spacing: 0.25pt;
    }
    #c1, #c2 {
        width: 320px !important;
        margin-bottom: 0;
    }
    .calcWrapper {
        display:flex;
        gap: 10px;
        align-items: flex-end;
        margin-top: 0.75rem;
    }
    .calcWrapper label {
        color: rgb(244, 241, 224);
        font-family: inherit;
        font-weight: 400;
        font-size: 12.35pt;
        letter-spacing: 0.1px;
        width: auto !important;
        position: relative;
    }
    .calcBottomWrapperO {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        height: fit-content;
        /* height: 42px; */
    }
    .calcBottomWrapperO div {
        position: relative !important;
        z-index: 100 !important;
        height: fit-content;
        color: rgb(244, 241, 224);
        font-family: inherit;
        font-weight: 400;
        font-size: 12.35pt;
        letter-spacing: 0.1px;
        /* min-width: 220px !important; */
        position: relative;
    }
    .calcBottomWrapperO a {
        text-decoration: none;
        color: #81d4fa;
        transition: transform .2s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: transform .2s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: transform .2s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: transform .2s cubic-bezier(.165, .84, .44, 1);
        -o-transition: transform .2s cubic-bezier(.165, .84, .44, 1);
    }
    .calcBottomWrapperO a:hover {
        color: #000;
    }
    #formGroupOlabel {
        margin-top:1.12rem;
        margin-bottom: 0.15rem;
        font-size: medium;
        font-weight: 400;
        letter-spacing: 0.25pt;
    }
    .table_for_arrears{
        width: 100%;
        border-collapse: collapse;
        margin-top: 10px;
    }
    .table_for_arrears thead{
        color: black;
        background-color: #f0f0f0;
    }
    .table_for_arrears thead th{
        padding: 5px; /* Внутренний отступ ячейки */
        text-align: center;
        border: 1px solid #ddd;
    }
    .table_for_arrears tbody tr{
        border-bottom: 1px solid #ddd;
    }
    .table_for_arrears tbody td{
        padding: 2px; /* Внутренний отступ ячейки */
        text-align: center;
        border: 1px solid #ddd;
    }
    .white-border-input {
        border: 2px solid white !important;
        color: white !important;
    }
}