
#app {
    position: relative;
    height: 100%;
    /* display: flex;
    flex-wrap: wrap;
    flex-direction: row; */
}

.swiper1 {
    width: 550px !important;
    height: 625px !important;
    padding-bottom: 30px;
    padding-left: 8px;
    padding-right: 8px;
}
.swiper-slide1 {
    text-align: center;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.swiper1 .swiper-zoom-container>img {
    position: relative;
    object-fit:cover;
    object-position:center;
    margin-bottom: 20rem;
    height: auto;
    width: 60%;
}
.swiper-nz-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.swiper-nz-container img {
    width: 45%;
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
}
.swiper1 .swiper-nz-container>a {
    font-size: larger;
    font-weight: 600 !important;
    position: absolute;
    bottom: 10rem !important;
    object-position: center;
    color: #81d4fa;
    max-width: 99%;
    text-decoration: none;
    letter-spacing: 0.3px;
    transition: all .3s cubic-bezier(.165, .84, .44, 1);
    -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
}
.swiper1 .swiper-nz-container>a:hover {
    /* color: rgb(232, 188, 123); */
    color: black;
}
.swiper-slide1:nth-child(1n) {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    border-radius: 15px;
    border:1px solid rgba(255, 255, 255, 0.18);
    /* box-shadow: 0 6px 10px hsl(0deg 0% 20.7% / 0.37); */
    box-shadow: 0 6px 12px -2px rgba(50,50,93,0.25), 0 3px 7px -3px rgba(0,0,0,0.3);
    background: rgba(199, 199, 199, 0.297);
    border-top: 2px solid rgba(75, 75, 75, 0.2);
    border-bottom: 2px solid rgba(236, 236, 236, 0.2);
}
.swiper-button-next,
.swiper-button-prev {
    scale: 64%;
    padding: 2rem 2.5rem !important;
    /* color: #42a5f5 !important; */
    color: #d9b173 !important;
    /* color: #03a9f4 #2c83ed !important; */
    filter:
        drop-shadow(
            /* 0 1px 1px hsl(207deg, 90%, 61%, 0.5) */
            0 1px 1px hsla(36, 57%, 65%, 0.3)
        );
}
.doc-p {
    max-width: 450px;
    color: rgb(244, 241, 224);
    padding: 3rem 0rem;
    margin-right: 12rem;
    font-size: 18px;
    font-family:'Inter', sans-serif;
    font-weight: 400;
    letter-spacing: 0.35pt;
    margin-bottom: 5rem;
    margin-right: 13rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 480px) {
    .swiper1 {
        width: 100% !important;
        /* width: 644px !important; */
        height: 420px !important;  
        margin-bottom: 2rem !important;
        padding-left: 11px !important;
        padding-right: 11px !important;
        padding-bottom: 16px;
        padding-top: 12px;
        overflow: hidden !important;
    }
    .swiper-slide1 {
        text-align: center;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden !important;
    }
    .swiper1 .swiper-zoom-container>img {
        position: relative;
        object-fit:cover;
        object-position:center;
        /* margin-bottom: 20rem; */
        height: auto;
        width: 60%;
    }
    
    .swiper-nz-container {
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .swiper-nz-container img {
        width: 35%;
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
    .swiper1 .swiper-nz-container>a {
        position: relative;
        padding-bottom: 8em;
        object-position:center;
        color: #d9b173;
        max-width: 99%;
        text-decoration: none;
    }
    .swiper1 .swiper-nz-container>a:hover {
        color: rgb(232, 188, 123);
    }
    .swiper-slide1:nth-child(1n) {
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        border-radius: 30px;
        border:1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0 6px 10px hsl(0deg 0% 20.7% / 0.37);
        background: rgba(199, 199, 199, 0.297);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2); 
    }
    .swiper-button-next,
    .swiper-button-prev {
        scale: 64%;
        padding: 0rem 2rem !important;
        /* color: #42a5f5 !important; */
        color: #d9b173 !important;
        /* color: #03a9f4 !important; */
        filter:
            drop-shadow(
                /* 0 1px 1px hsl(207deg, 90%, 61%, 0.5) */
                0 1px 1px hsla(36, 57%, 65%, 0.3)
            );
    }
    .doc-p {
        width: 99%;
        color: rgb(244, 241, 224);
        padding: 0rem 1rem;
        margin-right: 12rem;
        font-size: medium;
        font-family:'Inter', sans-serif;
        font-weight: 400;
        letter-spacing: 0.25pt;
        margin-bottom: 4rem;
        margin-right: 0 !important;
    }
}

@media (max-width:375px) {
    .doc-p {
        width: 96%;
        color: rgb(244, 241, 224);
        padding: 0rem 1rem;
        /* margin-right: 12rem; */
        font-size: medium;
        font-family:'Inter', sans-serif;
        font-weight: 400;
        letter-spacing: 0.25pt;
        margin-bottom: 4rem;
        margin-right: 0 !important;
    }
}