
@media (min-width: 1200px) {
  #app {
    position: relative;
    height: 100%;
  }

  .SwiperMainz {
    width: 100%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .swiper-button-prev {
    left: -5px !important;
    color: rgb(255, 255, 255) !important;
    fill: rgb(255, 255, 255) !important; 
  }
  .swiper-button-next {
    right: -5px !important;
    color: rgb(255, 255, 255) !important;
    fill: rgb(255, 255, 255) !important; 
  }

  .swiperN {
    padding-top: 50px !important;
    padding-bottom: 250px !important;
    padding-left: 35px !important;
    padding-right: 35px !important;
    height: 100% !important;
    position: relative;
    justify-content: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1600px;
  }
  .swiper-slide {
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    padding-top: 20rem;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border:1px solid rgba(255, 255, 255, 0.18);
    /* box-shadow: 0 15px 33px hsl(0deg 0% 5.7% / 0.23); */
    box-shadow: 
    0 2.8px 2.2px -5px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px -5px rgba(0, 0, 0, 0.048),
    0 12.5px 10px -5px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px -5px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px -10px rgba(0, 0, 0, 0.086),
    0 50px 100px rgba(0, 0, 0, 0.12)
    ;
    /* box-shadow: 0 30px 60px -12px rgba(50,50,93,0.3),0 18px 36px -18px rgba(0,0,0,0.3); */
    background: rgba(199, 199, 199, 0.297);
    border-top: 2px solid rgba(75, 75, 75, 0.2);
    border-bottom: 2px solid rgba(236, 236, 236, 0.2);
  }
  .swiper-slide2 {
    width: 300px;
    max-width: 500px;
    height: 550px;
    z-index: 100;
    overflow: hidden;
  }
  .newsWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 2rem;
    margin: auto;
    border-radius: 15px;
  }
  .newsWrapper p {
    position: relative;
    user-select: none;
    z-index: 1;
    width: 100%;
    color: hsla(156, 45%, 11%, 0.8);
    margin-top: -1.75rem;
    font-weight: 600;
    letter-spacing: 0.25px;
  }
  .newsWrapper img {
    position: absolute;
    top: 32px;
    left:0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270px;
    width: 332px;
    object-fit: cover;
    max-width: 95%;
    border-radius: 7px;
    border: 2px solid rgba(236, 236, 236, 0.2);
    border-top: 1px solid rgba(75, 75, 75, 0.2);
    overflow: hidden !important;
    user-select: none;
  }
  .newsButton {
    position: absolute;
    width: 180px;
    height: 40px;
    padding-top: 3px;
    font-family: 'Overpass', sans-serif;
    font-weight: 400 !important;
    color: #fff !important;
    background: transparent;
    border: 5px solid transparent !important;
    font-size: 11.2pt;
    border-radius: 5px;
    letter-spacing: 0.3pt;
    z-index: 105 !important;
    bottom: 1rem;
    user-select: none;
  }
  .newsButton:hover {
    border: 5px solid transparent !important;
    letter-spacing: 0.3pt;
    font-size: 11.2pt;
    padding-top: 3px !important;
    border-bottom: none;
    box-shadow: 0 0px 0px 4px hsl(123, 43%, 39%);
    transition: all .35s cubic-bezier(.165, .84, .44, 1);
    -webkit-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    -moz-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    -ms-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    -o-transition: all .35s cubic-bezier(.165, .84, .44, 1);
  }
}

@media (min-width: 2000px) {
  .SwiperMainz {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
}

/* .swiper-slide {
  -webkit-backdrop-filter: blur(1rem);
  backdrop-filter: blur(1rem);
  
} */
/*.swiper-slide:nth-child(2n) {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(221, 221, 221, 0.37);
    background: rgba(199, 199, 199, 0.297);
    border-top: 2px solid rgba(75, 75, 75, 0.2);
    border-bottom: 2px solid rgba(236, 236, 236, 0.2);
} */

@media (max-width: 480px) {
  .SwiperMainz {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    overflow: hidden !important;
  }
  .swiperN {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
    padding-left: 11px !important;
    padding-right: 11px !important;
    height: 100% !important;
    position: relative;
    justify-content: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden !important;
  }
  .swiper-slide2 {
    height: 450px !important;
    width: 100% !important;
    border-radius: 30px !important;
  }
  .swiper-slide {
    background-position: center;
    height: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    padding-top: 20rem;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border:1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 6px 12px hsl(0deg 0% 5.7% / 0.43);
    background: rgba(199, 199, 199, 0.297);
    border-top: 1px solid rgba(75, 75, 75, 0.2);
    border-bottom: 1px solid rgba(236, 236, 236, 0.2);
  }
  #app {
    position: relative;
    height: 100%;
    max-width: 95% !important;
  }
  .swiper-button-prev {
    left: -5px !important;
    color: rgb(255, 255, 255) !important;
    fill: rgb(255, 255, 255) !important; 
  }
  .swiper-button-next {
    right: -5px !important;
    color: rgb(255, 255, 255) !important;
    fill: rgb(255, 255, 255) !important; 
  }
  .newsWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 2rem;
    margin: auto;
    border-radius: 15px;
  }
  .newsWrapper p {
    position: relative;
    user-select: none;
    z-index: 1;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    color: hsla(156, 45%, 11%, 0.8);
    margin-top: -10.25rem;
    font-weight: 600;
    letter-spacing: 0.25px;
  }
  .newsWrapper img {
    position: absolute;
    top: 24px;
    left:0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 160px;
    width: 320px;
    object-fit: cover;
    border-radius: 15px;
    border: 2px solid rgba(236, 236, 236, 0.2);
    border-top: 1px solid rgba(75, 75, 75, 0.2);
    overflow: hidden !important;
    user-select: none;
  }
  .newsButton {
    position: absolute;
    width: 180px;
    height: 40px;
    padding-top: 3px;
    font-family: 'Overpass', sans-serif;
    font-weight: 400 !important;
    color: #fff !important;
    background: transparent;
    border: 5px solid transparent !important;
    font-size: 11.2pt;
    border-radius: 5px;
    letter-spacing: 0.3pt;
    z-index: 105 !important;
    bottom: 1rem;
    user-select: none;
  }
  .newsButton:hover {
    border: 5px solid transparent !important;
    letter-spacing: 0.3pt;
    font-size: 11.2pt;
    padding-top: 3px !important;
    border-bottom: none;
    box-shadow: 0 0px 0px 4px hsl(123, 43%, 39%);
    transition: all .35s cubic-bezier(.165, .84, .44, 1);
    -webkit-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    -moz-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    -ms-transition: all .35s cubic-bezier(.165, .84, .44, 1);
    -o-transition: all .35s cubic-bezier(.165, .84, .44, 1);
  }
}