.tabContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: fit-content !important;
    padding-left: 2rem;
    overflow: hidden;
    position: relative;
    z-index: 10;
}
.tabContainer a {
    position: relative;
    margin-top: auto;
    margin-bottom: 0rem;
}
.tabContainer Button {
    position: relative;
    margin-top: auto;
    margin-bottom: 6rem;
}
.tabContainer h2 {
    color: rgb(244, 241, 224);
    font-family: inherit;
    font-weight: 700;
    letter-spacing: 0.15pt !important;
    font-size: xx-large;
    transform: translateX(0%);
    letter-spacing: 0px;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.tabContainer p {
    color: rgb(244, 241, 224);
    font-family: inherit;
    font-weight: 300;
    font-size: 17pt;
    letter-spacing: 0.25px;
    min-width: 545px;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 480px) {
    .tabContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
/*        height: 560px !important;*/
        padding-left: 2rem;
        padding-right: 2rem;
        overflow: hidden;
        position: relative;
        z-index: 10;
    }
    .tabContainer Button {
        position: relative;
        margin-top: auto;
        margin-bottom: 6rem;
    }
    .tabContainer h2 {
        color: rgb(244, 241, 224);
        font-family: inherit;
        font-weight: 700;
        font-size: x-large;
        max-width: 300px !important;
        transform: translateX(0%);
        letter-spacing: 0.15pt;
        position: relative;
    }
    .tabContainer p {
        color: rgb(244, 241, 224);
        font-family: inherit;
        font-weight: 400;
        font-size: 12.35pt;
        letter-spacing: 0.25pt;
        min-width: 220px !important;
        position: relative;
    }
}

@media (max-width: 375px) {
    .tabContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
/*        height: 580px !important;*/
        width: 100%;
        padding-left: 1rem;
        padding-right: 1rem;
        overflow: hidden;
        position: relative;
        z-index: 10;
    }
    .tabContainer Button {
        position: relative;
        margin-top: auto;
        margin-bottom: 6rem;
    }
    .tabContainer h2 {
        color: rgb(244, 241, 224);
        font-family: inherit;
        font-weight: 700;
        font-size: x-large;
        max-width: 300px !important;
        transform: translateX(0%);
        letter-spacing: 0.15pt;
        position: relative;
    }
    .tabContainer p {
        color: rgb(244, 241, 224);
        font-family: inherit;
        font-weight: 400;
        font-size: 12.35pt;
        letter-spacing: 0.25pt;
        min-width: 220px !important;
        position: relative;
    }
}