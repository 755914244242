.Policy {
    color: #81d4fa !important;
    font-weight: 400;
    letter-spacing: 0.25px;
}
.Policy:hover {
    color: black !important;
    cursor:pointer;
}
.BBD {
    font-family: 'Overpass', sans-serif;
    font-size: small;
    border-radius: 5px;
    padding: 5px;
    width: 200px;
    height: 38px;
    border: 1px solid #0f281e;
    color: #0f281e;
    background: rgb(244, 241, 224);
    font-weight: 400;
    padding-bottom: 2px;
    letter-spacing: 0.3px;
    transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition: all 320ms cubic-bezier(.165, .84, .44, 1);
    -moz-transition: all 320ms cubic-bezier(.165, .84, .44, 1);
    -ms-transition: all 320ms cubic-bezier(.165, .84, .44, 1);
    -o-transition: all 320ms cubic-bezier(.165, .84, .44, 1);
    user-select: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.BBD:hover {
    background: hsl(36 57% 65% / 0.66); 
    box-shadow: 0 0px 0px 3px hsl(123, 43%, 39%);
    font-weight: 400;
    letter-spacing: 0.3px;
    border: 1px solid rgb(244, 241, 224);
}
#scroll-dialog-title {
    color: rgba(15, 41, 31, 0.85) !important;
    font-family: "Overpass", sans-serif;
    font-weight: 800;
    font-size: 11pt;
    letter-spacing: 0.3px;
}