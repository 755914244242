@media (min-width: 1200px) {
    #back2backZoom {
        margin-top: 1rem;
        border-radius: 15px;
        /* box-shadow: inset -2px 0px 11px hsl(156 45% 11% / 0.66); */
        position: relative;
        width: 100% !;
        height:82vh !important;
        background: url('../images/office6.png');
        background-position: top left;
        background-attachment: fixed;
        background-size: 118%;
        filter: brightness(0.9);
    }
    
    .AboutSection {
        text-shadow: 0 1px 2px hsl(0 0% 0% / 0.42);
        width: 50%;
        max-height: 400px;
        margin: auto;
        color: white;
        margin-top: -27rem;
        margin-bottom: 1.75rem;
        position: relative;
        padding: 45px;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) !important;
        border-radius: 15px !important;
        border:1px solid rgba(255, 255, 255, 0.18) !important;
        /* box-shadow: 0 11px 30px 0px hsl(0deg 0% 13% / 0.23) !important; */
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 50px 100px rgba(0, 0, 0, 0.12)
        ;
        border-top: 2px solid rgba(75, 75, 75, 0.2) !important;
        border-bottom: 2px solid rgba(236, 236, 236, 0.2) !important;
        background: rgba(169, 169, 169, 0.42) !important;
        backdrop-filter: blur(45px);
    }
    .AboutSection a {
        font-family: 'Overpass', sans-serif;
        text-decoration: none;
        color: #d9b173;
        filter: brightness(1.1);
        font-weight: 500;
        font-size: large;
        justify-items: center !important;
        margin-right: 2rem;
        transition: all 0.42s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .42s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .42s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .42s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .42s cubic-bezier(.165, .84, .44, 1);
    }
    .AboutSection a:hover {
        color: #000;
        filter: brightness(1);
        text-shadow: none;
    }
    .AboutIcon {
        margin-right: 5px;
    }
    #AboutMail {
        margin-top: -2px;
        filter: drop-shadow(0 1px 1px hsl(0 0% 0% / 0.42));
    }
    #AboutPhone {
        margin-top:-4px;
        filter: drop-shadow(0 1px 1px hsl(0 0% 0% / 0.42));
    }
    .AboutSection p {
        font-family: 'Overpass', sans-serif;
        font-weight: 400;
        letter-spacing: 0.3px;
        font-size: 13pt;
        max-width: 88%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .AboutSection h2 {
        font-weight: 700;
        color: #d9b173;
        filter: brightness(1.1);
        margin-bottom: 1rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .AboutContacts {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }
}

@media (max-width:  480px) {
    #back2backZoom {
        margin-top: 1rem;
        border-radius: 15px;
        /* box-shadow: inset -2px 0px 11px hsl(156 45% 11% / 0.66); */
        position: relative;
        width: 100% !;
        height:85vh !important;
        background: url('../images/GO.webp');
        background-position: top center;
        background-attachment: fixed;
        background-size: 150%;
        filter: brightness(0.9);
        overflow: hidden !important;
    }
    
    .AboutSection {
        text-shadow: 0 1px 2px hsl(0 0% 0% / 0.42);
        width: 88%;
        max-height: fit-content;
        margin: auto;
        color: white;
        margin-top: -40.5rem;
        margin-bottom: 1.75rem;
        position: relative;
        padding: 45px;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) !important;
        border-radius: 15px !important;
        border:1px solid rgba(255, 255, 255, 0.18) !important;
        box-shadow: 0 11px 30px 0px hsl(0deg 0% 13% / 0.23) !important;
        border-top: 1px solid rgba(186, 186, 186, 0.2) !important;
        border-bottom: 1px solid rgba(236, 236, 236, 0.2) !important;
        background: rgba(169, 169, 169, 0.42) !important;
        backdrop-filter: blur(45px);
    }
    .AboutSection a {
        font-family: 'Overpass', sans-serif;
        text-decoration: none;
        color: #d9b173;
        filter: brightness(1.1);
        font-weight: 500;
        font-size: large;
        justify-items: center !important;
        margin-right: 2rem;
        transition: all 0.42s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .42s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .42s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .42s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .42s cubic-bezier(.165, .84, .44, 1);
    }
    .AboutSection a:hover {
        color: #000;
        filter: brightness(1);
        text-shadow: none;
    }
    .AboutIcon {
        margin-right: 5px;
    }
    #AboutMail {
        margin-top: -2px;
        filter: drop-shadow(0 1px 1px hsl(0 0% 0% / 0.42));
    }
    #AboutPhone {
        margin-top:-4px;
        filter: drop-shadow(0 1px 1px hsl(0 0% 0% / 0.42));
    }
    .AboutSection p {
        font-family: 'Overpass', sans-serif;
        font-weight: 400;
        letter-spacing: 0.3px;
        font-size: 13pt;
        max-width: 100%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .AboutSection h2 {
        font-weight: 700;
        color: #d9b173;
        filter: brightness(1.1);
        margin-bottom: 1rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .AboutContacts {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-top: 1rem;
    }
}
