.MP {
    /* font-family: inherit; */
    color: #d9b173;
    padding: 0rem;
    display:flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
    flex:1;
    margin-bottom: 70px;
    font-display: auto !important;
    position: relative;
}

.MP Container {
    align-items: center;
}

.MP h1 {
    font-size: 32pt;
    font-weight: 800;
    text-align: center;
    justify-content: end;
    position: relative;
    display: inline;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    width: 80%;
    letter-spacing: 0.1pt !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.MP p {
    font-size: 17pt;
    position: relative;
    display: flex;
    width: 60%;
    text-align: center;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.FormContainer {
    max-width: 77% !important;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    z-index: 999;
}
.FormContainer:first-child {
    position: relative !important;
}
.FormContainer:last-child {
    position: absolute !important;
    margin-top: -2rem;
    /* bottom: -20rem !important; */
    /* top: 20rem !important; */
    /* margin-left: 10rem !important; */
}


@media (max-width: 480px) {
    .MP{
        padding-top: 1rem;
        display: flex !important;
        position: relative !important;
        margin-bottom: 27.5px !important;
    }
    .MP h1 {
        font-size: x-large;
        margin-bottom: 1rem;
        width: 90% !important;
    }
    .MP p {
        font-size: large;
        width: 90% !important;
    }
    .FormContainer {
        max-width: 100% !important;
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        z-index: 999;
        margin-bottom: 22rem;
    }
    .FormContainer:first-child {
        position: relative !important;
    }
    .FormContainer:last-child {
        position: absolute !important;
        /* margin-top: -4rem; */
        /* bottom: -20rem !important; */
        /* top: 20rem !important; */
        /* margin-left: 10rem !important; */
    }
}