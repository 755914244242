@media (min-width: 1200px) {
    .ECform {
        color: white;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        padding: 0rem 0;
        width: 450px;
        gap: 25px;
    }
    .ECform #password {
        margin-bottom: 0rem;
        width: 370px !important;
        height: 28px !important;
        align-items: center;
    }
    .ECform #email {
        width:420px !important
    }
    .ECform label {
        font-family: 'Overpass', sans-serif;
        font-size: medium;
        font-weight: 400;
        letter-spacing: 0.15pt;
        align-items: flex-start;
        margin-bottom: 0.5rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #FormGroupReset {
        width:550px;
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        /* justify-content: center !important; */
        align-items: center !important;
        max-height:700px;
    }
    #ButnotP2 {
        width: 300px;
        height: 45px;
        font-size: 13pt;
        margin-top: 4rem;
    }
    .ECform input {
        width: 420px !important;
        height: 28px;
        align-items: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .FormGroup a {
        text-decoration: none;
        color: #81d4fa;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all .3s cubic-bezier(.165, .84, .44, 1) !important;
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    }
    .FormGroup a:hover {
        color: black;
    }
    .FormGroup {
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 60%;
        height: 650px;
        margin-bottom: 2rem;
        /* margin-top: -3rem; */
        padding-left: 50px;
        padding-right: 50px;
        align-items: center;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        /* box-shadow: 0 6px 18px 0px hsl(0deg 0% 0.7% / 0.33); */
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 60px 100px rgba(0, 0, 0, 0.12)
        ;
        background: rgba(199, 199, 199, 0.2);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
    }
    .FormGroup h1 {
        color: white;
        font-family: inherit;
        font-size: 24pt;
        font-weight: 500;
        padding: 3.5rem 0;
        cursor: default;
        margin-bottom: -1rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .FormGroup p {
        color: white;
        font-family: inherit;
        font-weight: 400;
        margin-bottom: 2rem;
        margin-left: 10px;
        cursor: default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #NoAccount {
        position: absolute;
        bottom: 0px;
        letter-spacing: 0.35px;
    }
    .imgLogo {
        width: 320px !important;
        margin-left: 2rem;
        position: relative;
        margin-bottom: -10rem;
    }
    .SignupLink {
        display: flex;
        position:relative;
        justify-content: center;
        /* margin-right: 10.5rem; */
        padding-top: 2.5rem;
        color: white;
        font-weight: 300;
        letter-spacing: 0.35px;
        font-size: 12pt;
        cursor: default;
    }
    .SignupLink a {
        text-decoration: none;
        color: #81d4fa;
        margin-left: 10px;
        letter-spacing: 0.35px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all .3s cubic-bezier(.165, .84, .44, 1) !important;
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    }
    .SignupLink a:hover {
        color: #000000;
        font-weight: 400;
    }
    .SignupLink button {
        display: flex;
        position: relative;
        text-decoration: none;
        color: #81d4fa;
        margin-left: 10px;
        font-weight: 400;
        border: none;
        background: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all .3s cubic-bezier(.165, .84, .44, 1) !important;
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    }
    .SignupLink button:hover {
        color: #000;
        font-weight: 400;
    }
    .MiniFooter {
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .MiniFooter p {
        gap: 16px;
        margin-inline: 0.5rem;
        cursor:default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .MiniFooter a {
        margin-left: 0rem;
        color: #81d4fa;
        font-weight: 400;
        letter-spacing: 0.35px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
     
    }
    .MiniFooter a:hover {
        font-weight: 400;
    }
    .FormGroup #pReset {
        margin-left: 1rem !important;
    }
    #errorML {
        margin-top: -1rem !important;
        margin-bottom: 6rem;
        /* position: relative; */
        text-align: center !important;
        letter-spacing: 0.35pt;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #successML {
        margin-top: -1rem !important;
        margin-bottom: 6rem;
        letter-spacing: 0.35pt;
        text-align: center !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

@media (max-width:480px) {
    .ECform {
        color: white;
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        padding: 0rem 0.7rem;
        width: 100%;
        gap: 25px;
        margin-top: -1rem;
        margin-bottom: -4rem !important;
    }
    .ECform label {
        font-family: inherit;
        font-size: 12pt;
        font-weight: 400;
        align-items: flex-start;
        margin-bottom: 0.5rem;
    }
    .ECform #password {
        margin-bottom: 0rem;
        width: 270px !important;
        height: 28px !important;
    }
    .ECform #email {
        width: 318px !important;
    }
    #NoAccount {
        position: absolute;
        bottom: 0px;
        letter-spacing: 0.35px;
        /* padding-bottom: 0; */
    }
    #FormGroupReset {
        width:100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center !important;
        text-align: start;
        height:700px !important;
    }
    .ECform #passwordVerify, .ECform #password1 {
        width: 318px !important;
        align-self: center !important;
    }
    #ButnotP2 {
        width: 300px;
        height: 45px;
        font-size: 13pt;
        margin-top: 4rem;
        margin-left: 0;
        padding-top: 2px;
        margin-bottom: 0;
    }
    .ECform input {
        width: 320px;
        height: 28px;
        align-items: center !important;
    }
    .FormGroup a {
        text-decoration: none;
        color: #81d4fa;
    }
    .FormGroup a:hover {
        color: black;
    }
    
    .FormGroup {
        display: flex;
        align-self: center;
        flex-direction: column;
        flex: 1;
        /* flex-wrap: wrap; */
        max-width: 95% !important;
        height: 550px !important;
        margin-top: 0;
        margin-bottom: 5rem;
        /* padding-left: 50px;
        padding-right: 50px; */
        align-items: center;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0 6px 18px 0px hsl(0deg 0% 0.7% / 0.33);
        background: rgba(199, 199, 199, 0.2);
        border-top: 1px solid rgba(75, 75, 75, 0.2);
        border-bottom: 1px solid rgba(236, 236, 236, 0.2);
    }
    .FormGroup h1 {
        color: white;
        font-family: inherit;
        font-size: x-large;
        font-weight: 500;
        padding: 3rem 0.7rem;
        cursor: default;
        margin-bottom: 0rem;
    }
    .FormGroup #pReset {
        margin-left: 0rem !important;
    }
    .FormGroup p {
        color: white;
        font-family: inherit;
        font-weight: 400;
        margin-bottom: 3rem;
        margin-left: 7px;
        cursor: default;  
    }
    .imgLogo {
        text-align: center !important;
        width: 240px !important;
        position: relative !important;
        margin-left: 5.5rem !important;
        
        margin-bottom: -1rem;
        align-self: center !important;
    }
    .SignupLink {
        display: flex;
        position:relative;
        justify-content: center;
        /* margin-right: 10.5rem; */
        padding-top: 2.5rem;
        color: white;
        font-weight: 300;
        letter-spacing: 0.3px;
        font-size: 12pt;
        cursor: default;
    }
    .SignupLink a {
        text-decoration: none;
        color: #81d4fa;
        margin-left: 10px;
        letter-spacing: 0;
        font-weight: 400;
    }
    .SignupLink a:hover {
        color: #000000;
        font-weight: 400;
    }
    .SignupLink button {
        display: flex;
        position: relative;
        text-decoration: none;
        color: #81d4fa;
        margin-left: 10px;
        font-weight: 400;
        border: none;
        background: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all .3s cubic-bezier(.165, .84, .44, 1) !important;
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    }
    .SignupLink button:hover {
        color: #000;
        font-weight: 400;
    }
    .MiniFooter {
        display: flex;
        justify-self: center !important;
        
        justify-content: center;
        text-align: center;
        flex-direction: column;
        width: 100%;
        padding-bottom: 8rem !important;
    }
    .MiniFooter p {
        gap: 10px;
        margin-inline: 0rem !important;
        cursor:default;
        font-size: medium;
        margin-bottom: 0rem;
    }
    .MiniFooter a {
        margin-left: 0rem;
        color: #81d4fa;
        font-weight: 400;
        letter-spacing: 0.3px;
        margin-bottom: 0rem;
        padding-bottom: 0rem !important;
    }
    .MiniFooter a:hover {
        font-weight: 400;
    }
    #errorML {
        margin-top: 0rem !important;
        margin-top: -4rem;
        margin-bottom: 2.5rem;
        user-select: none;
    }
    #successML {
        margin-top: 0rem !important;
        margin-top: -4rem;
        margin-bottom: 2.5rem;
        user-select: none;
    }
    #NoAccount {
        position: absolute;
        bottom: 7.75rem;
    }
    #CheckBoxL {
        margin-bottom: -2rem !important;
        max-width: 340px;
    }
}