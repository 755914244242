@media (min-width: 1200px) {
    .MsgCircle img {
        height: 42px;
        width: 42px;
        transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        user-select: none;
    }
    .MsgCircle:hover {
        height: 250px;
        transition: 0.32s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        padding-top:0rem;
        user-select: none;
        z-index: 5000;
    }
    .MsgCircle a {
        pointer-events: none;
    }
    .MsgCircle:hover a {
        animation: fukit 0.42s forwards;
    }
    @keyframes fukit {
        0%, 99% {
            pointer-events: none;
        }
        100% {
            pointer-events: all;
        }
    }
}


@media (max-width: 480px) {
    .MsgCircle img {
        height: 42px;
        width: 42px;
        transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: 300ms cubic-bezier(0.4, 0, 0.2, 1);
        user-select: none;
            /* animation: dis-p-e 0.5s forwards cubic-bezier(0.4, 0, 0.2, 1) !important; */
    }
    .MsgCircle:hover {
        height: 250px;
        transition: 0.32s cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: .32s cubic-bezier(0.4, 0, 0.2, 1);
        padding-top:0rem;
        user-select: none;
        z-index: 5000;
    }
    .MsgCircle a {
        pointer-events: none;
    }
    .MsgCircle:hover a {
        animation: fukit 0.42s forwards;
    }
    @keyframes fukit {
        0%, 99% {
            pointer-events: none;
        }
        100% {
            pointer-events: all;
        }
    }
}