@media (min-width: 1200px) {
    .Requisites2cols {
        display: grid;
        position: relative;
        grid-template-columns: 59% 30%;
        height: 100%;
        max-width: 74%;
        margin: auto;
        justify-content: space-between;
        overflow-y: visible;
    }
    .RQh1 {
        max-width: fit-content;
        position: relative;
        color: #d9b173;
        margin-bottom: 3rem;
        left: 29.45% !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RequisitesCol1 {
        width: 100%;
    }
    .RequisitesCol1 span {
        font-family: 'Inter', sans-serif;
        letter-spacing: 0.35pt;
        display: flex;
        font-size: large;
        max-width: 810px !important;
        font-weight: 400;
        margin: auto;
        color: rgb(244, 241, 224);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RequisitesCol1 h3 {
        margin-top: 4rem;
        color: rgb(244, 241, 224);
        font-size: larger;
        letter-spacing: 0.15pt;
        font-weight: 500;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RequisitesCol2 {
        width: 100%;
        position: sticky !important;
        top: 0px !important;
    }
    .RequisitesCol2wrapper {
        position: sticky !important;
        top: 0px !important;
        min-width: 100%;
        width: 420px;
        max-height: 575px !important;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) !important;
        border-radius: 15px !important;
        border:1px solid rgba(255, 255, 255, 0.18) !important;
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px -5px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px -10px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 50px 100px rgba(0, 0, 0, 0.12)
        ;
        border-top: 2px solid rgba(75, 75, 75, 0.2) !important;
        border-bottom: 2px solid rgba(236, 236, 236, 0.2) !important;
        background: rgba(199, 199, 199, 0.297) !important;
        justify-self: flex-end !important;
        color: rgb(244, 241, 224);
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 18px;
        left:0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .RequisitesCol2wrapper a {
        color:#81d4fa;
        text-decoration: none;
        transition: .2s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: .2s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: .2s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: .2s cubic-bezier(.165, .84, .44, 1);
        -o-transition: .2s cubic-bezier(.165, .84, .44, 1);
    }
    .RequisitesCol2wrapper a:hover {
        color: #000;
    }
    .RequisitesCol2wrapper span {
        font-family: 'Inter', sans-serif;
        font-size: medium;
        font-weight: 400;
        letter-spacing: 0.3pt;
        max-width: 380px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RequisitesCol2wrapper #RQbutton {
        min-width: 300px !important;
        width: 320px;
        padding-left: 5px;
        padding-right: 5px;
        height: 48px;
        font-weight: 400;
        font-family: 'Overpass', sans-serif;
        color: #fff;
        box-shadow: 0 4px 6px hsl(0deg 0% 13% / 0.33);
        background-color: hsl(36deg, 57%, 65%, 0.95);
        border: 5px solid transparent !important;
        font-size: 11.2pt;
        margin-bottom: 32px;
        border-radius: 5px;
        letter-spacing: 0.35pt;
        padding-top: 3px !important;
        transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        user-select: none;
    }
    .RequisitesCol2wrapper #RQbutton:hover {
        border: 5px solid transparent !important;
        letter-spacing: 0.35pt;
        padding-top: 3px !important;
        border-bottom: none;
        box-shadow: 0 0px 0px 5px hsl(123, 43%, 39%);
        border: 3px solid rgba(199, 199, 199, 0.397) !important;
        transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    }
    .RQtable {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        width: 100%;
        color: rgb(244, 241, 224);
        overflow: hidden;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) !important;
        border-radius: 15px !important;
        border:1px solid rgba(255, 255, 255, 0.18) !important;
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px -5px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px -10px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 50px 100px rgba(0, 0, 0, 0.12)
        ;
        border-top: 2px solid rgba(75, 75, 75, 0.2) !important;
        border-bottom: 2px solid rgba(236, 236, 236, 0.2) !important;
        background: rgba(199, 199, 199, 0.297) !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RQtableRow {
        pointer-events: none;
        font-family: 'Overpass', sans-serif;
        justify-content: space-between;
        position: relative;
        display: flex;
        flex-direction: row !important;
        padding-inline: 2.25rem;
        font-weight: 400;
        align-content: center !important;
        overflow: hidden;
        letter-spacing: 0.1px;
    }
    .RQtableRow:first-child {
        padding-top: 1.15rem;
    }
    .RQtableRow:last-child {
        padding-bottom: 1.25rem;
    }
    .RQtableRow p:first-child {
        font-weight: 900;
        color: #d9b173;
    }   
}

@media (max-width: 480px) {
    .Requisites2cols {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 100%;
        max-width: 88%;
        margin: auto;
        justify-content: space-between;
        overflow-y: visible;
        gap: 4rem;
    }
    .RQh1 {
        max-width: fit-content;
        position: relative;
        color: #d9b173;
        margin: auto;
        margin-bottom: 2rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RequisitesCol1 {
        width: 100%;
    }
    .RequisitesCol1 span {
        font-family: 'Inter', sans-serif;
        letter-spacing: 0.35pt;
        display: flex;
        text-align: center;
        font-size: medium;
        margin: auto;
        max-width: 90% !important;
        color: rgb(244, 241, 224);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RequisitesCol1 h3 {
        margin-top: 4rem;
        color: rgb(244, 241, 224);
        font-size: larger;
        letter-spacing: 0.35pt;
        font-weight: 500;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RequisitesCol2 {
        width: 100%;
        position: sticky !important;
        top: 0px !important;
    }
    .RequisitesCol2wrapper {
        position: sticky !important;
        top: 0px !important;
        max-width: 100%;
        max-height: 575px !important;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) !important;
        border-radius: 15px !important;
        border:1px solid rgba(255, 255, 255, 0.18) !important;
        box-shadow: 0 23px 50px 0px hsl(0deg 0% 13% / 0.33) !important;
        border-top: 1px solid rgba(186, 186, 186, 0.2) !important;
        border-bottom: 1px solid rgba(236, 236, 236, 0.2) !important;
        background: rgba(199, 199, 199, 0.297) !important;
        justify-self: flex-end !important;
        color: rgb(244, 241, 224);
        display: flex;
        flex-direction: column;
        padding: 2rem;
        gap: 18px;
        left:0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }
    .RequisitesCol2wrapper a {
        color:#81d4fa;
        text-decoration: none;
        transition: .2s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: .2s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: .2s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: .2s cubic-bezier(.165, .84, .44, 1);
        -o-transition: .2s cubic-bezier(.165, .84, .44, 1);
    }
    .RequisitesCol2wrapper a:hover {
        color: #000;
    }
    .RequisitesCol2wrapper span {
        font-family: 'Inter', sans-serif;
        font-size: medium;
        letter-spacing: 0.35pt;
        max-width: 280px !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RequisitesCol2wrapper #RQbutton {
        width: 280px !important;
        padding-left: 5px;
        padding-right: 5px;
        height: 48px;
        font-family: 'Overpass', sans-serif;
        color: #fff;
        box-shadow: 0 4px 6px hsl(0deg 0% 13% / 0.33);
        background-color: hsl(36deg, 57%, 65%, 0.95);
        border: 5px solid transparent !important;
        font-size: small;
        margin-bottom: 32px;
        border-radius: 5px;
        letter-spacing: 0.3px;
        padding-top: 1px !important;
        transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        user-select: none;
    }
    .RequisitesCol2wrapper #RQbutton:hover {
        border: 5px solid transparent !important;
        font-size: small;
        letter-spacing: 0.3px;
        padding-top: 1px !important;
        border-bottom: none;
        box-shadow: 0 0px 0px 5px hsl(123, 43%, 39%);
        border: 3px solid rgba(199, 199, 199, 0.397) !important;
        transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    }
    .RQtable {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        width: 100%;
        color: rgb(244, 241, 224);
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0)) !important;
        border-radius: 15px !important;
        border:1px solid rgba(255, 255, 255, 0.18) !important;
        box-shadow: 0 23px 50px 0px hsl(0deg 0% 13% / 0.33) !important;
        border-top: 1px solid rgba(186, 186, 186, 0.2) !important;
        border-bottom: 1px solid rgba(236, 236, 236, 0.2) !important;
        background: rgba(199, 199, 199, 0.297) !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .RQtableRow {
        width: 100%;
        pointer-events: none;
        font-family: 'Overpass', sans-serif;
        justify-content: space-between !important;
        text-align: end;
        gap: 10px;
        position: relative;
        display: flex;
        flex-direction: row !important;
        padding-inline: 1rem;
        font-weight: 400;
    }
    .RQtableRow:first-child {
        padding-top: 1.15rem;
    }
    .RQtableRow:last-child {
        padding-bottom: 1.25rem;
    }
    .RQtableRow p:first-child {
        font-weight: 900;
        color: #d9b173;
        text-align: start !important;
    } 
}

