@media (min-width: 1200px) {
    .modal-height {
        min-width: 900px;
        width: 90%;
        height: 100%;
        overflow-y: scroll;
        
    }
    .modal-body {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: auto;
        min-width: 860px;
        width: 100%;
        height: 100%;
    }
    .AdminContainer {
        position: absolute;
        top: 0.75rem;
        left: 20rem;
        display: flex;
        flex-direction: column;
        z-index: 1000;
        flex:2;
        min-width: 1100px;
        height: 99%;
        background-color: #f1f8e9;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        overflow: hidden;
        color: #321e1e;
        font-family: 'Overpass', sans-serif;
        /* box-shadow: 0 9px 18px hsla(0, 0%, 0%, 0.15); */
    }
    .AdminFeed {
        display: block;
        position: relative;
        max-width: 99% !important;
        overflow-y: auto;
        scroll-behavior: auto;
        height: 100%;
        /* justify-content: space-between; */
    }
    .AdminFeed h2 {
        color:#321e1e;
        font-size: x-large;
        letter-spacing: 0.15pt;
        max-width: 80%;
    }
    .AdminFeed::-webkit-scrollbar {
        width: 9px;
        overflow: hidden;
    }
    .AdminFeed::-webkit-scrollbar-track {
        box-shadow: inset 1px 2px 4px hsla(0, 0%, 0%, 0.15);
        margin-block: 5px;
        border-radius: 25px;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
    .AdminFeed::-webkit-scrollbar-thumb {
        background-color: hsla(153, 36%, 36%, 0.9);
        border: 1px solid hsl(0 0% 100% / 0.75);
        border-radius: 25px;
        min-height: 400px;
    }
    .AdminFeedItems {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
    }
    .AdminFeedItems:last-child {
        margin-bottom: 1.25rem;
    }
    .AdminFeedItems:first-child {
        margin-top: .5rem;
    }
    .AdminFeedItem {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 2rem;
        padding-bottom: 0.25rem;
        background-color: white;
    }
    .AdminFeedItem label {
        color:#d9b173;
        padding-top: 0.9rem;
        margin-bottom: 0.25rem;
        font-size: medium;
        /* font-family: 'IBM Plex sans', sans-serif; */
        letter-spacing: 0.5pt;
        font-weight: 600;
    }
    .AFirstRow {
        display: flex;
        flex-direction: row;
        gap: 3.5px;
        font-weight: 600;
    }
    .ASecondRow {
        display: flex;
        flex-direction: row;
        width: 100%;
        min-height: 50px;
        align-items: top;
        padding-top: 1rem;
    }
    .LeftPart {
        width: 90%;
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
    }
    .RightPart {
        width: 10%;
    }
    .ACommentRow {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
    }
    .ACommentRow button {
        border-radius: 5px;
        border: 1px solid #e2e2e2;
        /* background: #fbfbfb; */
        margin-top:1rem;
    }
    .ACommentRow button:hover {
        background: #e8f5e9;
        box-shadow: inset 0 0px 6px hsla(205, 9%, 65%, 0.33);
        transition: 100ms ease-out;
        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -ms-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
    }
    .Cleft {
        display: flex;
        flex-direction: row;
        gap: 3.41px;
    }
    .Cright {
        padding-bottom: 1rem;
        padding-right: 0.75rem;
        font-size: small;
        color: #78909c;
    }
    #AArticles {
        background-color: #fbfbfb;
    }
    #AVacancies {
        background-color: #fbfbfb;
    }
    #BArticles{
        display: flex;
        position: relative;
        padding: 2rem;
        align-items: center !important;
        margin-left: auto;
        margin-right: auto !important;
        left: 0;
        right: 0;
    }
    #BArticles span {
        margin-bottom: 0.75rem;
    }
    #BVacancies {
        display: flex;
        position: relative;
        padding: 2rem;
        align-items: center !important;
        margin-left: auto;
        margin-right: auto !important;
        left: 0;
        right: 0;
        margin-bottom: 0.75rem;
    }
    #BVacancies span {
        margin-bottom: 0.75rem;
    }
    #articleSubmission {
        width: 300px;
        height: 45px;
        border-radius: 5px;
        border: 1px solid #e2e2e2;
        letter-spacing: 0.25px;
        color: #321e1e;
        font-weight: 600;
        /* background: #fbfbfb; */
        margin-top: 2rem;
        margin-bottom: 2rem;
        font-size: 10.5pt;
        padding-top: 4px;
        transition: 100ms ease-out;
        -webkit-transition: 100ms ease-out;
        -moz-transition: 100ms ease-out;
        -ms-transition: 100ms ease-out;
        -o-transition: 100ms ease-out;
    }
    #articleSubmission:hover {
        background: #e8f5e9;
        box-shadow: inset 0 0px 6px hsla(205, 9%, 65%, 0.33);

    }
    /* Код ниже для страницы AllArticles */
    #AllArticles {
        background-color: #fbfbfb;
        max-width: 900px;
        padding-top: 0.5rem;
    }
    #allFeed {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
    .AdminFeedItem textarea {
        border-color: #321e1e;
        border-radius: 3px;
    }
}

