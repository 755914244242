@media print {
    .showReqsRow span {
        /* margin-top: 4rem !important; */
        margin-right: 12px;
        margin-left: 1.25rem;
        font-weight: 700;
    }
    .showReqsRow {
        width: 600px;
        gap: 10px !important
    }
    .showReqsP {
        margin-left: 1.25rem;
    }
    .button {
        display: none;
    }
    .ButtonRow:not(dispatch) {
        display: none;
    }
}

@media (max-width: 480px) {
    #ContractsC{
        height: 485px;   
    }
    #PayC {
        height: 485px;
        text-align: center;
        margin: 200px auto;
    }
    #UploadC {
        margin: 200px auto;
        height: 785px;
        display: block;
        margin-bottom: 8rem;
    }
    #InfoC {
        height: 485px;
        min-width: 406px !important;
        margin-left: 0.25rem;
        /* width:100% !important; */
        /* min-width: 400px !important; */
    }
    #InfoN {
        height: 485px;
        min-width: 406px !important;
        margin-left: 0.25rem;
        /* width:100% !important; */
        /* min-width: 400px !important; */
    }
    #PaymentTable {
        position: relative !important;
        width: 298px;
        height: 40px;
        background: hsl(36, 57%, 65%);
        border: 1px solid white;
        /* padding-top: 5px; */
        margin-bottom: 2.5rem;
        margin-left: 5.2rem;
        text-decoration: none;
        box-shadow: 0 0 0 1px transparent;
        color: white;
        letter-spacing: 0.5px;
        font-size: small !important;
        font-weight: 400;
        font-family: "IBM Plex Sans", sans-serif;
    }
    #PaymentTable:hover {
        background: linear-gradient(30deg, hsl(36, 57%, 65%), hsl(31, 100%, 65%));
        box-shadow: 0 0 0 1px white;
        border: 2px solid white;
    }
    .ContractContainer {
        display: flex;
        position:relative !important;
        margin: auto;
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 60px 100px rgba(0, 0, 0, 0.12)
        ;
        width: 98% !important;
        
        /* left: 0 !important; */
        justify-content: center;
        border-radius: 1.3em;
        border-top: 3px solid hsla(36, 57%, 65%, 0.45);
        background-color: hsl(51 48% 92% / 1);
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 20 !important;
        margin-top: 13.45rem;
        /* scrollbar-base-color: #321e1d !important; */
    }
    
    .ContractContainer button {
        max-width: 157px;
        height: 40px;
        background: hsl(36, 57%, 65%);
        color: white;
        border: 1px solid white;
        box-shadow: 0 0 0 1px transparent;
        font-size: small;
        letter-spacing: 0.35pt;
        margin-bottom: 0.5rem;
        margin-left: -1.5rem;
        border-radius: 5px;
        user-select: none;
    }
    .ContractContainer button:hover {
        background: linear-gradient(30deg, hsl(36, 57%, 65%), hsl(31, 100%, 65%));
        box-shadow: 0 0 0 1px white;
        border: 2px solid white;
        /* transition: 100ms cubic-bezier(0, 0.33, 0.33, 0); */
    }
    .ButtonRow {
        display: flex;
        flex-direction: column;
        gap: 0 !important;
        margin-block: -0.5rem;
        /* justify-content: space-evenly; */
        z-index: 20 !important;
    }
    .DocCol {
        display:flex;
        flex-direction: column;
        position: relative;
        width: 90%;
        user-select: none;
        /* left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto; */
        /* jus-content: center; */
        align-content: flex-start;
        /* margin-top: -2rem; */
    }
    .DocCol label {
        margin-top: 2rem;
        padding-left: 2.1rem;
        margin-bottom: 4px;
    }
    .DocCol img {
        align-self: center;
        padding-top: 32px;
    }
    .DocRow {
        height: 32px !important;
        margin-bottom: 24px !important;   
    }
    .DocP {
        color: #321e1e;
        font-weight: 800;
        font-size: 17px;
        margin-left: -20px;
        margin-bottom: 4px;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .DocV {
        color: #321e1e;
        font-family: "Overpass",sans-serif;
        /* margin-left: -20px; */
    }
    .DocRow1 {
        height: 50px;
        margin-bottom: 16px;
    }
    .DocP1 {
        min-width: 370px;
        color: #321e1e;
        font-weight: 800;
        font-size: 17px;
        margin-bottom: 4px;
        margin-left: -10px;
    }
    .DocV1 {
        color: #321e1e;
        font-family: "Overpass",sans-serif;
        margin-left: -10px;
        padding-bottom: 2rem !important;
    }
    .TitleRow {
        display: flex;
        flex-direction: row;
        gap: 35px;
        margin-bottom: -25px;
        padding-top: 2rem;
    }
    .DocTP {
        color: #321e1e;
        font-weight: 700;
        font-size: 18px;
        margin-left: -30px;
        margin-bottom: 3px;
    }
    .DocTV {
        color: #321e1e;
        font-size: 18px;
        font-weight: 700;
        margin-left: -30px;
    }
    .TitleRow1 {
        display: flex;
        flex-direction: row;
        gap: 35px;
        margin-bottom: -25px;
        padding-top: 2rem;
    }
    .DocTP1 {
        color: #321e1e;
        font-weight: 700;
        font-size: 18px;
        margin-left: -10px;
        margin-bottom: 3px;
    }
    .DocTV1 {
        color: #321e1e;
        font-size: 18px;
        font-weight: 700;
        margin-left: -30px;
    }
    .DocCol hr {
        max-width: 100%;
        position: flex;
        margin-left: -1.35rem;
        margin-bottom: 1rem;
    }
    .DHR {
        max-width: 100%;
        position: flex;
        margin-left: -.7rem !important;
        margin-bottom: 1rem;
    }
    .RequisiteRow {
        display: flex;
        flex-direction: column;
        gap: 0px;
        position: relative;
        text-align: center;
        /* text-align: center; */
        /* align-items: center; */
        /* max-width: 400px; */
        /* left: -1.5rem;   */
    }
    .ReqTP {
        font-family: "Overpass", sans-serif;
        font-size: medium;
        font-weight: 700;
        min-width: 200px;
        margin-bottom: 0;
    }
    .ReqTV {
        font-family: "Overpass", sans-serif;
        font-size: medium;
        font-weight: 700;
        margin-bottom: 24px;
        min-width: 300px;
    }
    .showReqsRow {
        position: relative;
        display: flex;
        flex-direction: row;
        max-width: 100%;
        gap: 1px;
        justify-content: space-between;
        color: #321e1e;
        font-size: small;
        margin-top: 0.3rem;
    }
    .showReqsRow span {
        font-weight: 700;
        margin-top: 1rem;
    }
    .showReqsP {
        font-weight: 400 !important;
        text-align: end;
        max-width: 230px !important;
    }
    .PayTP {
        margin-bottom: 16px;
        padding-top: 2rem;
        align-self: center;
        font-family:'Overpass', sans-serif;
        font-weight: 800;
        font-size: 16pt;
        line-height: 1;
    }
    .reqWrapper {
        position: relative;
        align-items: center;
        margin-block: -0.25rem;
        margin-left: 1rem !important;
        user-select: initial;
    }
    .RequisiteWrapper {
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap:16px;
        margin-bottom: 16px;
    }
    .OutstandingBalance{
        top:0 !important;
        left: 0 !important;
        margin-top: 200px;
    }
    .AlignContainers {
        height: auto !important;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding-bottom:8rem;
        padding-top:50px;
    }
    .ChartContainer {
        height:475px !important;
        margin-left: -1.7rem !important;
        margin-top: 4rem;
        margin-bottom: 20rem;
        width: 450px !important;
        /* overflow-x: scroll !important; */
    }
    #interactiveC {
        width: 475px !important;
        height: 490px !important;
        overflow-x: scroll !important;
        overflow-y: hidden !important;
        /* margin-left: 2.5rem; */
    }
    #FinteractiveC {
        min-width: 800px !important;
        height: 475px !important;
        margin-left: 2.5rem !important;
        /* margin-bottom: 1rem; */
    }
    .SPAN {
        font-size: large;
        font-family: 'IBM Plex Sans', sans-serif;
        position: relative;
        display: flex;
        /*max-width: 280px;*/
        /*margin-top: 0rem;*/
        padding-bottom: 3rem !important;
        margin-left: 5.25rem !important;
        font-weight: 700;
        color: hsl(36, 57%, 65%);
    }
    .ErrorSPAN {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-left: 5.2rem !important;
        font-size: medium;
        color: hsl(4 90% 58% / 0.7);
        font-weight:400;
        top: 0.2rem;
        max-width: 300px !important;
        margin-bottom: 1rem;
        user-select: none;
    }
    .ChartContainer p {
        margin-left: 5.25rem;
        font-family: "IBM Plex Sans",sans-serif;
        font-size: Large;
        font-weight: 600;
        color: #321e1e;
        margin-bottom: 0;
       
    }
    .InfoRow {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        /* margin-bottom: 0.5rem !important; */
        font-size: medium !important;
        /* max-width: 98% !important;
        min-width: 320px !important; */
        /* gap: 4rem; */
        
        padding-bottom: 0.5rem;
        /* margin-left: 0.1rem; */
    }
    .InfoP1 {
        font-weight: 600;
        
    }
    .InfoV1 {
        font-weight: 400;
    }
    #InfoColumn {
        width: 94%;
        height: 101% !important;
        position: relative;
    }
    #doctype-in {
        width: 100%;
    }
    #question {
        width: 296px;
    }
    #docsSubmit {
        position: relative;
        /* display: block; */
        margin-top: 2rem;
        margin-bottom: 0;
        /* margin-bottom: 6rem !important; */
        /* padding-bottom: 2rem; */
        width: 200px;
        height: 50px;
        align-self: center;
        margin-left: 0rem;
        user-select: none;
    }
    #UploadColumn {
        width: 100%;
        margin-bottom: 2rem !important;
    }
    #UploadT {
        margin-bottom: -0.5rem;
        display: flex;
        flex-direction: row;
        z-index: 990;
        align-items: center;
    }
    .QuestionIcon {
        width: 24px;
        height: 24px;
        margin-left: 5px;
        padding-top: 0;
        color: hsla(153, 36%, 36%, 0.82);
        z-index: 1050;
    }
    .UploadFragment {
        display: flex;
        flex-direction: column;
    }
    .UploadFragment span {
        margin-top: 12px;
        position: relative;
    }
    .Spinner {
        display: flex;
        position: absolute;
        /* width: 100% !important; */
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;
    }
}

@media (max-width: 375px) {
    #ContractsC{
        max-height: 400px;
    }
    #PayC {
        max-height: 400px;
    }
    #InfoC {
        max-height: 400px;
    }
    #InfoN {
        max-height: 400px;
    }
    #UploadC {
        max-height: 400px;
    }
    .ContractContainer {
        display: flex;
        position:relative;
        margin: auto;
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 60px 100px rgba(0, 0, 0, 0.12)
        ;
        max-width: 98% !important;
        
        left: 0rem !important;
        justify-content: center;
        border-radius: 1.3em;
        border-top: 3px solid hsla(36, 57%, 65%, 0.45);
        background-color: hsl(51 48% 92% / 1);
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 20 !important;
        margin-top: 15.5rem;
        margin-bottom: 8rem;
        /* scrollbar-base-color: #321e1d !important; */
    }
    .ContractContainer::-webkit-scrollbar {
        width: 7px;
        overflow: hidden;
    }
    .ContractContainer::-webkit-scrollbar-track {
        box-shadow: inset 1px 2px 4px hsla(0, 0%, 0%, 0.15);
        /* box-shadow: inset 0 -1px 6px hsl(3 27% 15% / 0.23); */
        margin-block: 15px;
        border-radius: 25px;
    }
    .ContractContainer::-webkit-scrollbar-thumb {
        background-color: hsla(153, 36%, 36%, 0.9);
        border: 1px solid hsl(0 0% 100% / 0.75);
        border-radius: 25px;
        max-height: 120px !important;
    }
    .ContractContainer button {
        max-width: 157px;
        height: 40px;
        background: hsl(36, 57%, 65%);
        color: white;
        border: 1px solid white;
        box-shadow: 0 0 0 1px transparent;
        font-size: small;
        letter-spacing: 0.32pt;
        margin-bottom: 0.5rem;
        user-select: none;
        /* margin-left: -1.5rem; */
        
    }
    .ContractContainer button:hover {
        background: linear-gradient(30deg, hsl(36, 57%, 65%), hsl(31, 100%, 65%));
        box-shadow: 0 0 0 1px white;
        border: 2px solid white;
        /* transition: 100ms cubic-bezier(0, 0.33, 0.33, 0); */
    }
    .ButtonRow {
        display: flex;
        flex-direction: column;
        gap: 0px;
        /* justify-content: space-evenly; */
        z-index: 20 !important;
        /* width: 95%; */
    }
    .DocCol {
        display:flex;
        flex-direction: column;
        position: relative;
        max-width: 90%;
        /* left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto; */
        /* jus-content: center; */
        align-content: flex-start;
        margin-left: -0.2rem;
        user-select: none;
        /* margin-top: -2rem; */
    }
    
    .DocCol img {
        align-self: center;
        padding-top: 32px;
    }
    .DocRow {
        height: 50px;
        margin-bottom: 16px;   
    }
    .DocP {
        color: #321e1e;
        font-weight: 800;
        font-size: 17px;
        margin-left: -25px;
        margin-bottom: 4px;
    }
    .DocV {
        color: #321e1e;
        font-family: "Overpass",sans-serif;
        /* margin-left: -20px; */
    }
    .DocRow1 {
        height: 50px;
        margin-bottom: 32px;
        
    }
    .DocP1 {
        min-width: 80% !important;
        color: #321e1e;
        font-weight: 800;
        font-size: 17px;
        margin-bottom: 4px;
        margin-left: -10px;
    }
    .DocV1 {
        color: #321e1e;
        font-family: "Overpass",sans-serif;
        margin-left: -10px;
        padding-bottom: 2rem !important;
        font-size: 16px !important;
    }
    .TitleRow {
        display: flex;
        flex-direction: row;
        gap: 35px;
        margin-bottom: -25px;
        padding-top: 2rem;
    }
    .DocTP {
        color: #321e1e;
        font-weight: 700;
        font-size: 18px;
        margin-left: -30px;
        margin-bottom: 3px;
    }
    .DocTV {
        color: #321e1e;
        font-size: 18px;
        font-weight: 700;
        margin-left: -30px;
    }
    .TitleRow1 {
        display: flex;
        flex-direction: row;
        gap: 35px;
        margin-bottom: -25px;
        padding-top: 2rem;
    }
    .DocTP1 {
        color: #321e1e;
        font-weight: 700;
        font-size: 17px;
        margin-left: -10px;
        margin-bottom: 3px;
    }
    .DocTV1 {
        color: #321e1e;
        font-size: 17px;
        font-weight: 700;
        /* margin-left: -30px; */
    }
    .DocCol hr {
        max-width: 100%;
        position: flex;
        margin-left: -1.35rem;
        margin-bottom: 1rem;
    }
    .DHR {
        max-width: 100%;
        position: flex;
        margin-left: -.7rem !important;
        margin-bottom: 1rem;
    }
    .RequisiteRow {
        display: flex;
        flex-direction: column;
        gap: 5px;
        position: relative;
        text-align: center;
        /* text-align: center; */
        /* align-items: center; */
        /* max-width: 400px; */
    }
    .ReqTP {
        font-family: "Overpass", sans-serif;
        font-size: medium;
        font-weight: 700;
        min-width: 200px;
        
    }
    .ReqTV {
        font-family: "Overpass", sans-serif;
        font-size: medium;
        font-weight: 700;
        margin-bottom: 48px;
        min-width: 300px;
    }
    .showReqsRow {
        position: relative;
        display: flex;
        flex-direction: row;
        max-width: 100%;
        /* gap: 1px; */
        justify-content: space-between;
        color: #321e1e;
        font-size: small;
        margin-top: 0.7rem;
        /* justify-content: top !important; */
        /* padding-right: 1rem; */
        /* margin-left: -0.2rem; */
    }
    .showReqsRow span {
        font-weight: 700;
        margin-right: -2rem;
        margin-top: 0rem !important;
        margin-bottom: .5rem !important;
        /* max-width: max-content; */
    }
    
    .showReqsP {
        font-weight: 400 !important;
        text-align: end;
        max-width: 200px !important;
        margin-right:0rem !important;
    }
    .PayTP {
        margin-bottom: 16px;
        padding-top: 2rem;
        align-self: center;
        font-family:'Overpass', sans-serif;
        font-weight: 800;
        font-size: x-large;
    }
    .reqWrapper {
        position: relative;
        align-items: center;
        user-select:initial;
    }
    .RequisiteWrapper {
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap:32px;
        margin-bottom: 32px;
    }
    .AlignContainers {
        height: auto !important;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        padding-bottom: 8rem;
    }
    .OutstandingBalance{
        margin-top: 200px;
        left:0 !important;
        top:0 !important;
    }
    .SidebarItems{
        gap: 1rem;
    }
    .SidebarSection{
        height: 200px;
    }
    .ChartContainer {
        height:400px;
        margin-left: -1.1rem;
        margin-top: -2rem;
        margin-bottom: 18rem;
        width: 430px !important;
        font-size: 8pt !important;
    }
    
    .SPAN {
        font-size: medium;
        font-family: 'IBM Plex Sans', sans-serif;
        position: relative;
        display: flex;
        /*max-width: 250px;*/
        /*margin-top: -2rem;*/
        padding-bottom: 2.5rem !important;
        margin-left: 5.25rem !important;
        font-weight: 700;
        color: hsl(36, 57%, 65%);
    }
    .ChartContainer p {
        margin-left: 5.25rem;
        font-family: "IBM Plex Sans",sans-serif;
        font-size: medium;
        font-weight: 600;
        color: #321e1e;
    }
    .InfoRow {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        margin-bottom: 0.5rem !important;
        font-size: large;
        max-width: 99%;
    }
    .InfoP1 {
        font-weight: 600;
        
    }
    .InfoV1 {
        font-weight: 400;
    }
    #docsSubmit {
        margin-top: 2rem;
        width: 200px;
        height: 40px;
        align-self: center;
        user-select: none;
    }
}

@media (min-width: 960px) and (max-width:1199px) {
    #UploadC {
        position:relative;
        top: 5rem;
        left: -4rem;
        width: 750px;
        z-index: 1000;
        max-height: 500px;
    }
}

@media (min-width: 1200px) {
    
    #ContractsC{
        position:relative;
        margin-top: 4.25rem;
        /* left: 20rem; */
        width: 640px;
        margin-bottom: 5rem !important;
        padding-bottom: 5rem !important;
    }
    #PayC {
        position:relative;
        top: 5rem;
        left: -4rem;
        width: 750px;
        z-index: 1000;
        margin: auto;
        margin-top: 0;
    }
    #InfoC {
        position:relative;
        top: 5rem;
        left: -4rem;
        width: 750px;
        z-index: 1000;
        margin: auto;
        margin-top: 0;
    }
    #InfoN {
        position:relative;
        top: 5rem;
        left: -4rem;
        width: 750px;
        z-index: 1000;
        margin: auto;
        margin-top: 0;
    }
    #UploadC {
        position:relative;
        top: 5rem;
        left: -4rem;
        width: 750px;
        z-index: 1000;
    }
    .Other{
        margin-left: auto !important;
    }
    .ContractContainer {
        padding: 3rem;
        /* box-shadow: 0 12px 27px hsl(3 27% 15% / 0.37); */
        /* filter: drop-shadow(
            0 4px 6px hsl(3 27% 15% / 0.23)
            0 15px 30px hsl(3 27% 15% / 0.13)
            0 12px 23px hsl(0 0% 0% / 0.25)
        ); */
        box-shadow: 
        0 2.8px 2.2px rgba(0, 0, 0, 0.034),
        0 6.7px 5.3px rgba(0, 0, 0, 0.048),
        0 12.5px 10px rgba(0, 0, 0, 0.06),
        0 22.3px 17.9px rgba(0, 0, 0, 0.072),
        0 41.8px 33.4px rgba(0, 0, 0, 0.086),
        0 60px 100px rgba(0, 0, 0, 0.12)
        ;
        margin-left:30px !important;
        margin-top:100px;
        max-width: 45% !important;
        min-height: 400px;
        height: 100%;
        max-height: 750px;
        justify-content: center;
        align-content: center;
        border-radius: 1.3em;
        border-top: 3px solid hsla(36, 57%, 65%, 0.45);
        /* border-top: 3px solid hsla(122, 39%, 49%, 0.75); */
        /* border-top: 3px solid hsla(27, 100%, 47%, 0.12); */
        /* border-top: 3px solid hsl(38 100% 75% / 0.75); */
        background-color: hsl(51 48% 92% / 1);
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
        z-index: 20 !important;
        padding-bottom: 1.25rem;
        margin-bottom: 2rem;
        /* scrollbar-base-color: #321e1d !important; */
    }
    .ContractContainer::-webkit-scrollbar {
        width: 7px;
        overflow: hidden;
    }
    .ContractContainer::-webkit-scrollbar-track {
        box-shadow: inset 1px 2px 4px hsla(0, 0%, 0%, 0.15);
        /* box-shadow: inset 0 -1px 6px hsl(3 27% 15% / 0.23); */
        margin-block: 15px;
        border-radius: 25px;
    }
    .ContractContainer::-webkit-scrollbar-thumb {
        /* background-color: hsla(122, 39%, 49%, 0.902); */
        background-color: hsla(153, 36%, 36%, 0.9);
        /* background-color: hsl(122 39% 49% / 0.9); */
        /* box-shadow: 0 1px 10px hsl(3 27% 15% / 0.66); */
        border: 1px solid hsl(0 0% 100% / 0.75);
        /* outline: 1px solid #4caf50; */
        border-radius: 25px;
        min-height: 400px;
    }
    .ContractContainer button {
        font-family: 'Overpass', sans-serif;
        padding-top: 4px;
        min-width: 157px;
        height: 40px;
        background: hsl(36, 57%, 65%);
        color: white;
        border: 1px solid white;
        box-shadow: 0 0 0 1px transparent;
        font-size: 10.5pt;
        letter-spacing: 0.5px;
        border-radius: 5px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
        user-select: none;
    }
    .ContractContainer button:hover {
        background: linear-gradient(30deg, hsl(36, 57%, 65%), hsl(31, 100%, 65%));
        box-shadow: 0 0 0 1px white;
        border: 2px solid white;
        /* transition: 100ms cubic-bezier(0, 0.33, 0.33, 0); */
    }
    .ContractContainerOther{
        padding: 3rem;
        /* box-shadow: 0 12px 27px hsl(3 27% 15% / 0.37); */
        /* filter: drop-shadow(
            0 4px 6px hsl(3 27% 15% / 0.23)
            0 15px 30px hsl(3 27% 15% / 0.13)
            0 12px 23px hsl(0 0% 0% / 0.25)
        ); */
        box-shadow:
                0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                0 12.5px 10px rgba(0, 0, 0, 0.06),
                0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                0 60px 100px rgba(0, 0, 0, 0.12)
    ;
        margin-top:100px !important;
        max-width: 45% !important;
        min-height: 450px;
        height: 100%;
        max-height: 650px;
        justify-content: center;
        align-content: center;
        border-radius: 1.3em;
        border-top: 3px solid hsla(36, 57%, 65%, 0.45);
        /* border-top: 3px solid hsla(122, 39%, 49%, 0.75); */
        /* border-top: 3px solid hsla(27, 100%, 47%, 0.12); */
        /* border-top: 3px solid hsl(38 100% 75% / 0.75); */
        background-color: hsl(51 48% 92% / 1);
        overflow-y: auto;
        overflow-x: hidden;
        scroll-behavior: smooth;
        z-index: 20 !important;
        padding-bottom: 1.25rem;
        margin-bottom: 2rem;
    }
    .ContractContainerOther::-webkit-scrollbar {
        width: 7px;
        overflow: hidden;
    }
    .ContractContainerOther::-webkit-scrollbar-track {
        box-shadow: inset 1px 2px 4px hsla(0, 0%, 0%, 0.15);
        /* box-shadow: inset 0 -1px 6px hsl(3 27% 15% / 0.23); */
        margin-block: 15px;
        border-radius: 25px;
    }
    .ContractContainerOther::-webkit-scrollbar-thumb {
        /* background-color: hsla(122, 39%, 49%, 0.902); */
        background-color: hsla(153, 36%, 36%, 0.9);
        /* background-color: hsl(122 39% 49% / 0.9); */
        /* box-shadow: 0 1px 10px hsl(3 27% 15% / 0.66); */
        border: 1px solid hsl(0 0% 100% / 0.75);
        /* outline: 1px solid #4caf50; */
        border-radius: 25px;
        min-height: 400px;
    }
    .ContractContainerOther button {
        font-family: 'Overpass', sans-serif;
        padding-top: 4px;
        min-width: 157px;
        height: 40px;
        background: hsl(36, 57%, 65%);
        color: white;
        border: 1px solid white;
        box-shadow: 0 0 0 1px transparent;
        font-size: 10.5pt;
        letter-spacing: 0.5px;
        border-radius: 5px;
        font-weight: 400;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
        user-select: none;
    }
    .ContractContainerOther button:hover {
        background: linear-gradient(30deg, hsl(36, 57%, 65%), hsl(31, 100%, 65%));
        box-shadow: 0 0 0 1px white;
        border: 2px solid white;
        /* transition: 100ms cubic-bezier(0, 0.33, 0.33, 0); */
    }
    #PaymentTable {
        position: relative !important;
        width: 270px;
        height: 42px;
        background: hsl(36, 57%, 65%);
        border: 1px solid white;
        /* padding-top: 5px; */
        margin-bottom: 2.5rem;
        margin-left: 2.5rem;
        text-decoration: none;
        border-radius: 5px;
        color: white;
        box-shadow: 0 0 0 1px transparent;
        letter-spacing: 0.35pt !important;
        font-size: 10.5pt;
        letter-spacing: 0.5px;
        font-weight: 400;
        font-family: "Overpass", sans-serif;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    #PaymentTable:hover {
        background: linear-gradient(30deg, hsl(36, 57%, 65%), hsl(31, 100%, 65%));
        box-shadow: 0 0 0 1px white;
        border: 2px solid white;
        font-weight: 400;
        letter-spacing: 0.5px;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    #docsSubmit {
        margin-top: 2rem;
        width: 200px;
        align-self: center;
        user-select: none;
    }
    .ButtonRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        z-index: 20 !important;
    }
    .DocCol {
        display:flex;
        flex-direction: column;
        position: relative;
        width: 85%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        user-select: none;
    }
    .Spinner {
        display: flex;
        position: absolute;
        /* width: 100% !important; */
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;
    }
    #DocColContracts {
        display:flex;
        flex-direction: column;
        position: relative;
        width: 85%;
        left: 1rem !important;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: -3.5rem;
        margin-bottom: -2rem !important;
    }
    .DocCol img {
        align-self: center;
        padding-top: 32px;
    }
    .DocRow {
        height: 50px;
        margin-bottom: 16px;
    }
    .DocP {
        color: #321e1e;
        font-weight: 800;
        font-size: 17px;
        margin-left: -20px;
        margin-bottom: 4px;
        letter-spacing: 0.25px;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .DocV {
        color: #321e1e;
        font-family: "Overpass",sans-serif;
        margin-left: -20px;
    }
    .DocRow1 {
        height: 50px;
        margin-bottom: 16px;
    }
    .DocP1 {
        color: #321e1e;
        font-weight: 800;
        font-size: large;
        margin-left: -30px;
        margin-bottom: 1pt;
        letter-spacing: 0.25pt;
        padding-top: 2px;
    }
    .DocV1 {
        color: #321e1e;
        font-family: "Overpass",sans-serif;
        margin-left: -30px;
        letter-spacing: 0.15px;
    }
    .TitleRow {
        display: flex;
        flex-direction: row;
        gap: 35px;
        margin-bottom: -25px;
        padding-top: 2rem;
    }
    .DocTP {
        color: #321e1e;
        font-weight: 700;
        font-size: 23px;
        margin-left: -30px;
        margin-bottom: 3px;
    }
    .DocTV {
        color: #321e1e;
        font-size: 23px;
        font-weight: 700;
        margin-left: -30px;
    }
    .TitleRow1 {
        display: flex;
        flex-direction: row;
        gap: 35px;
        margin-bottom: -25px;
        padding-top: 2rem;
        letter-spacing: 0.1pt;
    }
    .DocTP1 {
        color: #321e1e;
        font-weight: 700;
        font-size: 23px;
        margin-left: -45px;
        margin-bottom: 3px;
    }
    .DocTV1 {
        color: #321e1e;
        font-size: 23px;
        font-weight: 700;
        margin-left: -30px;
    }
    .DocCol hr {
        width: 500px;
        position: flex;
        margin-left: -1.5rem;
        margin-bottom: 1rem;
    }
    .DHR {
        width: 500px;
        position: flex;
        margin-left: -2.8rem !important;
        margin-bottom: 1rem;
    }
    .RequisiteRow {
        display: flex;
        flex-direction: row;
        gap: 5px;
        position: relative;
        justify-content: center;
        width: 600px;
        left: -1.5rem; 
    }
    .ReqTP {
        font-family: "Overpass", sans-serif;
        font-size: medium;
        font-weight: 700;
        letter-spacing: 0.25px;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .ReqTV {
        font-family: "Overpass", sans-serif;
        font-size: medium;
        font-weight: 700;
        margin-bottom: 48px;
    }
    .showReqsRow {
        position: relative;
        display: flex;
        flex-direction: row;
        width: 375px;
        gap: 1px;
        justify-content: space-between;
        color: #321e1e;
        letter-spacing: 0.25pt;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .showReqsRow span {
        font-weight: 700;
        margin-bottom: 1rem;
        letter-spacing: 0.25px;
    }
    .showReqsP {
        font-weight: 400 !important;
        text-align: end;
        max-width: 400px;
    }
    .PayTP {
        position: static;
        margin-bottom: 16px;
        padding-top: 2rem;
        padding-bottom: 1rem;
        align-self: center;
        font-family:'Overpass', sans-serif;
        font-weight: 800;
        font-size: x-large;
        letter-spacing: 0.25px !important;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .reqWrapper {
        position: relative;
        align-items: center;
        user-select:initial;
    }
    .RequisiteWrapper {
        justify-content: center;
        display: flex;
        flex-direction: column;
        gap:32px;
        margin-bottom: 32px;
    }
    .AlignContainers {
        width: 80%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around !important;
        /* gap: 10rem !important; */
        margin: auto;
        margin-top: 0;
        height: 100%;
        max-height: 200vh;
    }
    .ChartContainer {
        position: relative;
        height:100%;
        margin-top: 12rem;
        min-width: 340px;
        max-width: 820px;
        margin-right: 1rem;
        padding-right: 2rem !important;
        padding-left:0;
    }
    #interactiveC {
        width: 800px;
        height: 450px ;
        /* overflow-x: scroll !important; */
    }
    #FinteractiveC {
        width: 820px;
        height: 450px;
    }
    .SPAN {
        font-size: large;
        font-family: 'IBM Plex Sans', sans-serif;
        position: relative;
        display: flex;
       /* max-width: 300px !important;*/
        /*margin-top: -4.9rem;*/
        padding-bottom: 2.5rem !important;
        margin-left: 2.5rem !important;
        font-weight: 800;
        /* color: hsl(36 57% 65%); */
        color: hsla(153, 36%, 36%, 0.82);
        letter-spacing: 0.25pt;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .ErrorSPAN {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-left: 2.65rem !important;
        font-size: medium;
        color: hsl(4 90% 58% / 0.7);
        font-weight:400;
        top: 0.2rem;
        width: 100% !important;
        margin-bottom: 1rem;
        user-select: none;
        letter-spacing: 0.15pt;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .ChartContainer p {
        margin-left: 2.65rem;
        font-family: "IBM Plex Sans",sans-serif;
        font-size: large;
        font-weight: 800;
        color: hsla(153, 36%, 36%, 0.82);
        letter-spacing: 0.25pt;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .InfoRow {
        display: flex;
        flex-direction: row !important;
        justify-content: space-between;
        margin-bottom: 0.5rem !important;
        font-size: large;
        max-width: 99%;
        margin-left: 0.15rem;
    }
    .InfoP1 {
        font-weight: 600;
        letter-spacing: 0.35px;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    .InfoV1 {
        font-weight: 400;
        letter-spacing: 0.25pt;
        -webkit-font-smoothing: antialiased !important;
        -moz-osx-font-smoothing: grayscale !important;
    }
    #InfoColumn {
        /* width: 90% !important; */
        min-height: 110% !important;
        position: relative;
    }
    #doctype-in {
        width: 100%;
    }
    #question {
        width: 376px;
    }
    #UploadT {
        margin-top: -1rem;
        margin-bottom: -0.5rem;
        margin-left: 0.1rem;
        z-index: 999;
        display: flex;
        flex-direction: row;
        letter-spacing: 0.15pt;
    }
    .QuestionIcon {
        width: 18px;
        margin-left: 2px;
        color: hsla(153, 36%, 36%, 0.82);
        z-index: 1001;
    }
    #contractTooltip {
        margin-top: 32px; 
    }
    .UploadFragment {
        display: flex;
        flex-direction: column;
    }
    .UploadFragment span {
        margin-top: 12px;
        position: relative;
    }
}
