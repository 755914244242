@media (min-width: 1200px) {
    .bodySide {
        max-height:100vh;
        max-width: 100%;
        overflow-x: hidden !important;
       /* overflow-y: hidden !important;*/
        display: flex;
        flex-direction: row;
        background-color: rgb(244, 241, 225);
        position: relative;
    }
    .SidebarSection {
        box-sizing:border-box;
        background-color:#0b271e;
        max-width:250px;
        min-height:100vh;
        display:flex;
        flex-direction:column;
        flex:2;
        overflow:hidden;
        position:sticky;
        top:0;
    }
    .SidebarItems {
        display: flex;
        flex-direction:column;
        position:relative;
        align-items:center;
        margin-right:0.3rem;
        gap:1.8rem;
    }
    .sbLink {
        color: #fcfcfb;
        font-family: "Overpass", sans-serif;
        font-size: 13pt;
        position: relative;
        display: inline-block; 
        letter-spacing: 0.3pt;
    } 
    .sbLink:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        background-color: rgba(0, 180, 0, 0.75);
        bottom: 0;
        left: 0;
        transform-origin: bottom right;
        transition: transform 0.3s ease-in-out;
        -webkit-transition: transform 0.3s ease-in-out;
        -moz-transition: transform 0.3s ease-in-out;
        -ms-transition: transform 0.3s ease-in-out;
        -o-transition: transform 0.3s ease-in-out;
        
    }
    .sbLink:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
    
    .SidebarLogo {
        padding:1.5rem;
    }
    .SidebarLogo img {
        width:200px;
    } 
}

@media (max-width: 480px) {
    .bodySide {
        min-height:114vh !important;
        display: flex;
        flex-direction: row;
        background-color: rgb(244, 241, 225);
        position: relative;
    }
    .SidebarSection {
        box-sizing:border-box;
        background-color:#0b271e;
        min-width:100%;
        min-height:30px;
        display:flex;
        flex-direction:row;
        flex:2;
        overflow:hidden;
        position: absolute;
    }
    .SidebarItems {
        display: flex;
        flex-direction:row;
        flex-wrap: wrap;
        flex: 2;
        position:relative;
        align-items: center;
        /* margin: 5px; */
        gap: 1rem;
        row-gap: 8px !important;
        margin-inline: 2.5rem;
        height: 30px !important;
        max-width: 200px;
        padding-top: 5.15rem;
        margin-bottom: 0;
        margin-top: 0;
        /* align-items: center; */
        /* gap:1.8rem; */
    }
    .SidebarLogo {
        margin-top: 4rem;
        padding: 0rem;
    }
    .SidebarLogo img {
        width:120px;
    }
    .sbLink {
        color: #fcfcfb;
        font-family: "Overpass", sans-serif;
        margin-top: 0.25rem;
        font-size: 10pt;
        position: relative;
        display: inline-block;
        letter-spacing: 0.3pt;
    }
    .sbLink:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        background-color: rgba(0, 180, 0, 0.75);
        bottom: 0;
        left: 0;
        transform-origin: bottom right;
        transition: transform 0.3s ease-in-out;
        -webkit-transition: transform 0.3s ease-in-out;
        -moz-transition: transform 0.3s ease-in-out;
        -ms-transition: transform 0.3s ease-in-out;
        -o-transition: transform 0.3s ease-in-out;
    }
    .sbLink:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}

@media (max-width: 375px) {
    .SidebarSection {
        box-sizing:border-box;
        background-color:#0b271e;
        min-width:100%;
        min-height:30px;
        display:flex;
        flex-direction:row;
        flex:2;
        overflow:hidden;
        position: absolute;
    }
    .SidebarItems {
        display: flex;
        flex-direction:row;
        position:relative;
        align-items:center;
        margin-right:0.3rem;
        gap:1.8rem;
    }
    .SidebarLogo {
        margin-top: 4rem;
        padding: 0rem;
    }
    .SidebarLogo img {
        width:120px;
    }
    .sbLink {
        color: white;
        font-family: "Overpass", sans-serif;
        margin-top: 2.25rem;
        font-size: 8.5pt;
        position: relative;
        display: inline-block;
    }
    .sbLink:after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 1px;
        background-color: rgba(0, 180, 0, 0.75);
        bottom: 0;
        left: 0;
        transform-origin: bottom right;
        transition: transform 0.3s ease-in-out;
        -webkit-transition: transform 0.3s ease-in-out;
        -moz-transition: transform 0.3s ease-in-out;
        -ms-transition: transform 0.3s ease-in-out;
        -o-transition: transform 0.3s ease-in-out;
    }
    .sbLink:hover:after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}