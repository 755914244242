@media (min-width:1200px) {
    .partnersMarquee {
        margin: 2rem !important;
        padding-bottom: 1rem;
    }
    .partnersMarquee img {
        width: 196px;
        color: rgb(217, 177, 115);
    }
}
@media (max-width:480px) {
    .partnersMarquee {
        position: relative;
        margin: 1.5rem !important;
        /* padding-bottom: 2rem; */
        top: -1.5rem;
        margin-bottom: 4.75rem !important;
    }
    .partnersMarquee img {
        width: 128px;
        color: rgb(217, 177, 115);
    }
}