@media (min-width: 1200px) {
    .ECformD {
        color: rgb(244, 241, 224);
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 0;
        width: 100%;
        gap: 12px;
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transition: all 0.3s cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all .3s cubic-bezier(.165, .84, .44, 1);
        -o-transition: all .3s cubic-bezier(.165, .84, .44, 1);
    }
    #ButnotP2 {
        top: -0.65rem;
    }
    #formatted-text-mask-input {
        max-width: 200px !important;
    }
    .DebtFormContainer {
        display: flex;
        flex-direction: column;
        gap: 6px;
        align-items: center;
    }
    .formGroupD {
        z-index: 1001;
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 2;
        width: fit-content;
        max-width: 1600px;
        padding-left: 50px;
        padding-right: 50px;
        /* max-width: 60%; */
        height: 205px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        /* margin-bottom: 7rem !important; */
        /* margin: auto; */
        margin-top: -1.3rem;
        align-items: center;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        /* box-shadow: 0 6px 18px hsl(0deg 0% 15.7% / 0.37); */
        box-shadow: 
            0 2.8px 2.2px rgba(0, 0, 0, 0.034),
            0 6.7px 5.3px rgba(0, 0, 0, 0.048),
            0 12.5px 10px rgba(0, 0, 0, 0.06),
            0 22.3px 17.9px rgba(0, 0, 0, 0.072),
            0 41.8px 33.4px rgba(0, 0, 0, 0.086),
            0 60px 100px rgba(0, 0, 0, 0.12)
        ;
        background: rgba(199, 199, 199, 0.2);
        border-top: 2px solid rgba(75, 75, 75, 0.2);
        border-bottom: 2px solid rgba(236, 236, 236, 0.2);
        margin-bottom: 0;
    }

    .FormLabel {
        user-select: none;
        color: rgb(244, 241, 224);
        filter: drop-shadow(
            0 1px 12px hsl(0 0% 0% / 1)
        );
        font-size: medium;
        font-family: 'Overpass', sans-serif;
        font-weight: 300;
        letter-spacing: 0.15pt;
        align-self: flex-start !important;
        padding-left: 2px;
        padding-top: 1.5rem;
        margin-bottom: -0.25rem;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #debtpage {
        width: fit-content !important;
        max-width: 1600px !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
    #debtpage #name {
        width: 240px !important;
    }
    #debtpage #date {
        min-width: 200px !important;
        max-width: fit-content;
    }
    #debtpage #formatted-text-mask-input {
        width: 200px !important;
    }
    #debtLabel {
        font-size: medium;
        letter-spacing: 0.15pt;
    }
    #paypage {
        background: none;
        border: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        box-shadow: none;
        max-width: 100%;
        margin-top: 3rem;
        margin-left: auto !important;
        margin-right: auto !important;
        left: 0 !important;
        right: 0 !important;
        justify-self: center;
    }
    #paypage #name {
        width: 240px;
    }
    #paypage #date {
        width: 180px;
    }
    #FormLabelP {
        font-size: xx-large;
        width: 600px;
        font-weight: 700;
        filter: drop-shadow(
            0 1px 30px hsl(0 0% 0% / 1)
        );
        /* margin-left: 16.5rem; */
        margin-bottom: 1rem;
        letter-spacing: 0.15pt !important;
        align-self: flex-start !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .debtMessage {
        position: relative;
        margin: auto;
        color: #d9b173;
        font-weight: 400;
        font-size: large;
        display: flex;
        gap: 10px;
    }
    .debtIcon {
        margin-top: 5px;
    }
    #debtButtonBack {
        border-radius: 5px;
        font-size: small;
        padding: 6px;
        padding-inline: 12px;
        margin-left: 22px;
        font-family: 'Overpass', sans-serif;
        border: 1px solid #0f281e;
        color: #0f281e;
        padding-bottom: 2px;
        letter-spacing: 0.3px;
        transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: rgb(244, 241, 224);
    }
    #debtButtonBack:hover {
        background: rgba(217, 176, 115, 0.9); 
        box-shadow: 0 0px 0px 3px hsl(123, 43%, 39%);
        font-weight: 400;
        letter-spacing: 0.3px;
        border: 1px solid rgb(244, 241, 224);
    }
    .PrivacyRow {
        display:flex;
        flex-direction:row;
        /* gap: 3rem; */
        max-width: 100% !important;
        align-items: top;
        justify-content: space-between;
        margin-top: -4px;
    }
    .PrivacyRow1 {
        display:flex;
        flex-direction:column;
        gap: 0px;
        max-width: 90% !important;
        align-self: flex-start;
        margin-top: -7px !important;
        margin-left: -1px;
    }
    .PrivacyRow span {
        position: relative;
        display: flex;
        color: hsl(4 90% 58% / 0.7) !important;
        font-size: medium;
        font-weight: 500;
    }
    .PrivacyRow1 span {
        position: relative;
        display: flex;
        color: hsl(4 90% 58% / 0.7) !important;
        font-size: medium;
        font-weight: 500;
        margin-top: -1rem;
        margin-bottom: 1rem;
    }
    #DebtCheck #check-api-p {
        margin-top: 4px !important;
    }

}

@media (max-width: 480px) {
    .ECformD {
        color: rgb(244, 241, 224);
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center !important;
        padding: 0.5rem 0;
        width: 100%;
        gap: 15px;
    }
    #ButnotP2 {
        top: -0.65rem;
        z-index: 1010;
    }
    #username {
        width: 280px !important;
    }
    
    #date {
        min-width: 280px !important;
    }
    #formatted-text-mask-input {
        min-width: 280px !important;
    }
    .DebtFormContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;
        align-items: center;
    }
    .formGroupD {
        z-index: 1001;
        position: absolute;
        display: flex;
        flex-direction: column;
        flex: 2;
        max-width: 88%;
        height: 480px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        /* margin: auto; */
        margin-top: -21rem;
        align-items: center;
        background: linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
        backdrop-filter: blur(15px);
        -webkit-backdrop-filter: blur(15px);
        border-radius: 15px;
        border:1px solid rgba(255, 255, 255, 0.18);
        box-shadow: 0 6px 18px hsl(0deg 0% 15.7% / 0.37);
        background: rgba(199, 199, 199, 0.2);
        border-top: 1px solid rgba(75, 75, 75, 0.2);
        border-bottom: 1px solid rgba(236, 236, 236, 0.2);
    }
    .FormLabel {
        user-select: none;
        color: rgb(244, 241, 224);
        filter: drop-shadow(
            0 1px 12px hsl(0 0% 0% / 1)
        );
        font-size: medium;
        font-family: 'Overpass', sans-serif;
        font-weight: 300;
        letter-spacing: 0.3px;
        align-self: center !important;
        padding-left: 0px;
        padding-top: 1.5rem;
        margin-bottom: -0.5rem;
    }
    .PrivacyRow {
        user-select: none;
        display:flex;
        flex-direction:column;
        /* gap: 3rem; */
        max-width: 100% !important;
        align-items: top;
        justify-content: space-between;
        padding: 1rem 2rem;
    }
    .PrivacyRow1 {
        user-select: none;
        display:flex;
        flex-direction:column;
        gap: 0px;
        max-width: 96% !important;
        align-content: center;
        padding: 0.25rem 1.25rem;
    }
    .PrivacyRow span {
        position: relative;
        display: flex;
        color: hsl(4 90% 58% / 0.7) !important;
        font-size: small;
        font-weight: 500;
    }
    .PrivacyRow1 span {
        position: relative;
        display: flex;
        color: hsl(4 90% 58% / 0.7) !important;
        font-size: small;
        font-weight: 500;
        margin-top: -0.5rem;
        margin-bottom: 1rem;
    }
    #DebtCheck #check-api-p {
        margin-top: 4px !important;
    }
    .debtMessage {
        position: relative;
        margin: auto;
        color: #d9b173;
        font-weight: 400;
        font-size: large;
        display: flex;
        flex-direction: column;
        gap: 32px;
        text-align: center;
        max-width: 80%;
    }
    .debtIcon {
        font-size: xxx-large !important;
        margin: auto;
    }
    #debtButtonBack {
        height: 38px !important;
        border-radius: 5px;
        font-size: small;
        padding: 6px;
        padding-inline: 12px;
        max-width: fit-content;
        margin: auto;
        /* margin-left: 22px; */
        font-family: 'Overpass', sans-serif;
        border: 1px solid #0f281e;
        color: #0f281e;
        padding-bottom: 2px;
        letter-spacing: 0.3px;
        transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: all 320ms cubic-bezier(0.4, 0, 0.2, 1);
        user-select: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background: rgb(244, 241, 224);
    }
    #debtButtonBack:hover {
        background: rgba(217, 176, 115, 0.9); 
        box-shadow: 0 0px 0px 3px hsl(123, 43%, 39%);
        font-weight: 400;
        letter-spacing: 0.3px;
        border: 1px solid rgb(244, 241, 224);
    }

    #debtpage {
        min-width: 66% !important;
        margin-top: -1rem;
        height: 610px;
    }
    #debtLabel {
        position: relative !important;
        display: flex !important;
        font-size: medium;
        letter-spacing: 0.5px;
        max-width: 80%;
        margin: auto;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        left: 0;
    }
    #debtpage #name, #debtpage #contract {
        width: 280px !important;
    }
    #debtpage #date, #debtpage #formatted-text-mask-input {
        width: 272px !important;
    }
    #paypage {
        background: none;
        border: none;
        backdrop-filter: none;
        -webkit-backdrop-filter: none;
        box-shadow: none;
        max-width: 100%;
        margin-top: -20rem;
        margin-left: auto !important;
        margin-right: auto !important;
        left: 0 !important;
        right: 0 !important;
    }
    #paypage form {
        margin-left: -3.75rem;
        margin-top: -4rem;
    }
    #paypage #name, #paypage #date, #paypage #dogovor {
        width: 300px;
    }
    #FormLabelP {
        font-size: large;
        width: 349px;
        font-weight: 700;
        filter: drop-shadow(
            0 1px 30px hsl(0 0% 0% / 1)
        );
        margin-left: 0.25rem;
        margin-bottom: 1rem;
        align-self: flex-start !important;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

}
