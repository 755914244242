@media (max-width:480px) {
    .profileContainer {
        max-width: 95% !important;
        height: 60px;
        position: absolute !important;
        top: 10px;
        right: 0;
        left: 0;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        border-radius: 30px;
        overflow: hidden;
        transition: all 200ms ease-out;
        -webkit-transition: all 200ms ease-out;
        -moz-transition: all 200ms ease-out;
        -ms-transition: all 200ms ease-out;
        -o-transition: all 200ms ease-out;
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
    }
    .profileContainer:hover {
        border-radius: 30px;
        height: 200px !important;
        transition: all 200ms ease-out;
        -webkit-transition: all 200ms ease-out;
        -moz-transition: all 200ms ease-out;
        -ms-transition: all 200ms ease-out;
        -o-transition: all 200ms ease-out;
        /* z-index: 1500; */
    }
    .profileContainer:hover .regContainer {
        opacity: 100%;
        transition: opacity 250ms linear;
        -webkit-transition: opacity 250ms linear;
        -moz-transition: opacity 250ms linear;
        -ms-transition: opacity 250ms linear;
        -o-transition: opacity 250ms linear;
    }
    .pWrapper {
        display: flex;
        flex-direction: row;
        margin-bottom: -0.8rem;
        /* z-index: 1501; */
    }
    .AvatarC {
        position: relative !important;
        text-align: center !important; 
        height: 55px !important;
        width: 55x !important;
    }
    .AvatarC p {
        position: relative !important;
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 0.15rem;
        color: white;
        font-size: 17pt !important;
    }
    .pBack {
        display: flex;
        width: 45px !important;
        height: 45px !important;
        position: absolute !important;
        border-radius: 50px;
        left: 8px !important;
        top: 0 !important;
        bottom: 0 !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-top: 7px !important;
        justify-content: center !important;
    }
    .usernameA {
        display: flex;
        flex-direction: row;
        gap: 6px;
        padding: 1.15rem 4.5rem !important;
        font-weight: 600;
        font-family: "Overpass", sans-serif;
        color: #321e1e;
        font-size: medium;
    }
    .profileContainer .svg1 {
        position: absolute;
        right: 22px;
        top: 21px;
        transform: rotateX(-180deg);
        fill: #90a4ae;
        width: 24px;
        transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
        transition-delay: 0s;
        -webkit-transition-delay: 0s;
        -moz-transition-delay: 0s;
        -ms-transition-delay: 0s;
        -o-transition-delay: 0s;
    }
    .profileContainer:hover .svg1 {
        transform: rotateX(0deg);
        transform: translateY(-10%);
        right: 22px;
        top: 19.567px;
        transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
        -moz-transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
        -ms-transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
        -o-transition: 100ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .regContainer {
        opacity: 0;
        display: flex;
        flex-direction: row;
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        font-size: small;
        /* z-index: 10; */
    }
    .regCol {
        display: flex;
        flex-direction: column;
        gap: -10px;
        padding-top: 0.1rem;
        user-select: none;
    }
    .regCol p {
        padding: 0 2rem;
        padding-top: 5px;
        padding-bottom: 5px;
        font-family: 'Overpass', sans-serif;
        margin-bottom: 5px;
        max-width: 330px !important;
        color:#321e1e;
    }
    .regCol svg {
        position:relative;
        right: -1rem !important;
        top: 0.3rem !important;
        /* padding-top: 0.2rem; */
        padding-bottom: 0rem;
        margin-bottom: 1rem;
        fill: #90a4ae;
        width: 23px;
    }
    .SupportIcon {
        position: absolute;
        bottom: 1rem !important;
        right: 1.25rem !important;
        display: flex;
        width: 56px;
        height: 56px;
        /* background-color: hsl(120 100% 35% / 0.45); */
        background-color: hsla(153, 36%, 36%, 0.69);
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        box-shadow: 0 4px 18px hsl(0 0% 0% / 0.23);
        margin-bottom: 0.5rem !important;
    }
}
@media (max-width:375px) {
    .profileContainer {
        min-width: 95% !important;
        height: 60px;
        position: absolute ;
        top: 10px;
        right: 25px;
        background-color: white;
        border-radius: 30px;
        filter: 
            drop-shadow(
                0 10px 6px hsl(0 0% 33% / 0.33)
            )
            drop-shadow(
                0 24px 24px hsl(0 0% 23% / 0.33)
            );
        overflow: hidden;
        transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -ms-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-delay: 0.4s; 
        margin-left: 0.55rem !important;
    }
    .profileContainer:hover {
        border-radius: 30px;
        height: 220px !important;
        transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -ms-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        /* z-index: 1500; */
    }
    .profileContainer:hover .regContainer {
        opacity: 100%;
        transition: 250ms linear;
        -webkit-transition: 250ms linear;
        -moz-transition: 250ms linear;
        -ms-transition: 250ms linear;
        -o-transition: 250ms linear;
    }
    .pWrapper {
        display: flex;
        flex-direction: row;
        /* z-index: 1501; */
    }
    .AvatarC {
        position: relative;
        height: 45px;
        width: 45px;
    }
    .AvatarC p {
        position: relative;
        /* left: 0.6rem !important; */
        /* padding: 0.78rem 0.81rem !important; */
        margin-top: auto;
        margin-bottom: auto;
        color: white;
        font-size: 17pt !important;
    }
    .pBack {
        position: absolute !important;
        width: 45px ;
        height: 45px;
        border-radius: 50px;
        right: 0rem !important;
        top: 0 !important;
        bottom: 0 !important;
        margin-top: auto !important;
        margin-bottom: auto !important;
        margin-top: 7px !important;
        justify-content: center;
    }
    .usernameA {
        display: flex;
        flex-direction: row;
        gap: 6px;
        /* margin-left: -10px !important; */
        padding: 1.05rem 1.3rem !important;
        font-weight: 600;
        font-family: "Overpass", sans-serif;
        color: #321e1e;
        font-size: medium;
    }
    .profileContainer .svg1 {
        position: absolute;
        right: 11px !important;
        top: 24px !important;
        transform: rotate(90deg);
        fill: #90a4ae;
        width: 24px;
        transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -ms-transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
    }
    .profileContainer:hover .svg1 {
        transform: rotate(0deg);
        top: 17px !important;
        right: 6px !important;
        transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -ms-transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: 250ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .regContainer {
        opacity: 0;
        display: flex;
        flex-direction: row;
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        font-size: small;
        /* z-index: 10; */
    }
    .regCol {
        display: flex;
        flex-direction: column;
        gap: -10px;
        user-select: none;
    }
    .regCol p {
        padding: 0 1.2rem !important;
        margin-bottom: 5px;
        max-width: 300px !important;
        color:#321e1e;
    }
    .regCol svg {
        position:relative;
        right: -1.15rem !important;
        top: 0px;
        margin-bottom: 1rem;
        fill: #90a4ae;
        width: 23px;
    }
    .SupportIcon {
        position: absolute !important;
        bottom: 2rem !important;
        right: 1.25rem !important;
        display: flex;
        width: 56px;
        height: 56px;
        /* background-color: hsl(120 100% 35% / 0.45); */
        background-color: hsla(153, 36%, 36%, 0.69);
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        box-shadow: 0 4px 12px hsl(0 0% 0% / 0.23);
    }
}

@media (min-width:1200px) {
    .profileContainer {
        width: 420px;
        height: 60px;
        position: absolute;
        top: 10px;
        right: 25px;
        background-color: white;
        border-radius: 30px;
        filter: 
            drop-shadow(
                0 10px 6px hsl(0 0% 33% / 0.33)
            )
            drop-shadow(
                0 24px 24px hsl(0 0% 23% / 0.33)
            );
        overflow: hidden;
        transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -ms-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        z-index: 1001;
    }
    .profileContainer:hover {
        border-radius: 30px;
        height: 250px;
        transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -ms-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .profileContainer:hover .regContainer {
        opacity: 100%;
        transition: 250ms linear;
        -webkit-transition: 250ms linear;
        -moz-transition: 250ms linear;
        -ms-transition: 250ms linear;
        -o-transition: 250ms linear;
    }
    .pWrapper {
        display: flex;
        flex-direction: row;
    }
    .AvatarC {
        position: relative;
        height: 60px;
        width: 60px;
    }
    .AvatarC p {
        position: relative;
        margin-top: auto;
        margin-bottom: auto;
        padding-bottom: 0.15rem;
        /* padding: 0.7rem 1.3rem; */
        color: white;
        font-size: 18pt;
    }
    .pBack {
        display: flex;
        width: 50px;
        height: 50px;
        position: absolute;
        border-radius: 50px;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;
        left: 0.4rem;
        justify-content: center;
        align-content: center;
    }
    .usernameA {
        display: flex;
        flex-direction: row;
        gap: 6px;
        padding: 1.1rem 1rem;
        font-weight: 600;
        font-family: "Overpass", sans-serif;
        letter-spacing: 0.15px;
        color: #321e1e;
    }
    .profileContainer .svg1 {
        position: absolute;
        right: 21px;
        top: 22px;
        transform: rotate(90deg);
        fill: #90a4ae;
        width: 24px;
        transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -ms-transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
    }
    .profileContainer:hover .svg1 {
        transform: rotate(0deg);
        top: 16px;
        right: 1rem;
        transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -ms-transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    .regContainer {
        opacity: 0;
        display: flex;
        flex-direction: row;
        transition-delay: 0.4s;
        -webkit-transition-delay: 0.4s;
        -moz-transition-delay: 0.4s;
        -ms-transition-delay: 0.4s;
        -o-transition-delay: 0.4s;
        /* z-index: 10; */
    }
    .regCol {
        display: flex;
        flex-direction: column;
        gap: -10px;
    }
    .regCol p {
        padding: 0 2rem;
        margin-bottom: 5px;
        max-width: 380px;
        color:#321e1e;
        letter-spacing: 0.15px;
        user-select: none;
    }
    .regCol svg {
        position:relative;
        right: 0.4rem;
        top: 0px;
        margin-bottom: 1rem;
        fill: #90a4ae;
        width: 23px;  
    }
    .regCol svg:hover {
        fill: #78909c;
    }
    .mainColumn {
        display: flex;
        flex-direction: column;
    }
    .SupportIcon {
        position: absolute;
        /* margin-bottom: 8rem !important; */
        bottom: 2rem;
        right: 2rem;
        display: flex;
        width: 64px;
        height: 64px;
        background-color: hsla(153, 36%, 36%, 0.69);
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        box-shadow: 0 12px 18px hsl(0 0% 0% / 0.33);
        z-index: 10000;
    }
}
.SupportIcon svg {
    position: relative;
    fill: white;
    width: 32px;
    height: 32px;
    animation: support 5s infinite ease-in-out;
}
.SupportIcon:hover {
    scale: 100%;
    transition: 300ms ease-in-out;
    -webkit-transition: 300ms ease-in-out;
    -moz-transition: 300ms ease-in-out;
    -ms-transition: 300ms ease-in-out;
    -o-transition: 300ms ease-in-out;
}
.SupportIcon:hover svg {
    animation: support_out 100ms forwards;
}

@keyframes support {
    0% { transform: rotate(0deg) }
    1.78571% { transform: rotate(10deg) }
    3.57143% { transform:rotate(-10deg) }
    5.35714% { transform:rotate(0deg) }
    7.14286% { transform:rotate(10deg) }
    8.92857% { transform:rotate(-10deg) }
    10.71429% { transform:rotate(0deg) }
    100% { transform:rotate(0deg) }
}

@keyframes support_out {
    0% {transform: scale(0.95);}
    100% {transform: scale(1.05);}
}