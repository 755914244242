@media (max-width: 480px) {
    .DropContainer {
        width: 320px;
        height: 290px;
        border: 1px solid #e2e2e1;
        z-index: 1000 !important;
        border-radius: 5px;
        color: #321e1e;
        background: #fcfcfb;
    }
    .dropzone {
        width: 320px;
        margin: -1px;
        height: 181.5px;
        border: 1px solid transparent;
        border-bottom: 1px #e2e2e1 dashed;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }
    .dropzone:hover {
        border: 1px #e2e2e1 dashed;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: 100ms linear;
        -webkit-transition: 100ms linear;
        -moz-transition: 100ms linear;
        -ms-transition: 100ms linear;
        -o-transition: 100ms linear;
        box-shadow: inset 0 0px 7px hsl(153, 36%, 36%, 0.75);
    }
    .dropzone:hover button {
        background: linear-gradient(30deg, hsl(36, 57%, 65%), hsl(31, 100%, 65%));
        box-shadow: 
        0 0 0 1px white,
        0px 3px 4px hsl(153, 36%, 36%, 0.75)
        ;
        border: 2px solid white;
        transform: perspective(75em) rotateX(21deg) !important;
        transition: all 90ms cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all 90ms cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all 90ms cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all 90ms cubic-bezier(.165, .84, .44, 1);
        -o-transition: all 90ms cubic-bezier(.165, .84, .44, 1);
        color: white;
        font-weight: 500;
    }
    .text-center {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: center;
        top: 13.3%;
        margin: auto;
        bottom: 0;
        justify-content: center;
        padding-left: 40px !important;
        padding-right: 40px !important;
        /* width: 80%; */
    }
    .text-center p {
        -moz-user-select:none !important; 
        -webkit-user-select: none !important; 
        -ms-user-select: none !important;
        user-select: none !important;
        color:#321e1e;
        font-family: 'Open Sans', sans-serif;
        font-size: 11pt;
        letter-spacing: 0.3px;
    }
    .text-center button {
        width: 200px;
        align-self: center;
        background: none;
        margin-left: 0rem;
        color: #321e1e !important;
        font-weight: 500;
        border-color: #321e1e;
        box-shadow: 0 0 0 1px transparent;
        transition: all 90ms cubic-bezier(.165, .84, .44, 1);
        -webkit-transition: all 90ms cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all 90ms cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all 90ms cubic-bezier(.165, .84, .44, 1);
        -o-transition: all 90ms cubic-bezier(.165, .84, .44, 1);
    }
    .A-side {
        width: 318px;
        overflow-y: auto;
        height: 107.5px;
        padding-top: 10px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-right: 20px;
        justify-content: space-between;
        margin-top: 1px;
    }
    .A-side li {
        display: flex;
        align-items: top;
        justify-content: space-between;
        margin-left: -10px !important;
        font-size: small;
        margin-top: 7px;
    }
    .A-side span {
        inline-size: 175px;
        overflow-wrap: break-word;
    }
    .A-side::-webkit-scrollbar {
        width: 7px;
        overflow: hidden;
    }
    .A-side::-webkit-scrollbar-track {
        box-shadow: inset 1px 2px 4px hsla(0, 0%, 0%, 0.15);
        margin-block: 12px;
        border-radius: 25px;
    }
    .A-side::-webkit-scrollbar-thumb {
        background-color: hsla(153, 36%, 36%, 0.9);
        border: 1px solid hsl(0 0% 100% / 0.75);
        border-radius: 25px;
        max-height: 120px !important;
    }
    #DropzoneS {
        padding-top: 1rem;
        width: 352px !important;
        height: 170px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #DropzoneSupport {
        width: 352px !important;
        height: 280px;
        margin-top: 0rem;
        margin-left: -1px;
    }
}

@media (min-width: 1200px) {
    .DropContainer {
        width: 100%;
        height: 270px;
        border: 1px #e2e2e1 solid;
        margin: auto;
        z-index: 1000 !important;
        border-radius: 5px;
        color: #321e1e;
        background-color: #fcfcfb;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #DropzoneSupport {
        width: 520px !important;
        height: 280px;
        margin-top: 0rem;
    }
    .dropzone {
        width: 100%;
        margin: -1px;
        height: 160px;
        border: 2px solid transparent;
        border: 1px #e2e2e1 dashed;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    #DropzoneS {
        padding-top: 1rem;
        width: 520px !important;
        height: 170px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .dropzone:hover {
        border: 1px #e2e2e1 dashed;
        margin: -1px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        transition: 100ms linear;
        -webkit-transition: 100ms linear;
        -moz-transition: 100ms linear;
        -ms-transition: 100ms linear;
        -o-transition: 100ms linear;
        box-shadow: inset 0 0px 7px hsl(153, 36%, 36%, 0.75);
    }
    
    .dropzone:hover button {
        background: linear-gradient(30deg, hsl(36, 57%, 65%), hsl(31, 100%, 65%));
        box-shadow: 
        0 0 0 1px white,
        0px 3px 4px hsl(153, 36%, 36%, 0.75)
        ;
        border: 2px solid white !important;
        outline: none;
        transform: perspective(75em) rotateX(21deg) !important;
        transition: all 150ms cubic-bezier(.165, .84, .44, 1) !important;
        -webkit-transition: all 150ms cubic-bezier(.165, .84, .44, 1);
        -moz-transition: all 150ms cubic-bezier(.165, .84, .44, 1);
        -ms-transition: all 150ms cubic-bezier(.165, .84, .44, 1);
        -o-transition: all 150ms cubic-bezier(.165, .84, .44, 1);
        /* letter-spacing: 0.25pt; */
        /* transition: all 90ms cubic-bezier(.165, .84, .44, 1); */
        /* font-weight: 500; */
        /* letter-spacing: 0.35pt; */
        /* color: #fff; */
    }
    .dropzone:hover #DropSupport {
        transform: none !important;
        font-weight: 400;
        letter-spacing: initial;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .text-center {
        position: relative;
        display: flex;
        flex-direction: column;
        text-align: center;
        top: 13.3%;
        margin: auto;
        bottom: 0;
        justify-content: center;
        width: 80%;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .text-center p {
        -moz-user-select:none !important; 
        -webkit-user-select: none !important; 
        -ms-user-select: none !important;
        user-select: none !important;
        color:#321e1e;
        font-family: 'Open Sans', sans-serif;
        font-size: 11pt !important;
        letter-spacing: 0.35pt;
       -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .text-center button {
        position: relative;
        letter-spacing: 0.5px !important;
        min-width: fit-content;
        max-width: 200px;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        align-self: center;
        background: none;
        color: #321e1e;
        font-weight: 400;
        border: 2px solid #321e1e23 !important;
        outline: 1px solid #321e1e23;
        /* box-shadow: 0 0 0 1px #321e1e23 !important; */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .text-center #DropSupport {
        height: 48px;
        letter-spacing: 0.5px;
        padding-top: 1px !important;
        font-size: small !important;
        font-weight: 500 !important;
        /* font-family: 'Overpass', sans-serif; */
    }
    .A-side {
        overflow-y: auto;
        height: 110px;
        padding-top: 10px;
        padding-left: 0.1rem;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        padding-right: 20px;
        justify-content: space-between;
    }
    .A-side li {
        display: flex;
        align-items: top;
        justify-content: space-between;
        font-size: smaller;
        margin-top: 7px;
        letter-spacing: 0.25px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .A-side span {
        inline-size: 200px;
        overflow-wrap: break-word;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .A-side::-webkit-scrollbar {
        width: 7px;
        overflow: hidden;
    }
    .A-side::-webkit-scrollbar-track {
        box-shadow: inset 1px 2px 4px hsla(0, 0%, 0%, 0.15);
        margin-block: 12px;
        border-radius: 25px;
    }
    .A-side::-webkit-scrollbar-thumb {
        background-color: hsla(153, 36%, 36%, 0.9);
        border: 1px solid hsl(0 0% 100% / 0.75);
        border-radius: 25px;
        max-height: 120px !important;
    }
}
